import { createSelector } from '@reduxjs/toolkit';

const user = (state) => state.user;

const isLoggedInSelector = createSelector(user, (state) => state.isLoggedIn);
const isGuestSelector = createSelector(user, (state) => state.user?.guest);

const requireCompleteInformationSelector = createSelector(
  user,
  (state) => state.requiringCompleteInformation
);
const userSelector = createSelector(user, (state) => state.user);
const addressSelector = createSelector(
  userSelector,
  (user) => user?.addresses || []
);
const lastUsedAddressSelector = createSelector(userSelector, (user) => {
  const userAddressIds = user?.addresses?.map((address) => address.id) || [];
  return (
    user?.last_used_addresses?.filter((id) => userAddressIds.includes(id)) || []
  );
});

export {
  isLoggedInSelector,
  isGuestSelector,
  requireCompleteInformationSelector,
  userSelector,
  addressSelector,
  lastUsedAddressSelector,
};
