import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import constants from '../../Config/constants';
import routeList from '../../Routes/list';
import { setOrderBannerMinimized } from '../../RTK/utility';
import useOrderController from '../../Screens/Orders/index.controller';

const webLimit = constants.ACTIVE_ORDER_LIMIT_WEB;
function useOngoingOrderBanner() {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const { data } = useOrderController();
  const isMinimized = useSelector(
    (state) => state.utility.isOrderBannerMinimized
  );

  const _goToOrders = (highlightId) => {
    const params = constants.isWeb ? {} : { activeTab: 1 };
    const route = constants.isWeb ? routeList.BASKET_TAB : routeList.BASKET;
    if (highlightId) {
      params.highlightOrderId = highlightId;
    }
    navigation.navigate(route, params);
  };

  const _toggleBanner = () => dispatch(setOrderBannerMinimized(!isMinimized));

  return {
    hasSeeMore: constants.isWeb ? data.length > webLimit : data?.length > 1,
    isMinimized,
    order: data?.[0], // use on mobile
    orders: data?.slice?.(0, webLimit), // used on web
    goToOrders: _goToOrders,
    toggleBanner: _toggleBanner,
  };
}

export default useOngoingOrderBanner;
