import { useEffect } from 'react';
import lodashGet from 'lodash/get';

import UrlParameter from '../../Helper/UrlParameter';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useGoBack from '../../Hooks/useGoBack';
import storeApi from '../../Service/api/store';
import routeList from '../../Routes/list';

function useSeeAllController(props) {
  const { createRequest } = useCancellableRequest();
  const goBack = useGoBack();

  const key = lodashGet(props.route, 'params.key');
  const tag = lodashGet(props.route, 'params.tag');
  const title = lodashGet(props.route, 'params.title');

  const initialData = [{ loading: true }, { loading: true }, { loading: true }];

  useEffect(() => {
    if (!key || !tag || !title) {
      goBack();
    }
  }, []);

  const _getQueryParams = (page) => {
    let queryParams = `?${UrlParameter.getLimitAndOffset(page)}&open_only=true`;
    if (key) {
      queryParams += `&${key}=${tag}`;
    } else if (typeof tag === 'string') {
      queryParams += `&tags=${tag}`;
    }
    return queryParams;
  };

  const _getStoresData = (page) => {
    const queryParams = _getQueryParams(page);
    return createRequest(storeApi.getAllStores, queryParams);
  };

  const _onCardPressed = (item) => () => {
    props.navigation.navigate(routeList.STORE, {
      id: item.id,
      distance: item.distance,
      duration: item.duration,
    });
  };

  return {
    initialData,
    key,
    tag,
    title,
    getQueryParams: _getQueryParams,
    getStoresData: _getStoresData,
    onCardPressed: _onCardPressed,
  };
}

export default useSeeAllController;
