import StyleSheet from 'react-native-media-query';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import breakpoints from '../../Theme/styles/breakpoints';

const pcIconSize = 30;

export const { ids, styles } = StyleSheet.create({
  iconContainer: {
    position: 'absolute',
    top: globalSpacing / 2,
    right: globalSpacing,
  },
  accordionIcon: {
    width: 20,
    height: 20,
  },
  clipBoardIcon: { width: 10, height: 10 },
  itemOnGoingContainer: {
    ...ThemeStyle.pageVerticalSpacing,
    ...ThemeStyle.pageHorizontalSpacing,
    borderRadius: 10,
    [`@media ${breakpoints.sm}`]: {
      ...ThemeStyle.flexDirectionRowCenter,
    },
  },
  itemOnGoing2ndContainer: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionColumnSpaceBetween,
    [`@media ${breakpoints.sm}`]: {
      ...ThemeStyle.spacingLeft,
      maxWidth: '50%',
    },
  },
  itemOnGoingProgressContainer: {
    ...ThemeStyle.spacingTop,
  },

  itemOnGoingLottie: {
    width: pcIconSize,
    height: pcIconSize,
  },

  itemPendingContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  itemVerifyContainer: {
    ...ThemeStyle.spacingLeftSmall,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ThemeColor.green,
    borderRadius: 30,
    height: 20,
  },
  itemVerfiyLottieMainContainer: {
    ...ThemeStyle.alignItemsCenter,
    ...ThemeStyle.justifyContentCenter,
    width: pcIconSize / 2,
    height: pcIconSize / 2,
    borderRadius: pcIconSize / 2,
    backgroundColor: ThemeColor.gray,
  },
  itemVerfiyLottieContainer: {
    position: 'absolute',
  },
  imageStyle: {
    borderWidth: 1,
    borderColor: ThemeColor.gray,
    borderRadius: 5,
    overflow: 'hidden',
    height: 72,
  },
  noImageStyle: {
    backgroundColor: ThemeColor.green,
    width: 70,
  },
});
