import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';

import Image from '../../Components/Image';
import List from '../../Components/List';
import ListItem from '../../Components/ListItem';
import sampleCuisines from '../../Components/Filter/categoryData';
import Sentry from '../../Helper/Sentry';
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import withController from '../../Hooks/withController';
import { categoryCacheSelector } from '../../RTK/dataCache/selectors';
import searchApi from '../../Service/api/search';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

const iconSize = 20;

class CuisinesSection extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      cuisines: props.categoryCache,
    };
  }

  componentDidMount() {
    if (lodashIsEmpty(this.state.cuisines)) {
      this._getCuisines();
    }
  }

  async _getCuisines() {
    const { api } = this.props;
    const { ok, data } = await api.createRequest(searchApi.getCuisinesAWS);
    const cuisines = ok ? data : sampleCuisines;
    if (!ok) {
      Sentry.reportError('Error getting cuisines on search page', data);
    }
    this.setState({ cuisines });
  }

  _renderItem = ({ index, item }) => {
    return (
      <ListItem
        testID={`cuisineButton${index}`}
        accessoryLeft={
          <View style={[styles.icon, ThemeStyle.spacingRight]}>
            {/* make it bigger, but still align to recent searches */}
            <Image
              source={{ uri: item.inactive }}
              style={{
                width: iconSize * 2,
                height: iconSize * 2,
                position: 'absolute',
                left: '-50%',
                top: '-50%',
              }}
            />
          </View>
        }
        accessoryRight={
          <Icon
            name="arrow-ios-forward"
            fill={ThemeColor.darkGray}
            style={styles.icon}
          />
        }
        title={item.name}
        onPress={() => this.props.onPress(item.name, true)}
      />
    );
  };

  render() {
    const { cuisines } = this.state;

    if (lodashIsEmpty(cuisines)) {
      return null;
    }

    return (
      <View style={[ThemeStyle.pageHorizontalSpacing, ThemeStyle.spacingTop]}>
        {/* <Text category="h6">Food</Text> */}

        {/* <View style={[ThemeStyle.contentTopSpacing, ThemeStyle.pageHorizontalSpacing]}> */}
        <Text category="s1">Cuisines</Text>

        <List
          data={lodashFilter(cuisines, (e) => !e.all)}
          renderItem={this._renderItem}
          withSeparator
        />
        {/* </View> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  icon: {
    width: iconSize,
    height: iconSize,
  },
});

const mapStateToProps = (state) => ({
  categoryCache: categoryCacheSelector(state),
});

CuisinesSection = withController(CuisinesSection, useCancellableRequest, 'api');

CuisinesSection.propTypes = {
  onPress: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(CuisinesSection);
