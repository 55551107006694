import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { CANCEL_ERROR } from 'apisauce';
import lodashFilter from 'lodash/filter';
import lodashIsEqual from 'lodash/isEqual';
import lodashPick from 'lodash/pick';

import constants from '../Config/constants';
import AnalyticsHelper from '../Helper/Analytics';
import Sentry from '../Helper/Sentry';
import { setCart } from '../RTK/cart';
import { setFavourites } from '../RTK/favourite';
import { saveUser, setUserAddresses, updateLastUsedAddress } from '../RTK/user';
import { addressSelector, userSelector } from '../RTK/user/selectors';
import { setGettingUserData } from '../RTK/utility';
import Service from '../Service';
import userApi from '../Service/api/user';
import otherApi from '../Service/api/other';
import Cookie from '../Service/Cookie';

import useCancellableRequest from './useCancellableRequest';

export default function () {
  const dispatch = useDispatch();
  const { createRequest } = useCancellableRequest();
  const user = useSelector(userSelector);
  const userAddresses = useSelector(addressSelector);
  const userFavorites = useSelector((state) => state.favourite?.favouriteData);
  const userCarts = useSelector((state) => state.cart?.cartData);

  const { refetch: _refetchCarts } = useQuery({
    queryKey: [user?.id, 'user-carts'],
    queryFn: () => userApi.getCart(),
    enabled: false,
  });

  // prettier-ignore
  const _setReduxFlag = async (key, loading, error) => await dispatch(setGettingUserData({ key, value: { loading, error } }))

  const _setCartData = async (addLoader) => {
    // add flag to redux that the cart is loading if addLoader parameter is true
    if (addLoader) {
      await _setReduxFlag('cart', true);
    }
    const {
      data: { ok, data, problem, status },
    } = await _refetchCarts();
    const isNetworkError = problem === 'NETWORK_ERROR';
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      const withStoreItemsOnly = lodashFilter(data, (cd) => cd.items.length);
      if (!lodashIsEqual(withStoreItemsOnly, userCarts)) {
        await dispatch(setCart(withStoreItemsOnly)); // if server cart data and app cart data is not equal, update app cart from server
      }
      // remove flag to redux that the cart is done loading if addLoader parameter is true
      if (addLoader) {
        await _setReduxFlag('cart', false);
      }
    } else {
      const error = data?.message || 'Cannot get user carts';
      Sentry.reportError('Error getting user carts', data);
      await _setReduxFlag('cart', false, error); // remove loader and add error message
      return { error: isNetworkError ? '' : error, status }; // return error message
    }
  };

  const _setUserAddresses = async () => {
    // add addLoader parameter if needed like on the _setCartData function, for now its not needed so I didn't put it
    const { ok, data, problem, status } = await createRequest(
      userApi.getAddress
    );
    const isNetworkError = problem === 'NETWORK_ERROR';
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      // for the callee of this function, redirect the route to address adding screen because user data on server doesn't have address yet
      if (data.length === 0) {
        return { noAddress: true };
      }

      await dispatch(setUserAddresses(data));
    } else {
      const error = data?.message || 'Cannot get user addresses';
      Sentry.reportError('Error getting user addresses', data);
      await _setReduxFlag('address', false, error); // remove loader and add error message
      return { error: isNetworkError ? '' : error, status }; // return error message
    }
  };

  const _setUserDetails = async () => {
    // add addLoader parameter if needed like on the _setCartData function, for now its not needed so I didn't put it
    const { ok, data, headers, problem, status } = await createRequest(
      userApi.getUser
    );
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      if (!constants.isWeb) {
        await Cookie.set(headers['set-cookie']); // save session cookie
      }
      // store data.addresses to data.last_used_addresses and;
      data.last_used_addresses = data.addresses
        .filter((e) => e != data.active_address) // filter out the active address
        .slice(0, constants.lastUsedAddressLength); // slice it for required last used address length
      // pick data that can only be modified
      const dataToCompare = [
        'first_name',
        'last_name',
        'phone',
        'last_used_addresses',
      ];
      const localUserInfo = lodashPick(user, dataToCompare);
      const apiuserInfo = lodashPick(data, dataToCompare);
      // if user info is not match
      if (!lodashIsEqual(localUserInfo, apiuserInfo)) {
        await dispatch(saveUser(data)); // update device details from api details
      }
      Sentry.setUser({
        id: data?.id,
        email: data?.email,
      });
      await AnalyticsHelper.setUser(data?.id);
      // if server saved stores is not match on device saved stores
      if (!lodashIsEqual(userFavorites, data.favorites)) {
        await dispatch(setFavourites(data.favorites)); // update device favorites from api favorites
      }
    } else if (!user?.first_name) {
      const error = data?.message || 'Cannot get user details';
      Sentry.reportError('Error getting user details', data);
      await _setReduxFlag('details', false, error); // remove loader and add error message
      return { error, status }; // return error message
    }
  };

  const _updateLastUsedAddress = async () => {
    const { ok, data, problem } = await createRequest(userApi.getUser);
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    // proceed logic
    if (ok) {
      await dispatch(updateLastUsedAddress(data));
    } else {
      Sentry.reportError('Error updating user last used addresses', data);
    }
  };

  return {
    setCartData: _setCartData,
    setUserAddresses: _setUserAddresses,
    setUserDetails: _setUserDetails,
    updateLastUsedAddress: _updateLastUsedAddress,
  };
}
