import React, { Fragment } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text, useTheme } from '@ui-kitten/components';

import { mealPlan } from '../RTK/defaultValues';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import constants from '../Config/constants';

type Type = {
  value: string;
};

type Props = {
  small?: boolean;
  value?: string;
  onChange: (data: Type) => void;
};

const types: Type[] = [
  { value: mealPlan.type.threeDay },
  { value: mealPlan.type.fiveDay },
];

const textCategory = constants.isWeb ? 'p2' : 'p1';
const textCategorySmall = constants.isWeb ? 'c1' : 'p2';

const MealPlanTypes = ({ small, value, onChange }: Props) => {
  const store_theme = useTheme();
  return (
    <View style={[ThemeStyle.flexDirectionRow, small && ThemeStyle.flex1]}>
      {types.map((type: Type, index: number) => {
        const isActive = value === type.value;
        const style = constants.isWeb
          ? ({ hovered }) => [
              styles.contentContainer,
              small && ThemeStyle.flex1,
              hovered && { backgroundColor: ThemeColor.green100 },
              isActive && styles.active,
            ]
          : small && ThemeStyle.flex1;
        return (
          <Fragment key={index}>
            <Button
              onPress={() => onChange(type)}
              style={style}
              isHoverable={constants.isWeb}
              plain
            >
              <View
                style={[
                  !constants.isWeb && styles.contentContainer, // mobile style only
                  !constants.isWeb && isActive && styles.active, // mobile style only
                  small ? styles.containerSmall : styles.container,
                ]}
              >
                <View style={ThemeStyle.flexDirectionRowCenter}>
                  <Icon
                    name="calendar-check"
                    pack="fa5"
                    fill={
                      isActive
                        ? store_theme['icon_color'] || ThemeColor.white
                        : store_theme['icon_color'] || ThemeColor.green
                    }
                    style={small ? styles.iconSmall : styles.icon}
                  />
                  <Text
                    appearance={isActive ? 'alternative' : 'default'}
                    category={small ? textCategorySmall : textCategory}
                    style={ThemeStyle.capitalize}
                  >
                    {type.value.replace('_', ' ')}
                  </Text>
                </View>
                <Text
                  appearance={isActive ? 'alternative' : 'default'}
                  category={small ? textCategorySmall : textCategory}
                >
                  Meal Plan
                </Text>
              </View>
            </Button>

            {index !== types.length - 1 && (
              <View
                style={
                  small
                    ? ThemeStyle.rowSeparator
                    : ThemeStyle.pageHorizontalSpacing
                }
              />
            )}
          </Fragment>
        );
      })}
    </View>
  );
};

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: 'center',
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 10,
  },
  container: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
  },
  containerSmall: {
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingMedium,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },
  iconSmall: {
    ...ThemeStyle.spacingRightSmall,
    width: 15,
    height: 15,
  },
});

export default MealPlanTypes;
