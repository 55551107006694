import lodashIsEqual from 'lodash/isEqual';
import lodashAttempt from 'lodash/attempt';
import lodashIsError from 'lodash/isError';

import constants from '../Config/constants';
import { filter } from '../RTK/defaultValues';

const { useCase, homeInitialFilterValue, searchInitialFilterValue } = filter;

function anonymize(text, afterCharCount = 3, lastCharCount) {
  if (!text) {
    return text;
  }
  const characterToHide = text.substring(
    afterCharCount,
    text.length - (lastCharCount || 0)
  );
  const characterToReplace = '*'.repeat(characterToHide.length);
  let result = text.substr(0, afterCharCount) + characterToReplace;
  if (lastCharCount && lastCharCount > 0) {
    result += text.substr(text.length - lastCharCount, lastCharCount);
  }
  return result;
}

const capitalize = (str) => {
  if (typeof str === 'object') return str[0];
  if (!str) return str;
  const [first, ...rest] = str;
  return `${first.toUpperCase()}${rest.join('')}`;
};

const formatDistance = (distance) => {
  if (!distance) {
    return `< 0.1 ${constants.DISTANCE_UNIT}`;
  } else {
    return `${distance} ${constants.DISTANCE_UNIT}`;
  }
};

/**
 * Used for formatting the duration of store delivery time, code reference: https://stackoverflow.com/a/8495907
 * @param {*} duration @number
 * @returns {number}
 */
const formatDuration = (duration) => Math.round(duration / 5) * 5;

const isDefaultFilter = (
  tagFilter,
  categoryFilter = [],
  useIn = useCase.HOME
) => {
  // set filters
  const defaultFilter =
    useIn === useCase.HOME ? homeInitialFilterValue : searchInitialFilterValue;
  const currentFilter = { category: categoryFilter, tags: tagFilter };
  // set category filter
  const defaultCategory = defaultFilter.category;
  const currentCategory = currentFilter.category;
  // set tags filter
  const defaultTags = defaultFilter.tags.map((f) => f.paramName);
  const currentTags = currentFilter.tags.map((f) => f.paramName);
  // return if the current category and tags filter is match to the default filter
  return (
    lodashIsEqual(currentTags, defaultTags) &&
    lodashIsEqual(currentCategory, defaultCategory)
  );
};

/**
 * Check if user does not setup it's details yet in profile if no first, last or phone data
 * @param {*} user - object: user details
 * @param {optional} isCamelCase - boolean: if want to check as camelcase
 * @returns {boolean}
 */
const isUserDetailsIncomplete = (user, isCamelCase) => {
  if (isCamelCase) {
    return !user?.firstName || !user?.lastName || !user?.phone;
  } else {
    return !user?.first_name || !user?.last_name || !user?.phone;
  }
};

/**
 * Check if json is parsable or not
 * @param {*} jsonStr json stringify value
 * @returns
 */
const isValidJson = (jsonStr) => {
  return !lodashIsError(lodashAttempt(JSON.parse.bind(null, jsonStr)));
};

/**
 * Create array from number
 * @param {number} num - The numeric value to be converted to array.
 * @returns {Array} The converted number to array e.g: num=5 will return [0,1,2,3,4] // 5 elements array
 */
const numberToArray = (num) => {
  return Array.from(Array(num).keys());
};

/**
 * Converts a numeric value to its corresponding English word representation.
 * @param {number} num - The numeric value to be converted to words.
 * @returns {string} The English word representation of the input number.
 */
const numberToWord = (num) => {
  // base on this https://www.thoughtco.com/how-to-convert-numbers-to-words-with-javascript-4072535
  // so far we only need 0 - 5, if in case we needed something bigger number refer to the link above
  const numStrList = ['zero', 'one', 'two', 'three', 'four', 'five'];
  return numStrList[num];
};

const pluralize = (count, text) => {
  // this function only support adding s at the end of text
  // adjust this if need to have ed, ies etc.
  return `${text}${count > 1 ? 's' : ''}`;
};

export {
  anonymize,
  capitalize,
  formatDistance,
  formatDuration,
  isDefaultFilter,
  isUserDetailsIncomplete,
  isValidJson,
  numberToArray,
  numberToWord,
  pluralize,
};
