import React, { useRef, useState } from 'react';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';
import { View } from 'react-native';
import { Divider, Text } from '@ui-kitten/components';
import { ScrollView } from 'react-native-gesture-handler';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import AnalyticsHelper from '../../Helper/Analytics';
import ThemeStyle, { deviceHeight } from '../../Theme/styles';

import Button from '../Button';
import QuickCartQtyBadge from '../QuickCartQtyBadge';

import commonProps, { scrollProps } from './commonProps';
import modals from './modals';
import styles from './styles';

const topSpacing = deviceHeight * 0.3 + constants.statusBarHeight;
const maxHeight = deviceHeight - topSpacing;

function MultipleVariantSheet({ sheetId }) {
  const sheetRef = useRef();
  const scrollHandlers = useScrollHandlers('multivariant', sheetRef);

  const [data, setData] = useState();

  const _onOk = () => modals.hide(modals.MULTIPLE_VARIANT);
  const _onSelect = (item) => async () => {
    AnalyticsHelper.itemSelection({
      ...item,
      fromSection: 'Edit item multi variant popup',
    });
    await modals.hide(modals.MULTIPLE_VARIANT);
    modals.show(modals.PRODUCT, { isUpdate: true, id: item.item_id, ...item });
  };

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottomMedium}>
          You have different variations of this item in your{' '}
          {constants.cartTerm}.
        </Text>

        <View style={[ThemeStyle.spacingBottom, { maxHeight }]}>
          <ScrollView {...scrollProps} {...scrollHandlers}>
            {data?.items?.map((item, index) => (
              <Button key={index} onPress={_onSelect(item)} plain>
                <View
                  style={[
                    ThemeStyle.pageVerticalSpacingMedium,
                    ThemeStyle.flexDirectionRowCenter,
                  ]}
                >
                  <View style={ThemeStyle.spacingRight}>
                    <QuickCartQtyBadge value={item.quantity} />
                  </View>
                  <View style={ThemeStyle.flex1}>
                    <Text category="s2" style={ThemeStyle.bold}>
                      {item.name}
                    </Text>

                    {!lodashIsEmpty(item.extras) && (
                      <Text category="p2" style={ThemeStyle.dimColor}>
                        {item.extras.map((e) => e.name).join(', ')}
                      </Text>
                    )}

                    {!lodashIsEmpty(item.instructions?.instructions) && (
                      <Text style={ThemeStyle.dimColor}>
                        {item.instructions.instructions}
                      </Text>
                    )}
                  </View>
                </View>

                {index !== data.items.length - 1 && (
                  <Divider style={ThemeStyle.divider} />
                )}
              </Button>
            ))}
          </ScrollView>
        </View>

        <Button status="basic" onPress={_onOk}>
          Done
        </Button>
      </View>
    </ActionSheet>
  );
}

export default MultipleVariantSheet;
