import React from 'react';
import { createStackNavigator } from '@react-navigation/stack';

import Home from '../Screens/Home/Home';
import Search from '../Screens/Search';
import SeeAll from '../Screens/SeeAll';
import Store from '../Screens/Store';
import StoreInformation from '../Screens/StoreInformation';
import { FullReviewList } from '../Screens/StoreReviews';
import useSetUserData from '../Hooks/useSetUserData';
import routeList from './list';
import constants from '../Config/constants';

const Stack = createStackNavigator();

const HomeNavigator = (props) => {
  if (!constants.isWeb) {
    useSetUserData();
  }
  const conditionalProps = constants.isWeb
    ? { scrollToTop: true, loadHomeData: props.route.params.loadHomeData }
    : { scrollToTop: true };
  return (
    <Stack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={routeList.HOME}
    >
      <Stack.Screen
        name={routeList.HOME}
        component={Home}
        initialParams={{ ...conditionalProps }}
      />
      {!constants.isWeb && (
        <>
          <Stack.Screen name={routeList.SEARCH} component={Search} />
          <Stack.Screen name={routeList.STORE} component={Store} />
          <Stack.Screen
            name={routeList.STORE_INFORMATION}
            component={StoreInformation}
          />
          <Stack.Screen
            name={routeList.STORE_REVIEWS}
            component={FullReviewList}
          />
          <Stack.Screen name={routeList.SEE_ALL} component={SeeAll} />
        </>
      )}
    </Stack.Navigator>
  );
};

export default HomeNavigator;
