import React, { Fragment } from 'react';
import { View, SafeAreaView, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';

import { Icon, Text, useTheme } from '@ui-kitten/components';

import Button from '../Button';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import routeList from '../../Routes/list';

import withDatePicker from '../../Hooks/withDatePicker';

import PickupWordLogo from '../../Assets/PickupWordLogo.svg';

import { styles as homeStyles } from '../../Screens/Home/styles';
import { shopSelector } from '../../RTK/shop/selectors';
import { storeCartSelector } from '../../RTK/cart/selectors';
import Image from '../Image';

const Header = ({
  hasBackButton = true,
  backButtonText = 'Back to Store',
  plain = false,
  onPressBack,
}) => {
  const store_theme = useTheme();
  const navigation = useNavigation();
  const { cart } = useSelector((state) => state);
  const shopData = useSelector(shopSelector);
  const actualCart = useSelector((state) =>
    storeCartSelector(state, shopData?.id, true)
  );

  const itemCount = actualCart
    .map((item) => item.items)
    .map((arr) => arr.map((obj) => obj.quantity))
    .flat(cart.cartData.length)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const toggleRightDrawer = () =>
    navigation.getParent('RightDrawer').toggleDrawer();

  if (plain) {
    return (
      <View style={[styles.wrapper, { backgroundColor: '#fff' }]}>
        <View style={styles.leftColumnHeader}>
          {hasBackButton && (
            <Button onPress={onPressBack} style={styles.leftColumnAction} plain>
              <Icon
                name={'ios-chevron-back'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.actionIconSize}
              />
              <Text>{backButtonText}</Text>
            </Button>
          )}
        </View>
        <View style={styles.centerColumn}>
          <PickupWordLogo width={130} height={50} />
        </View>
        <View style={styles.rightColumnHeader} />
      </View>
    );
  }

  return (
    <Fragment>
      <SafeAreaView style={styles.wrapper}>
        <View
          dataSet={{ media: ids.leftColumnHeader }}
          style={styles.leftColumnHeader}
        ></View>

        <View style={styles.centerColumn}>
          <Image
            source={{ uri: shopData?.brand?.brand_logo }}
            resizeMode="contain"
            style={styles.storeLogo}
          />
        </View>

        <View
          dataSet={{ media: ids.rightColumnHeader }}
          style={styles.rightColumnHeader}
        >
          <View
            dataSet={{ media: ids.rightColumnActionContainer }}
            style={styles.rightColumnActionContainer}
          >
            <Button
              testID="cartButton"
              onPress={toggleRightDrawer}
              style={{
                ...styles.rightColumnAction,
                backgroundColor:
                  store_theme['button_color'] || ThemeColor.green,
              }}
              plain
            >
              <Icon
                name={'basket-outline'}
                fill={store_theme['button_text_color'] || ThemeColor.white}
                pack="ion"
                style={styles.actionIconSize}
              />

              {itemCount > 0 && (
                <View style={styles.cartTxtContainer}>
                  <Text
                    style={{
                      ...styles.itemCountTxt,
                      color:
                        store_theme['button_text_color'] || ThemeColor.white,
                    }}
                  >
                    {itemCount}
                  </Text>
                </View>
              )}
            </Button>
          </View>
        </View>
      </SafeAreaView>
    </Fragment>
  );
};
const { ids, styles } = StyleSheet.create({
  wrapper: {
    borderBottomColor: ThemeColor.gray,
    borderBottomWidth: 1,
    height: 65,
    display: 'flex',
    paddingLeft: '5%',
    paddingRight: '5%',
    flexDirection: 'row',
  },
  leftColumnHeader: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  centerColumn: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  rightColumnHeader: {
    ...ThemeStyle.flex1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  logoContainer: {
    display: 'flex',
  },
  actionIconSize: { width: 25, height: 25 },
  rightColumnActionContainer: {
    display: 'flex',
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
  },
  rightColumnAction: {
    width: 75,
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cartTxtContainer: { width: 25, alignItems: 'center' },
  leftColumnAction: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemCountTxt: { fontSize: 18 },
  ...homeStyles,
  storeLogo: {
    width: 130,
    height: 50,
  },
});

export default withDatePicker(React.memo(Header));
