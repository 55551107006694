import React, { useState } from 'react';
import ActionSheet from 'react-native-actions-sheet';
import { Alert, StyleSheet, View } from 'react-native';
import { useSelector } from 'react-redux';
import { Text } from '@ui-kitten/components';

import constants from '../../Config/constants';
import { mealPlan } from '../../RTK/defaultValues';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import MealPlanTypes from '../MealPlanTypes';

import commonProps from './commonProps';
import modals from './modals';
import styles from './styles';

/**
 * useful data shape is
 * {
 *   value?: 'meal plan type 3_day or 5_day
 * }
 */
function MealPlanTypeSelectorSheet({ sheetId }) {
  const mealPlanData = useSelector((state) => state.mealPlan?.data);

  const [data, setData] = useState();

  const _onTypeSelection = (type) => {
    if (type === mealPlan.type.threeDay && mealPlanData.items.length > 3) {
      return Alert.alert(
        'Ooops!',
        `Kindly remove some items on your ${constants.cartTerm} before changing your meal plan type.`
      );
    }
    modals.hide(modals.MEAL_PLAN_TYPE_SELECTOR, type);
  };

  return (
    <ActionSheet
      id={sheetId}
      onBeforeShow={setData}
      useBottomSafeAreaPadding={!constants.isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        <Text style={ThemeStyle.spacingBottom} {...SECTION_TITLE}>
          Select Meal Plan Type
        </Text>

        <View style={ThemeStyle.flexDirectionRowCenterCenter}>
          <MealPlanTypes
            value={data?.value}
            onChange={(data) => _onTypeSelection(data.value)}
          />
        </View>
      </View>
    </ActionSheet>
  );
}

const localStyles = StyleSheet.create({
  selectionContainer: {
    ...ThemeStyle.pageHorizontalSpacing,
    ...ThemeStyle.pageVerticalSpacing,
    backgroundColor: ThemeColor.lightGray,
    borderRadius: 10,
  },
  active: {
    backgroundColor: ThemeColor.green,
  },
  icon: {
    ...ThemeStyle.spacingRightMedium,
    width: 20,
    height: 20,
  },
});

export default MealPlanTypeSelectorSheet;
