import React, { Fragment } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Divider, Layout, Text } from '@ui-kitten/components';

import Button from '../../Components/Button';
import List from '../../Components/List';
import Heading from '../../Components/HorizontalListing/Heading';
import StoreCard from '../../Components/Card/Store';
import { ScaleButtonAnimation } from '../../Components/Web/Intereaction';
import constants from '../../Config/constants';
import StoreHelper from '../../Helper/Store';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

function OrderForLaterSection({
  data,
  hasOpenStore,
  whenFilter,
  isSaved,
  disableSave,
  onSave,
  onPress,
}) {
  const conditionalItemOverlayProps = constants.isWeb
    ? { style: { width: 200, marginHorizontal: 50 } }
    : {};

  const _renderItem = ({ index, item }) => {
    const { statusText, statusDescription } = StoreHelper.getStoreHourStatus({
      storeHours: item?.store_hours,
      offDates: item?.off_dates,
      preOrderTime: item?.pre_order_to_order_queue_timer,
      dateAndTime: whenFilter?.value, // to adjust on the date filter not just current date and time
    });
    const onCardPressData = { ...item, orderForLater: true }; // add flag for order for later
    const ButtonComponent = constants.isWeb ? ScaleButtonAnimation : Button;
    return (
      <StoreCard
        {...item}
        key={index}
        imageOverlay={
          <View style={[ThemeStyle.flex1, styles.itemOverlay]}>
            <View
              style={ThemeStyle.pageHorizontalSpacing}
              {...conditionalItemOverlayProps}
            >
              <Text
                category="p1"
                status="control"
                style={[ThemeStyle.textCenter, ThemeStyle.spacingBottomMedium]}
              >
                {statusText}, {statusDescription}
              </Text>
              <ButtonComponent
                buttonStyle={styles.scaleButtonStyle} // specific for web on ScaleButtonAnimation
                contentStyle={styles.scaleContentStyle} // specific for web on ScaleButtonAnimation
                size="small"
                status="success" // specific for mobile on Button component
                onPress={onPress(onCardPressData, 'Order for later')}
              >
                {constants.isWeb ? (
                  <Text category="c1" status="control">
                    Order for later
                  </Text>
                ) : (
                  'Order for later'
                )}
              </ButtonComponent>
            </View>
          </View>
        }
        isSaved={isSaved(item.id)}
        disableSave={disableSave}
        onSave={onSave(item)}
        onPress={onPress(onCardPressData, 'Order for later')}
        fullWidthOnMobile
      />
    );
  };

  return (
    <Fragment>
      {hasOpenStore && <Divider style={ThemeStyle.sectionSeparator} />}
      <View style={ThemeStyle.pageVerticalSpacingMedium}>
        <Heading title="Order for later" noHPadding />
      </View>
      {constants.isWeb ? (
        <Layout
          dataSet={{ media: ids.listContainer }}
          style={styles.listContainer}
        >
          {data.map((item, index) => _renderItem({ index: index, item: item }))}
        </Layout>
      ) : (
        <List data={data} renderItem={_renderItem} withSeparator />
      )}
    </Fragment>
  );
}

const { ids, styles } = StyleSheet.create({
  itemOverlay: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterCenter,
    backgroundColor: `#00000066`, // 40% black
  },
  listContainer: {
    flexWrap: 'wrap',
    flex: 1,

    columnGap: 10,
    [`@media ${BreakPoints.xs}`]: {
      flexDirection: 'column',
    },
    [`@media ${BreakPoints.md}`]: {
      flexDirection: 'row',
    },
  },
  scaleButtonStyle: {
    backgroundColor: ThemeColor.green,
    borderRadius: 30,
  },
  scaleContentStyle: {
    height: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  scaleBtnLabel: {
    ...ThemeStyle.regular,
    fontSize: 12,
    color: ThemeColor.white,
  },
});

export default OrderForLaterSection;
