const myFavoriteSection = {
  title: 'My Favorites',
  tag: {
    key: 'funnel',
    value: 'favorite',
  },
  size: 'medium',
};
const sections = [
  {
    title: 'Fastest Near You',
    tag: {
      key: 'distance',
      value: 5,
    },
    size: 'medium',
  },
  {
    title: 'National Favourite',
    tag: {
      key: 'funnel',
      value: 'favorite_national',
    },
    size: 'medium',
  },
  {
    title: 'Trending Right Now',
    tag: {
      key: 'funnel',
      value: 'popular_day',
    },
    size: 'medium',
  },
];

export { myFavoriteSection, sections };
export default function getHomeSections(excludeFavorite) {
  if (excludeFavorite) {
    return sections;
  }
  return [myFavoriteSection, ...sections];
}
