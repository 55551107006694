import { useEffect, useState } from 'react';
import { Linking } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../../Config/constants';
import { numberToArray } from '../../Helper';
import useSetSpecificUserData from '../../Hooks/useSetSpecificUserData';
import OrderHelper from '../../Helper/Order';
import routeList from '../../Routes/list';
import { pendingCartSelector } from '../../RTK/cart/selectors';
import { userSelector } from '../../RTK/user/selectors';
import { setHasOrderBanner } from '../../RTK/utility';
import { successfulOrderSelector } from '../../RTK/utility/selectors';
import userApi from '../../Service/api/user';
import Service from '../../Service';

let shouldFetchCart = false; // must be outside of life cycle
function useOrderController() {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const { setCartData } = useSetSpecificUserData();

  const hasOrderBanner = useSelector((state) => state.utility.hasOrderbanner);
  const successfulOrder = useSelector(successfulOrderSelector);
  const userId = useSelector((state) => userSelector(state)?.id);
  const pendingCart = useSelector(pendingCartSelector);
  const [highlightedOrderId, setHighlightedOrderId] = useState('');
  const [isRefreshing, setRefreshing] = useState(false);
  const [showDetailsOrderId, setShowDetailsOrderId] = useState('');
  const [isPollingEnabled, setPollingEnabled] = useState(true);

  const refreshOrdersParams = lodashGet(route, 'params.refreshOrders');
  const highlightOrderIdParams = lodashGet(route, 'params.highlightOrderId');

  const {
    data: orderData,
    isFetching: isOrderLoading,
    error: orderError,
    refetch: refreshOrders,
  } = useQuery({
    queryKey: [userId, 'ongoing-orders'],
    queryFn: () => userApi.getNewOrders(),
    enabled: isPollingEnabled,
    refetchInterval: 5000,
    refetchOnWindowFocus: false,
  });
  const loaderData = numberToArray(5).map(() => ({ loading: true }));
  const err = lodashGet(orderData, 'data.message') || orderError;
  const error = err ? Service.handleErrorMessage(err) : '';
  const data = error ? [] : lodashGet(orderData, 'data.result') || loaderData;
  const filteredData = OrderHelper.filterData(data, successfulOrder);

  // for handling url parameter related to order page
  useEffect(() => {
    if (refreshOrdersParams == true) {
      // if has refresh order params, boolean or string (on web, all url parameter is consider as string)
      if (!isOrderLoading) {
        refreshOrders(); // if not yet loading, refresh the order
      }
      navigation.setParams({ refreshOrders: undefined }); // clear on url parameter
    }
    if (highlightOrderIdParams) {
      // if has highlight order id params
      setHighlightedOrderId(highlightOrderIdParams); // set the highlight order id of url parameter to local state
      navigation.setParams({ highlightOrderId: undefined }); // clear on url parameter
    }
  }, [refreshOrdersParams, highlightOrderIdParams]);

  // for after fetching orders
  useEffect(() => {
    if (!isOrderLoading) {
      // if order is not loading anymore
      if (lodashIsEmpty(filteredData)) {
        // if no filteredData, disable polling
        dispatch(setHasOrderBanner(false));
        setPollingEnabled(false);
      } else {
        // if has filteredData
        // #region condition for fetching cart if before the first order is pending and status gets updated
        if (filteredData[0].status === constants.ORDER_STATUS.PENDING) {
          // add flag that it should fetch cart after the first order status is not pending
          shouldFetchCart = true;
        } else if (shouldFetchCart && !lodashIsEmpty(pendingCart)) {
          // previous order is pending and now the status gets updated, and has pending cart
          shouldFetchCart = false; // reset to false
          setCartData(); // refetch cart
        }
        // #endregion
        if (!hasOrderBanner) {
          // if order banner is not showing yet, flag to show order banner
          dispatch(setHasOrderBanner(true));
          setPollingEnabled(true);
        }
      }
      if (isRefreshing) {
        // if currently refreshing, remove flag
        setRefreshing(false);
      }
    }
  }, [isOrderLoading]);

  const _toggleShowDetails = (data) => () => {
    const shouldRemove = showDetailsOrderId === data.id;
    setShowDetailsOrderId(shouldRemove ? '' : data.id);
  };

  const _onRefresh = () => {
    setHighlightedOrderId('');
    setRefreshing(true);
    refreshOrders();
  };

  const _onStoreNamePressed = (data) => () => {
    if (constants.isWeb) {
      navigation.navigate(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.STORE,
          params: { id: data.store_details.id },
        },
      });
    } else {
      navigation.navigate(routeList.STORE, { id: data.store_details.id });
    }
  };

  const _onViewOrderPressed = (data) => () => {
    Linking.openURL(data.delivery_tracking_link);
  };

  return {
    data: filteredData,
    error,
    highlightedOrderId,
    isRefreshing,
    showDetailsOrderId,
    onRefresh: _onRefresh,
    onStoreNamePressed: _onStoreNamePressed,
    onViewOrderPressed: _onViewOrderPressed,
    toggleShowDetails: _toggleShowDetails,
  };
}

export default useOrderController;
