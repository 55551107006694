import { useTheme } from '@ui-kitten/components';
import { useState } from 'react';
import StyleSheet from 'react-native-media-query';

import BreakPoints from '../../../../Theme/styles/breakpoints';
import StoreInformationScreen from '../../../../Screens/StoreInformation';
import ModalPrompt from './ModalPrompt';

const StoreInformation = ({ modalId }: any) => {
  const [data, setData] = useState<any>();
  const store_theme = useTheme();

  return (
    <ModalPrompt
      id={modalId}
      title={'Ratings & Reviews'}
      dataSet={{ media: ids.storeInfoPopupModal }}
      mainContentWrapperStyle={styles.storeInfoPopupModal}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      scrollEnabled={true}
      onBeforeShow={setData}
    >
      <StoreInformationScreen store_theme={store_theme} />
    </ModalPrompt>
  );
};

const { ids, styles } = StyleSheet.create({
  storeInfoPopupModal: {
    height: 450,
    [`@media ${BreakPoints.xs}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 480,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 500,
    },
  },
});

export default StoreInformation;
