import React from 'react';
import { View, StyleSheet } from 'react-native';
import { PaymentIcon } from 'react-native-payment-icons';
import creditcardutils from 'creditcardutils';

import GooglePay from '../Assets/googlePay.png';
import ApplePay from '../Assets/applePay.png';
import constants from '../Config/constants';
import ThemeStyle from '../Theme/styles';
import Image from '../Components/Image';

export default function (Component) {
  return (props) => {
    // 4242 4242 4242 4242 will transform to **** **** **** 4242
    // last 4 digit is the only number visible other number will transform to asterix(*) symbol
    const maskCardNumber = (cardNumber) => {
      if (!cardNumber) return cardNumber;
      const asideFromLast4Digit = cardNumber.substr(0, cardNumber.length - 4);
      const last4digit = cardNumber.substr(-4);
      return asideFromLast4Digit.replace(/\d/g, '*') + last4digit;
    };

    const getPaymentMethodIcon = (cardNumber) => {
      const cardType = creditcardutils.parseCardType(cardNumber);
      const iconWidth = 25;

      if (cardType) {
        return (
          <View style={ThemeStyle.spacingRightMedium}>
            <PaymentIcon type={cardType} width={iconWidth + 5} />
          </View>
        );
      }

      return (
        <View style={styles.nativePaymentWrapper}>
          <Image
            source={constants.isIOS ? ApplePay : GooglePay}
            style={{ width: iconWidth, height: 8 }}
            resizeMode="contain"
          />
        </View>
      );
    };

    return (
      <Component
        {...props}
        maskCardNumber={maskCardNumber}
        getPaymentMethodIcon={getPaymentMethodIcon}
      />
    );
  };
}

const styles = StyleSheet.create({
  nativePaymentWrapper: {
    ...ThemeStyle.spacingRightMedium,
    borderWidth: StyleSheet.hairlineWidth,
    paddingVertical: 5,
    paddingHorizontal: 2,
  },
});
