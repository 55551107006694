import lodashIsEmpty from 'lodash/isEmpty';

const page_limit = 10;

function getLimitAndOffset(page_number, returnObject) {
  if (returnObject) {
    return {
      limit: page_limit,
      offset: (page_number - 1) * page_limit,
    };
  }

  return `limit=${page_limit}&offset=${(page_number - 1) * page_limit}`;
}

function getUrlParameterFromFilter(
  parentCategory,
  tagFilter = [],
  categoryFilter = [],
  whenFilter,
  startWithAmpersand
) {
  let urlParams = '';

  if (!lodashIsEmpty(parentCategory)) {
    urlParams += `&product_type=${parentCategory.value}`;
  }

  tagFilter.forEach((tag) => {
    const rootSeparator = urlParams || startWithAmpersand ? '&' : '?';
    if (tag.value) {
      urlParams += `${rootSeparator}${tag.paramName}=${tag.value}`;
    } else if (tag.values) {
      tag.values.forEach((tagVal) => {
        const separator = urlParams || startWithAmpersand ? '&' : '?';
        urlParams += `${separator}${tag.paramName}=${tagVal.value}`;
      });
    }
  });

  const separator = urlParams || startWithAmpersand ? '&' : '?';
  if (!lodashIsEmpty(categoryFilter)) {
    // intended, bcs it will always contain 1 object just to match with tag filter format
    const category = categoryFilter[0];
    urlParams += `${separator}tags=${category.value}`;
  }

  if (lodashIsEmpty(whenFilter)) {
    urlParams += `${separator}open_only=false`;
  } else {
    urlParams += `${separator}open_only=true&order_date=${whenFilter.date}&order_time=${whenFilter.time}`;
  }

  return urlParams;
}

function getUrlParameter(url) {
  // Remove the scheme (pickupcustomerapp://) and split the remaining part by '?' to get the query string
  const queryString = url.split('?')[1];

  // Split the query string by '&' to get individual key-value pairs
  const pairs = queryString.split('&');

  // Initialize an empty object to hold the parameters
  let params = {};

  // Loop through the key-value pairs and split by '=' to get the key and value
  pairs.forEach((pair) => {
    const [key, value] = pair.split('=');
    params[key] = value;
  });

  return params;
}
export default {
  page_limit,
  getUrlParameterFromFilter,
  getUrlParameter,
  getLimitAndOffset,
};
