import { combineReducers, Reducer } from 'redux';
import { createTransform, persistReducer } from 'redux-persist';
import { Storage } from 'redux-persist/es/types';

import constants from '../Config/constants';

import authenticationReducer from './authentication';
import userReducer from './user';
import filterReducer from './filter';
import shopReducer from './shop';
import productReducer from './product';
import cartReducer from './cart';
import checkoutReducer from './checkout';
import dataCacheReducer from './dataCache';
import utilityReducer from './utility';
import favouriteReducer from './favourite';
import searchReducer from './search';
import errorReducer from './error';
import reservationReducer from './reservation';
import mealPlanReducer from './mealPlan';
import noSessionReducer from './noSession';
import shopPersistReducer from './shopPersist';

const persistedKeys = {
  // used on web and mobile
  CART: 'cart',
  CHECKOUT: 'checkout',
  DATA_CACHE: 'dataCache',
  FAVORITE: 'favorite',
  NOSESSION: 'noSession', // dont remove
  SEARCH: 'search',
  SHOPPERSIST: 'shopPersist',
  USER: 'user',
  UTILITY: 'utility',
  // specific on web
  AUTH: 'authentication',
  FILTER: 'filter',
  FILTER_WEB: 'filterWeb',
};

export default (storage: Storage, onClear?: Function) => {
  const persistedKeysToClear = Object.keys(persistedKeys)
    .map((key) => persistedKeys[key]) // get the keys value
    .filter((val) => val !== persistedKeys.NOSESSION); // exclude noSession

  const createPersistReducer = (key: string, reducer: Reducer, options?: any) =>
    persistReducer({ key, storage, ...options }, reducer);

  const commonReducers = {
    // Persisted reducers
    cart: createPersistReducer(persistedKeys.CART, cartReducer, {
      blacklist: ['isAddingOrUpdatingCart'],
    }),
    checkout: createPersistReducer(persistedKeys.CHECKOUT, checkoutReducer),
    dataCache: createPersistReducer(persistedKeys.DATA_CACHE, dataCacheReducer),
    favourite: createPersistReducer(persistedKeys.FAVORITE, favouriteReducer),
    noSession: createPersistReducer(persistedKeys.NOSESSION, noSessionReducer),
    search: createPersistReducer(persistedKeys.SEARCH, searchReducer),
    shopPersist: createPersistReducer(
      persistedKeys.SHOPPERSIST,
      shopPersistReducer
    ),
    user: createPersistReducer(persistedKeys.USER, userReducer),
    utility: createPersistReducer(persistedKeys.UTILITY, utilityReducer, {
      blacklist: ['hasOrderbanner', 'isOrderBannerMinimized', 'orderDetails'],
    }),
    // Non persisted reducers
    mealPlan: mealPlanReducer,
    product: productReducer,
    reservation: reservationReducer,
    shop: shopReducer,
  };

  let combinedReducers;
  if (constants.isWeb) {
    const filterTransformBlackList = createTransform(
      // transform state coming from storage
      (inboundState, key) => {
        if (key === 'home') {
          return {
            ...(inboundState as object),
            when: undefined,
          };
        }
        return inboundState;
      }
    );
    combinedReducers = combineReducers({
      // Web persisted reducers
      authentication: createPersistReducer(
        persistedKeys.AUTH,
        authenticationReducer
      ),
      filter: createPersistReducer(persistedKeys.FILTER, filterReducer, {
        transforms: [filterTransformBlackList],
      }),
      // Web non persisted reducers
      error: errorReducer,
      ...commonReducers,
    });
  } else {
    combinedReducers = combineReducers({
      // Mobile non persisted reducers
      filter: filterReducer,
      ...commonReducers,
    });
  }

  return (state: any, action: any) => {
    if (action.type === 'user/logout') {
      onClear?.(); // call onClear if parameter is passed
      // clear persisted data
      persistedKeysToClear.forEach((key) =>
        storage.removeItem(`persist:${key}`)
      );
      // we return the noSession object instead of undefined
      // because noSession contain state of app behaviour e.g: remember login credential etc.
      return combinedReducers({ noSession: state.noSession }, action);
    }
    return combinedReducers(state, action);
  };
};
