import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, useTheme } from '@ui-kitten/components';

import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import { ScaleButtonAnimation } from './Web/Intereaction';

const scrollDistance = 400;
const ArrowControl = ({
  listRef,
  scrollOffset,
  isEndReached,
  children,
  position = 'top',
}) => {
  const disabledPrev = scrollOffset <= 0;
  const disabledNext = isEndReached;
  const hide = disabledPrev && disabledNext; // if both disable, it means not scrollable
  const store_theme = useTheme();

  const _prevSlide = () => {
    if (!disabledPrev) {
      const prevDistance = scrollOffset - scrollDistance;
      listRef.current._scrollToOffset({ animated: true, offset: prevDistance });
    }
  };

  const _nextSlide = () => {
    if (!disabledNext) {
      const nextDistance = scrollOffset + scrollDistance;
      listRef.current._scrollToOffset({ animated: true, offset: nextDistance });
    }
  };

  if (hide) {
    return null;
  }

  return (
    <View
      style={
        position == 'top'
          ? styles.controlButtonContainer
          : styles.controlButtonContainerMiddle
      }
    >
      <ScaleButtonAnimation
        testID="prev_btn"
        onPress={_prevSlide}
        buttonStyle={styles.scaleControlButton}
        contentStyle={styles.controlButton}
        scale={1.2}
        disabled={disabledPrev}
      >
        <Icon
          name={'chevron-back'}
          fill={
            disabledPrev
              ? ThemeColor.gray
              : store_theme['icon_color'] || ThemeColor.green
          }
          pack="ion"
          style={styles.controlIcon}
        />
      </ScaleButtonAnimation>

      {children}

      <ScaleButtonAnimation
        testID="next_btn"
        onPress={_nextSlide}
        buttonStyle={styles.scaleControlButton}
        contentStyle={styles.controlButton}
        disabled={disabledNext}
        scale={1.2}
      >
        <Icon
          name={'chevron-forward'}
          fill={
            disabledNext
              ? ThemeColor.gray
              : store_theme['icon_color'] || ThemeColor.green
          }
          pack="ion"
          style={styles.controlIcon}
        />
      </ScaleButtonAnimation>
    </View>
  );
};

const styles = StyleSheet.create({
  control_container: {
    ...ThemeStyle.flexDirectionRow,
  },
  controlButtonContainer: {
    width: 75,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    ...ThemeStyle.spacingBottomSmall,
  },
  controlButtonContainerMiddle: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },

  scaleControlButton: {
    borderWidth: 1,
    borderColor: ThemeColor.gray100,
    backgroundColor: ThemeColor.white,
    borderRadius: 30,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.23,
    shadowRadius: 2.62,
    elevation: 4,
  },
  controlButton: {
    width: 30,
    height: 30,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },

  controlIcon: { width: 20, height: 20 },
});

export default ArrowControl;
