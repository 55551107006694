import { clientAWS } from './client';
import { assets } from './endpoints';
import storeApi from './store';

function getCuisinesAWS() {
  const path = assets.TAGS_JSON;
  return clientAWS.get(path);
}

function getSearchResults(searchValue, urlParams, isTag, ...args) {
  let params = '';
  if (isTag) {
    params += '?tags=' + searchValue;
  } else {
    params += '?name=' + searchValue;
  }
  if (urlParams) {
    params += urlParams;
  }
  return storeApi.getAllStores(params, ...args);
}

export default {
  getSearchResults,
  getCuisinesAWS,
};
