import { useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useDispatch } from 'react-redux';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';

import modals from '../Components/Sheets/modals';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import constants from '../Config/constants';
import messages from '../Config/messages';
import CartHelper from '../Helper/Cart';
import useToast from '../Hooks/useToast';
import { reorderCart } from '../RTK/cart';
import { updateCheckoutDetails } from '../RTK/checkout';
import { setItems, setType } from '../RTK/mealPlan';
import { setFirstMealPlanCheckout } from '../RTK/utility';
import { checkout, mealPlan } from '../RTK/defaultValues';
import routeList from '../Routes/list';
import storeApi from '../Service/api/store';
import userApi from '../Service/api/user';

import useCart from './useCart';
import useStorePersist from './useStorePersist';

const BUTTON_VALUE = {
  CANCEL: 'cancel',
  PREORDER: 'preorder',
  CONTINUE: 'continue',
};

function useOrderAgain() {
  const navigation = useNavigation();
  const sheetRef = useRef();
  // orderRef & reorderResultRef, doesn't need UI update so it just can be a ref for faster reflect of data changed
  const orderRef = useRef(); // data from order history
  const reorderResultRef = useRef(); // data result from reorder
  const dispatch = useDispatch();
  const toast = useToast();
  const { addOrUpdateCart } = useCart();
  const { getSavedStore } = useStorePersist();
  const { showModalPrompt } = useModalPrompt(); // for web

  // for when selecting pre order date
  const [scheduleList, setScheduleList] = useState([]);
  const [showScheduleSelection, setShowScheduleSelection] = useState(false);
  const [submitting, setSubmitting] = useState(false); // if the reorder is submitting

  /**
   * Callback after selecting pre order date
   * @param {*} date - string: ISO string format
   */
  const _onScheduleSelected = async (date) => {
    setShowScheduleSelection(false);

    const dateToDayJs = dayjs(date);

    const { plan, store_id } = reorderResultRef.current;

    const preOrderDate = {
      label: dateToDayJs.format(constants.DATE_DISPLAY_FORMAT),
      value: dateToDayJs.toISOString(),
      date: dateToDayJs.format(constants.DBDATE_FORMAT),
      time: dateToDayJs.format(constants.DBTIME_FORMAT),
    };

    const errors = [];
    const successItems = [];
    for (let i = 0; i < plan.items.length; i++) {
      const item = plan.items[i];
      await new Promise((resolve) => {
        addOrUpdateCart({
          from: 'Reorder',
          item,
          orderType: plan.order_type || constants.ORDER_TYPES.DELIVERY,
          preOrderDate,
          silent: true,
          store: { id: store_id },
          onCancel: () => {
            errors.push(`${item.name}: Canceled`);
            resolve();
          },
          onError: ({ message }) => {
            errors.push(`${item.name}: ${message}`);
            resolve();
          },
          onSuccess: () => {
            successItems.push(item);
            resolve();
          },
        });
      });
    }
    if (errors.length !== 0) {
      // if some item encounter error while adding to cart
      if (plan.items.length === errors.length) {
        // all items is error adding to cart, reset the state
        _resetState();
      }
      _showAlert('Item error', errors.join('\n'));
    }
    if (plan.items.length !== errors.length) {
      // if some item is added to cart, redirect to cart page
      _successReorder({
        storeId: store_id,
        items: successItems,
        orderType: plan.order_type || constants.ORDER_TYPES.DELIVERY,
        preOrderDate,
      });
    }
  };

  const _resetState = () => {
    orderRef.current = null;
    reorderResultRef.current = null;
    setScheduleList([]);
    setShowScheduleSelection(false);
    setSubmitting(false);
  };

  const _showAlert = (title, message) => {
    if (constants.isWeb) {
      alert(`${title}\n${message}`);
    } else {
      Alert.alert(title, message);
    }
  };

  /**
   * Call this function Regardless if all or partial item is being added to cart, as long as it will going to redirect on cart page
   * @param {*} storeId - string: store id
   * @param {*} items - array of object: plan.items or item to be added to cart
   * @param {optional} orderType - string: selected order type on checkout
   * @param {optional} preOrderDate - object: { label: string, value: Date ISO string format, date: string, time: string } selected pre order date and time on checkout
   */
  const _successReorder = async ({
    storeId,
    items,
    orderType,
    preOrderDate,
  }) => {
    const savedStoreInformation = getSavedStore(storeId);
    dispatch(
      reorderCart({
        storeId,
        storeName: savedStoreInformation?.name || '',
        items,
      })
    );
    if (orderType) {
      // get order type data
      const selectedOrderType = lodashFind(constants.ORDER_TYPE_DATA, {
        value: orderType,
      });
      // update order type
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.ORDER_TYPE,
          keyValue: selectedOrderType,
        })
      );
    }
    if (!lodashIsEmpty(preOrderDate)) {
      // update delivery schedule
      dispatch(
        updateCheckoutDetails({
          store_id: storeId,
          keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
          keyValue: preOrderDate,
        })
      );
    }
    _resetState(); // reset states
    await sheetRef.current.hide(); // close order again sheet
    toast.show(messages.ADDED_CART_ITEM);
    if (constants.isWeb) {
      navigation.navigate(routeList.STORE, { id: storeId });
    } else {
      navigation.navigate(routeList.BASKET, { activeTab: 0 }); // redirect to basket page -> basket tab
    }
  };

  const _getFlags = () => {
    const plan = reorderResultRef.current?.plan || {};
    const reasons = reorderResultRef.current?.status?.reasons || [];
    const isMealPlanOngoing = reasons.includes('meal_plan_ongoing');
    const isMealPlanUnavailable = reasons.includes('meal_plan_unavailable');
    const isMealPlanUnsupported = reasons.includes('meal_plan_unsupported');
    const isAsap = plan.order_time === 'asap_order_time';
    const isSupporPreOrder = reasons.includes('store_supports_pre_order');
    const isToday = plan.order_date === dayjs().format(constants.DBDATE_FORMAT);
    return {
      isAsap,
      isMealPlanOngoing,
      isMealPlanUnavailable,
      isMealPlanUnsupported,
      isSupporPreOrder,
      isToday,
    };
  };

  const _getPrompt = () => {
    const plan = reorderResultRef.current?.plan || {
      order_date: '',
      order_time: '',
      items: [],
    };
    const isSomeAddedToCart = plan.items.length !== 0;
    const { isAsap, isSupporPreOrder } = _getFlags();
    const buttons = [
      {
        status: 'danger',
        text: 'Cancel',
        value: BUTTON_VALUE.CANCEL,
      },
      ...(isSupporPreOrder
        ? [
            {
              status: 'info',
              text: 'Pre-Order',
              value: BUTTON_VALUE.PREORDER,
            },
          ]
        : []),
      ...(isSomeAddedToCart
        ? [
            {
              status: 'success',
              text: 'Continue',
              value: BUTTON_VALUE.CONTINUE,
            },
          ]
        : []),
    ];
    let title = '';
    let message = '';
    let suffix = '';
    if (!isAsap) {
      title = 'Closed Store';
      message = `This order will be placed for ${dayjs(
        `${plan.order_date} ${plan.order_time}`
      ).format(constants.DATE_DISPLAY_FORMAT)}.`;
      suffix = ' Would you like to continue?';
    } else if (isSupporPreOrder) {
      suffix = ' Would you prefer to choose a different timeslot?';
    }
    return {
      buttons,
      title,
      message: `${message} 😔 ${suffix}`,
    };
  };

  const _handleUserDecision = async (decision) => {
    const { plan, store_id } = reorderResultRef.current;
    const isSomeAddedToCart = plan.items.length !== 0;
    const { isAsap } = _getFlags();
    if (lodashIsEmpty(decision) || decision?.value === BUTTON_VALUE.CANCEL) {
      if (isSomeAddedToCart) {
        for (let i = 0; i < plan.items.length; i++) {
          const item = plan.items[i];
          const payload = {
            cart_details_id: CartHelper.getCartDetailsId(item),
          };
          await userApi.removeCart(payload);
        }
      }
      _resetState();
      sheetRef.current.hide(); // close order again sheet
    } else if (decision.value === BUTTON_VALUE.PREORDER) {
      const { ok, data } = await storeApi.getStoreOrderDates(store_id);
      if (ok) {
        if (constants.isWeb) {
          const result = await showModalPrompt(
            MODALPROMPT.changeDateAndTimeSchedule,
            {
              storeHours: data,
              modalTitle: 'Select Pre-Order date',
            }
          );
          if (!lodashIsEmpty(result)) {
            _onScheduleSelected(result?.value);
          } else {
            onCancelReOrder();
          }
        } else {
          setScheduleList(data); // update schedule list state with response data
          setShowScheduleSelection(true);
        }
      } else {
        toast.show(
          'Error getting available time please try again later.',
          false
        );
      }
    } else if (decision.value === BUTTON_VALUE.CONTINUE) {
      let preOrderDate = undefined;
      if (!isAsap) {
        const dateToDayJs = dayjs(`${plan.order_date} ${plan.order_time}`);
        preOrderDate = {
          label: dateToDayJs.format(constants.DATE_DISPLAY_FORMAT),
          value: dateToDayJs.toISOString(),
          date: dateToDayJs.format(constants.DBDATE_FORMAT),
          time: dateToDayJs.format(constants.DBTIME_FORMAT),
        };
      }
      _successReorder({
        storeId: store_id,
        items: plan.items,
        orderType: plan.order_type || constants.ORDER_TYPES.DELIVERY,
        preOrderDate,
      });
    }
  };

  const _handleUserDecisionPrompt = async () => {
    const promptProps = _getPrompt();
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.prompt, {
        ...promptProps,
        buttons: promptProps.buttons
          .reverse()
          .map((e) => ({ ...e, onPress: () => _handleUserDecision(e) })),
        onCloseButtonClick: () => _handleUserDecision(),
      });
    } else {
      const result = await modals.show(modals.PROMPT, promptProps);
      _handleUserDecision(result);
    }
  };

  const _proceedToMealPlan = async () => {
    const { meal_plan_index, store_details } = orderRef.current;
    const { plan } = reorderResultRef.current;
    const storeId = store_details.id;
    const isThreeDay = meal_plan_index.split('/').pop() == 3; // meal_plan_index is 1/3 or 1/5
    const mealPlanType = isThreeDay
      ? mealPlan.type.threeDay
      : mealPlan.type.fiveDay;
    const mealPlanOrderType = lodashFind(constants.ORDER_TYPE_DATA, {
      value: constants.ORDER_TYPES.MEAL_PLAN,
    });
    _resetState(); // reset states
    // update store order type
    dispatch(
      updateCheckoutDetails({
        store_id: storeId,
        keyToUpdate: checkout.keys.ORDER_TYPE,
        keyValue: mealPlanOrderType,
      })
    );
    dispatch(setType(mealPlanType)); // select meal plan 3 or 5 days plan
    dispatch(setItems(plan.items.map((e) => ({ ...e, image: e.image_url })))); // set meal plan items
    dispatch(setFirstMealPlanCheckout()); // to remove the hint just in case since user must already know how to order meal plan
    await sheetRef.current.hide(); // close order again sheet
    await new Promise((resolve) => setTimeout(resolve, 100)); // need to add some delay for some reason it cause screen freeze when hide the sheet then navigate
    navigation.navigate(routeList.STORE, { id: storeId }); // nagivate to store page
    if (!constants.isWeb) {
      await new Promise((resolve) => setTimeout(resolve, 100)); // need to add some delay for some reason opening a sheet after just navigate cause the sheet to close unexpectedly
      modals.show(modals.MEAL_PLAN_LIST);
    }
  };

  /**
   *
   * @param {*} orderData - order data from order history
   * @param {required} sheetRef - on mobile it's for closing the action sheet, on web just pass the function for closing the modal
   */
  const _onSubmit = async (orderData, actionSheetRef) => {
    sheetRef.current = actionSheetRef?.current || { hide: actionSheetRef };
    orderRef.current = orderData;
    const isMealPlan = orderData.meal_plan;
    setSubmitting(true);
    const { ok, data } = await userApi.reorder(orderData.id);
    if (ok) {
      reorderResultRef.current = data;
      const { plan, status, store_id } = data;
      const {
        isAsap,
        isMealPlanOngoing,
        isMealPlanUnavailable,
        isMealPlanUnsupported,
        isToday,
      } = _getFlags();
      if (status.possible) {
        if (isMealPlan) {
          // if reordering a meal plan
          _proceedToMealPlan();
        } else if (isToday && isAsap) {
          _successReorder({
            storeId: store_id,
            items: plan.items,
            orderType: plan.order_type,
          });
        } else {
          _handleUserDecisionPrompt();
        }
      } else if (isMealPlan) {
        // TODO - button to go to store menu
        let message = 'This meal plan cannot be reorder.';
        if (isMealPlanUnavailable) {
          message = 'Store meal plan orders are currently not available.';
        } else if (isMealPlanOngoing) {
          message =
            'You currently have an existing meal plan for the current or following weeks.';
        } else if (isMealPlanUnsupported) {
          message =
            'Store has stopped supporting meal plan orders. Please contact them if you have an on going plan.';
        }
        _showCannotReorder(message);
      } else {
        _showCannotReorder(
          "We're sorry, but the item(s) in your order can't be reordered."
        );
      }
    } else {
      _resetState();
      toast.show(
        'Reorder is not working at the moment, please try again later.',
        false
      );
    }
  };

  const _goToStore = () => {
    const storeId = reorderResultRef.current.store_id;
    if (constants.isWeb) {
      navigation.navigate(routeList.HOME, {
        screen: 'MainMenu',
        params: {
          screen: routeList.STORE,
          params: { id: storeId },
        },
      });
    } else {
      navigation.navigate(routeList.STORE, { id: storeId });
    }
    _resetState();
  };

  const _showCannotReorder = (message) => {
    const promptProps = {
      title: 'Reorder Unavailable',
      message: message,
      buttons: [
        {
          text: 'Go to menu',
          status: 'success',
          onPress: _goToStore,
          value: 'menu',
        },
        { text: 'Close', status: 'danger', onPress: _resetState },
      ],
    };
    sheetRef.current.hide();
    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.prompt, promptProps);
    } else {
      setTimeout(async () => {
        const result = await modals.show(modals.PROMPT, promptProps);
        if (!lodashIsEmpty(result) && result?.value === 'menu') {
          _goToStore();
        } else {
          _resetState();
        }
      }, 250);
    }
  };

  return {
    scheduleList,
    showScheduleSelection,
    submitting,
    onCancelReOrder: _resetState,
    onScheduleSelected: _onScheduleSelected,
    onSubmit: _onSubmit,
  };
}

export default useOrderAgain;
