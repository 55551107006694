import dayjs from 'dayjs';
import lodashFind from 'lodash/find';
import lodashFindIndex from 'lodash/findIndex';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';

import StoreHelper from './Store';

const {
  DATE_DISPLAY_FORMAT,
  DBDATE_FORMAT,
  DBTIME_FORMAT,
  TIME_ONLY_DISPLAY_FORMAT,
} = constants;

const ASAP = {
  value: 'asap',
  label: 'ASAP',
  date: '',
  time: '',
  canAsap: true,
};

/**
 * Get result of isoDate from storeHours, the return can be store on checkout delivery schedule value
 * @param {String} isoDate - Date ISO format
 * @param {Array} storeHours - An array containing the store hours returned from API https://marketplace-api.stg.pickup.ph/api#/Stores/StoresController_getDates
 * @returns {Object} An object containing information about the store hours:
 * {
 *   ok: boolean // true if found on storeHours, else false
 *   data: {
 *     label: string, // date and time humanize format.
 *     value: string, // ISO format of date & time.
 *     date: string, // Date string DB format.
 *     time: string // Time string DB format.
 *   }
 * }
 */
const getDateFromStoreHours = (isoDate, storeHours) => {
  if (!isoDate) return { ok: false };
  const isoDayjs = dayjs(isoDate);
  const dbDate = isoDayjs.format(DBDATE_FORMAT);
  const dbTime = isoDayjs.format(DBTIME_FORMAT);
  const filterByDate = lodashFind(storeHours, { value: dbDate });
  const timeOptions = filterByDate?.time_options || [];
  const filterByTime = lodashFind(timeOptions, { value: dbTime });
  const isOk = !lodashIsEmpty(filterByTime);
  return {
    ok: isOk,
    data: isOk
      ? {
          value: isoDate,
          label: dayjs(isoDate).format(DATE_DISPLAY_FORMAT),
          date: dbDate,
          time: dbTime,
        }
      : undefined,
  };
};

/**
 * Get schedule information when select on date picker in cart or checkout page.
 * @returns {Object} An object containing information about the selected schedule:
 *   - label: string, Display label for the reschedule information.
 *   - value: string, Unique value identifier for the reschedule information.
 *   - date: string, Date string representing the rescheduled date.
 *   - time: string, Time string representing the rescheduled time.
 * or if the function encounter error
 * @returns {Object} An object containing information about the selected schedule:
 *   - error: Boolean, Indicates if there is an error in obtaining reschedule information.
 */
const getSelectedSchedule = (selectedDate, dateOptions) => {
  const selectedDatejs = dayjs(selectedDate);
  const currentDateAndTimejs = dayjs();
  const result = {
    label: selectedDatejs.format(DATE_DISPLAY_FORMAT),
    value: selectedDatejs.toISOString(),
    date: selectedDatejs.format(DBDATE_FORMAT),
    time: selectedDatejs.format(DBTIME_FORMAT),
  };
  const isASAP = selectedDatejs.isBefore(currentDateAndTimejs);
  const schedListIndex = lodashFindIndex(dateOptions, {
    date: result.date,
    time: result.time,
  });
  if (isASAP || schedListIndex >= 0) {
    return isASAP ? ASAP : result;
  } else {
    return { error: true };
  }
};

/**
 * Check if can order asap base on storeHours
 * @param {Array} storeHours - An array containing the store hours returned from API https://marketplace-api.stg.pickup.ph/api#/Stores/StoresController_getDates
 * @returns {Boolean} true if can asap, false if cannot
 */
const isCanAsap = (storeHours) => {
  const firstStoreHours = lodashGet(storeHours, '[0].time_options[0].value');
  return firstStoreHours === 'asap_order_time'; // if first value of time_options is asap, it means user can asap order
};

/**
 * Determines if a checkout is allowed based on store hours, off dates, preparation time,
 * and the provided date and time (date filter).
 * @param {Array} storeHours - An array containing the store hours for each day.
 * @param {Array} offDates - An array containing dates when the store is closed. (Optional)
 * @param {number} prepTime - The preparation time required for the order in minutes.
 * @param {string} dateAndTime - The date and time ISO format. (Optional)
 * @returns {Object} An object containing information about the checkout status:
 *   - isClosed: Boolean, true if the store is closed now or the provided date and time.
 *   - isClosing: Boolean, true if the store is closing soon with consideration for preparation time.
 */
const isCanCheckout = (
  storeHours = [],
  offDates = [],
  prepTime = 0,
  dateAndTime
) => {
  const totalPrepTime = prepTime + constants.PREP_TIME_ALLOWANCE;

  const storeAvailability = StoreHelper.getStoreAvailability(
    storeHours,
    offDates,
    'object',
    dateAndTime
  );

  const now = dayjs(dateAndTime); // or dateAndTime value
  const closingTimeFormat = TIME_ONLY_DISPLAY_FORMAT.replace('a', 'A'); // need to change small to uppercase to match the format of closing
  const closing = dayjs(
    `${now.format(DBDATE_FORMAT)} ${storeAvailability?.closing}`, // same date as now
    `${DBDATE_FORMAT} ${closingTimeFormat}`
  );

  const opening = dayjs(
    `${now.format(DBDATE_FORMAT)} ${storeAvailability?.opening}`, // same date as now
    `${DBDATE_FORMAT} ${closingTimeFormat}`
  );
  const closingWithPreptime = closing.subtract(totalPrepTime, 'minute');

  return {
    isClosed: storeAvailability?.isOpen
      ? now.isSameOrAfter(closing)
      : now.isAfter(opening),
    isClosing: storeAvailability?.isOpen
      ? now.isSameOrAfter(closingWithPreptime)
      : false,
  };
};

export default {
  ASAP,
  getDateFromStoreHours,
  getSelectedSchedule,
  isCanAsap,
  isCanCheckout,
};
