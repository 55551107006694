import { useDispatch, useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';

import { changeWhenFilter } from '../RTK/filter';
import { checkout } from '../RTK/defaultValues';
import { storeCheckoutSelector } from '../RTK/checkout/selectors';

import CheckoutHelper from '../Helper/Checkout';

import constants from '../Config/constants';
import messages from '../Config/messages';
import useToast from './useToast';
import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';

import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import { updateCheckoutDetails } from '../RTK/checkout';

type ErrorHandler = {
  error?: {
    errorType?: string;
    title?: string;
    message?: string;
  };
  isAcceptingAdvanceOrder?: boolean;
};
const { ERROR_TYPE, ORDER_TYPES } = constants;
const { ASAP } = CheckoutHelper;
const useError = (storeId: string) => {
  const dispatch = useDispatch<any>();
  const storeCheckoutData = useSelector((state) =>
    //@ts-ignore
    storeCheckoutSelector(state, storeId)
  );
  const toast = useToast();
  const { showModalPrompt } = useModalPrompt();

  const orderType = storeCheckoutData[checkout.keys.ORDER_TYPE];
  const isPickup = orderType?.value?.includes(ORDER_TYPES.PICKUP);

  const _errorCartOrCheckoutHandler = async ({
    error,
    isAcceptingAdvanceOrder,
  }: ErrorHandler) => {
    ///This condition if the item or category reached the limit
    if (error?.title === messages.ITEM_LIMIT) {
      if (isAcceptingAdvanceOrder) {
        const result = await showModalPrompt(
          MODALPROMPT.changeDateAndTimeSchedule,
          {
            error: {
              errorType: error?.title,
              title: error?.title,
              message: error?.message,
            },
          }
        );
        _onChangeDateOrTime(result);
      } else {
        // open modal for changing time for today
        const result = await showModalPrompt(MODALPROMPT.changeTimeSchedule, {
          title: `Select ${isPickup ? 'Pickup' : 'Dispatch'} Time`,
          error: {
            errorType: error?.title,
            title: error?.title,
            message: error?.message,
          },
        });
        _onChangeDateOrTime(result);
      }
    }
    ///This condition if unavailable but accepting pre-order
    else if (
      error?.title === ERROR_TYPE.UNAVAILABLE &&
      isAcceptingAdvanceOrder
    ) {
      const result = await showModalPrompt(
        MODALPROMPT.changeDateAndTimeSchedule,
        {
          error: {
            errorType: error?.title,
            title: messages.UPDATE_ORDER_SCHEDULE,
            message: `Ooops! Looks like some of the item in your ${constants.cartTerm} went out of stock while you were checking out. To proceed, select another time slot in the delivery schedule.`,
          },
        }
      );
      _onChangeDateOrTime(result);
    }
    ///This condition if unavailable but not accepting pre-order
    else if (
      error?.title === ERROR_TYPE.UNAVAILABLE &&
      !isAcceptingAdvanceOrder
    ) {
      const result = await showModalPrompt(MODALPROMPT.changeTimeSchedule, {
        title: `Select ${isPickup ? 'Pickup' : 'Dispatch'} Time`,
        // value: deliverySchedule,
        error: {
          errorType: ERROR_TYPE.UNAVAILABLE,
          title: ERROR_TYPE.UNAVAILABLE,
          message: `Ooops! Looks like some of the item in your ${constants.cartTerm} went out of stock while you were checking out. To proceed, select another time slot in the delivery schedule.`,
        },
      });
      _onChangeDateOrTime(result);
    }
    ///This condition if the store is closed but accepting pre-order
    else if (
      (error?.title === messages.STORE_CLOSING.title ||
        error?.title === messages.STORE_CLOSED_MOBILE.title) &&
      isAcceptingAdvanceOrder
    ) {
      const result = await showModalPrompt(
        MODALPROMPT.changeDateAndTimeSchedule,
        {
          error: {
            errorType: error?.title,
            title: messages.UPDATE_ORDER_SCHEDULE,
            message: error?.message,
          },
        }
      );
      _onChangeDateOrTime(result);
    } else {
      showModalPrompt(MODALPROMPT.errorModal, {
        title: error?.title,
        message: error?.message,
      });
    }
  };

  const _onChangeDateOrTime = (result) => {
    if (!lodashIsEmpty(result?.label)) {
      if (result?.label === ASAP.label) {
        dispatch(updateCheckoutDetails(undefined));
        dispatch(changeWhenFilter(undefined));
      } else {
        const data = {
          store_id: storeId,
          keyToUpdate: checkout.keys.DELIVERY_SCHEDULE,
          keyValue: result,
        };
        dispatch(updateCheckoutDetails(data));
        dispatch(changeWhenFilter(result));
      }
      toast.show(
        `${messages.CHANGE_SCHEDULE} ${result.label || ASAP.label}.`,
        true
      );
    }
  };
  return {
    ErrorCartOrCheckoutHandler: _errorCartOrCheckoutHandler,
  };
};
export default useError;
