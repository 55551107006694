import React, { Fragment, useState } from 'react';
import { View, FlatList, SafeAreaView, TouchableOpacity } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';

import { Icon, Text, Input } from '@ui-kitten/components';
import Popup from 'reactjs-popup';
import dayjs from 'dayjs';

import ScreenSize from '../../Helper/ScreenSize';

import Button from '../Button';
import DatePicker from '../DatePicker';
import Image from '../Image';

import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

import { addressSelector, isLoggedInSelector } from '../../RTK/user/selectors';
import { allCartSelector } from '../../RTK/cart/selectors';
import { whenFilterSelector } from '../../RTK/filter/selectors';

import routeList from '../../Routes/list';

import withDatePicker from '../../Hooks/withDatePicker';

import useAddress from '../../Hooks/useAddress';

import { LoadingIndicator } from '../Loader';

import { MODALPROMPT } from '../Web/Modal/ModalPrompt/config';

import useModalPrompt from '../Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { AddressSelector } from '../Web/Address';

import constants from '../../Config/constants';
//@ts-ignore : svg type error in ts
import PickupWordLogo from '../../Assets/PickupWordLogo.svg';

const iconSize = 20;

type HeaderProps = {
  isVisibleSearchStore?: boolean;
  onTextChanged: (e: string) => void;
  isPressableCart?: boolean;
  hasBackButton?: boolean;
  backButtonText?: string;
  plain: boolean;
  onPressBack?: () => void;
  store_id?: string;
  onConfirm?: (date: string) => void;
};
const Header = ({
  isVisibleSearchStore = true,
  onTextChanged,
  isPressableCart = true,
  hasBackButton = true,
  backButtonText = 'Back to Store',
  plain = false,
  onPressBack,
  store_id,
  onConfirm,
  ...props
}: HeaderProps) => {
  const navigation = useNavigation<any>();
  const route = useRoute();
  const { xl } = ScreenSize();
  const { showModalPrompt, hideModalPrompt } = useModalPrompt();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const { setActiveAddress, isLoading } = useAddress();
  const [searchValue, setSearchValue] = useState('');
  const [dateVisible, setDateVisible] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);
  const cart = useSelector(allCartSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const userAddressess = useSelector(addressSelector);
  const search = useSelector((state: any) => state.search);

  const itemCount = cart
    .map((item) => item.items)
    .map((arr) => arr.map((obj) => obj.quantity))
    .flat(cart.length)
    .reduce((previousValue, currentValue) => previousValue + currentValue, 0);

  const activeAddress = userAddressess.filter((item) => {
    return item.active == true;
  });

  const toggleSearchContainer = () => navigation.navigate(routeList.SEARCH);

  const toggleLeftDrawer = () =>
    navigation.getParent('LeftDrawer').toggleDrawer();
  const toggleRightrawer = () =>
    navigation.getParent('RightDrawer').toggleDrawer();

  const onChangeText = (nextValue) => {
    setSearchValue(nextValue);
    onTextChanged(nextValue);
  };

  const onPressActiveAddress = (address) => setActiveAddress(address);

  const DatePickerContent = () => (
    <Button
      testID="dropDownButton"
      onPress={() => setDateVisible(true)}
      style={ThemeStyle.flexDirectionRowCenterCenter}
      plain
    >
      <Text category="p1" numberOfLines={1}>
        {lodashIsEmpty(whenFilter)
          ? 'ASAP'
          : dayjs(whenFilter?.value).format(constants.DATE_TIME_FILTER_DISPLAY)}
      </Text>
      <Icon
        name="chevron-down-outline"
        fill={ThemeColor.green}
        pack="eva"
        style={[{ width: iconSize, height: iconSize }]}
      />
    </Button>
  );

  const AddressContent = () => {
    return (
      <Popup
        trigger={
          <button style={styles.popupButton}>
            <View
              style={[
                ThemeStyle.flexDirectionRowCenterCenter,
                { minWidth: 100, maxWidth: 200 },
              ]}
            >
              <Text category="p1" numberOfLines={1}>
                {!lodashIsEmpty(activeAddress)
                  ? activeAddress[0].formatted_address
                  : 'Address'}
              </Text>
              <Icon
                name="chevron-down-outline"
                fill={ThemeColor.green}
                pack="eva"
                style={[
                  styles.rightIcon,
                  { width: iconSize, height: iconSize },
                ]}
              />
            </View>
          </button>
        }
        arrow={false}
        contentStyle={styles.popupAddressContainer}
        position="bottom center"
        nested
      >
        <View
          dataSet={{ media: ids.saving_address_container }}
          style={styles.saving_address_container}
        >
          {
            //@ts-ignore
            <AddressSelector
              addNewAddress={onAddNewAddress}
              onSelectedSavedAddress={onPressActiveAddress}
              onSelectedSavedAddressAsEdit={onSelectedSavedAddressAsEdit}
            />
          }
        </View>
      </Popup>
    );
  };

  const searchResultItem = ({ item }) => (
    <Button
      style={styles.storeItemSearch}
      onPress={() => navigation.navigate(routeList.STORE, { id: item.id })}
      plain
    >
      <View style={styles.searched_item_result_container}>
        <Image
          source={{ uri: item.banner }}
          style={styles.image_search_result}
          resizeMode="contain"
        />
      </View>
      <View style={styles.description_search_result}>
        <Text category="h6" numberOfLines={1} style={styles.name_search_result}>
          {item.name}
        </Text>
        <Text numberOfLines={1} style={styles.locationSearchResult}>
          {item.location}
        </Text>
      </View>
    </Button>
  );
  const searchContent = () => (
    <View>
      <Input
        placeholder="Search store, dishes, products"
        value={searchValue}
        accessoryLeft={() => (
          <Button style={styles.searchIconButtonContainer} plain>
            <Icon
              name={'search'}
              fill={ThemeColor.green}
              pack="ion"
              style={styles.searchIcon}
            />
          </Button>
        )}
        accessoryRight={() =>
          searchValue != '' ? (
            <Button
              style={styles.closeIconButtonContainer}
              onPress={() => setSearchValue('')}
              plain
            >
              <Icon
                name={'close'}
                fill={ThemeColor.red}
                pack="ion"
                style={styles.closeSearchIcon}
              />
            </Button>
          ) : null
        }
        textStyle={{ fontSize: 14 }}
        style={styles.searchInput}
        onFocus={toggleSearchContainer}
        onBlur={() =>
          setTimeout(() => {
            setSearchVisible(false);
          }, 200)
        }
        onChangeText={onChangeText}
      />
      {searchVisible && (
        <View style={styles.popover_content_wrapper}>
          <FlatList
            data={search.resultSearched}
            contentContainerStyle={styles.flatlist_search_container}
            renderItem={searchResultItem}
            ItemSeparatorComponent={() => (
              <View style={styles.result_search_separator} />
            )}
          />
        </View>
      )}
    </View>
  );
  const authenticationContent = () => (
    <View style={styles.authenticationContainer}>
      <Button
        testID="loginButton"
        onPress={onLogin}
        style={styles.loginButton}
        plain
      >
        <Text status="basic" category="p2">
          Log in
        </Text>
      </Button>

      <Button
        testID="signupButton"
        onPress={onSignup}
        style={styles.signupButton}
        plain
      >
        <Text status="control" category="p2">
          Sign Up
        </Text>
      </Button>
    </View>
  );
  const goBackToStore = () => {
    navigation.navigate(routeList.HOME, {
      screen: 'MainMenu',
      params: {
        screen: routeList.STORE,
        params: { id: store_id },
      },
    });
  };
  const goBack = () => navigation.goBack();

  const onSelectedSavedAddressAsEdit = (index, item) => {
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'edit',
      selectedAddress: {
        ...item,
        index: item.id,
        latitude: item?.geo_json_point.coordinates[1],
        longitude: item?.geo_json_point.coordinates[0],
      },
    });
  };

  const onLogin = () => {
    showModalPrompt(MODALPROMPT.authentication, {
      isLogin: true,
      navigation: navigation,
    });
  };

  const onSignup = () => {
    showModalPrompt(MODALPROMPT.authentication, {
      isLogin: false,
      navigation: navigation,
    });
  };

  const onAddNewAddress = () =>
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'new',
      selectedAddress: {},
    });
  if (
    route.name === routeList.CHECKOUT ||
    route.name === routeList.CHECKOUT_MEAL_PLAN
  ) {
    return (
      <View style={[styles.wrapper, { backgroundColor: '#fff' }]}>
        <View style={styles.plainLeftColumnHeaderLarge}>
          {hasBackButton && (
            <Button
              onPress={onPressBack ? onPressBack : goBackToStore}
              style={styles.plainBackButtonContainer}
              plain
            >
              <Icon
                name={'ios-chevron-back'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.iconSize}
              />
              <Text>{backButtonText}</Text>
            </Button>
          )}
        </View>
        <View style={styles.plainCenterColumnHeaderLarge}>
          <PickupWordLogo width={130} height={50} />
        </View>
        <View style={styles.rightColumnHeaderNotPlain}>
          {!xl && (
            <Button
              testID="receiptButton"
              onPress={toggleRightrawer}
              style={styles.cartButton}
            >
              <Icon
                name={'receipt'}
                fill={ThemeColor.white}
                pack="ion"
                style={styles.iconSize}
              />
            </Button>
          )}
        </View>
      </View>
    );
  }
  if (plain) {
    return (
      <View style={[styles.wrapper, { backgroundColor: '#fff' }]}>
        <View style={styles.plainLeftColumnHeaderLarge}>
          {hasBackButton && (
            <Button
              onPress={onPressBack ? onPressBack : goBackToStore}
              style={styles.plainBackButtonContainer}
              plain
            >
              <Icon
                name={'ios-chevron-back'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.iconSize}
              />
              <Text>{backButtonText}</Text>
            </Button>
          )}
        </View>
        <View style={styles.plainCenterColumnHeaderLarge}>
          <PickupWordLogo width={130} height={50} />
        </View>
        <View style={styles.rightColumnHeaderNotPlain} />
      </View>
    );
  }

  return (
    <Fragment>
      <SafeAreaView style={styles.wrapper}>
        <View
          dataSet={{ media: ids.leftColumnHeader }}
          style={styles.leftColumnHeader}
        >
          {route.name !== routeList.STORE ? (
            <Button testID="menu_btn" onPress={toggleLeftDrawer} plain>
              <Icon
                name={'menu-outline'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.iconSize}
              />
            </Button>
          ) : (
            <Button
              onPress={goBack}
              style={styles.plainBackButtonContainer}
              plain
            >
              <Icon
                name={'ios-chevron-back'}
                fill={ThemeColor.green}
                pack="ion"
                style={styles.iconSize}
              />
            </Button>
          )}

          <View
            dataSet={{ media: ids.logoContainer }}
            style={[styles.logoContainer, ThemeStyle.spacingLeft]}
          >
            <TouchableOpacity
              onPress={() => navigation.navigate(routeList.HOME_TAB)}
            >
              <PickupWordLogo width={130} height={50} />
            </TouchableOpacity>
          </View>

          <View
            dataSet={{ media: ids.dateContainer }}
            style={styles.dateContainer}
          >
            <DatePickerContent />
            {route.name !== routeList.RESERVATION_TAB && (
              <Fragment>
                <View>
                  <Text category="p1">to</Text>
                </View>
                <AddressContent />
              </Fragment>
            )}
          </View>
        </View>

        <View
          dataSet={{ media: ids.rightColumnHeader }}
          style={styles.rightColumnHeader}
        >
          <View
            dataSet={{ media: ids.searchContainer }}
            style={styles.searchContainer}
          >
            {isVisibleSearchStore &&
              route.name !== routeList.STORE &&
              searchContent()}
          </View>
          {!isLoggedIn && authenticationContent()}
          <View
            dataSet={{ media: ids.cartContainer }}
            style={styles.cartContainer}
          >
            <Button
              testID="cartButton"
              onPress={toggleRightrawer}
              style={styles.cartButton}
              plain
            >
              <Icon
                name={'basket-outline'}
                fill={ThemeColor.white}
                pack="ion"
                style={styles.iconSize}
              />

              {itemCount > 0 && (
                <View style={styles.cartTxtContainer}>
                  <Text style={styles.itemCountTxt}>{itemCount}</Text>
                </View>
              )}
            </Button>
          </View>
        </View>
      </SafeAreaView>
      <LoadingIndicator isOpen={isLoading} />
      {
        //@ts-ignore
        <DatePicker
          isOpen={dateVisible}
          onClosed={() => setDateVisible(false)}
          onProceed={(date) => {
            onConfirm(date);
            setDateVisible(false);
          }}
        />
      }
    </Fragment>
  );
};
const { ids, styles } = StyleSheet.create({
  wrapper: {
    borderBottomColor: ThemeColor.gray,
    borderBottomWidth: 1,
    height: 65,
    display: 'flex',
    paddingLeft: '5%',
    paddingRight: '5%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

  leftColumnHeader: {
    height: 65,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [`@media ${BreakPoints.xs}`]: {
      marginRight: null,
    },
    [`@media ${BreakPoints.sm}`]: {
      marginRight: 10,
    },
    [`@media ${BreakPoints.md}`]: {
      marginRight: 10,
    },
    [`@media ${BreakPoints.lg}`]: {
      marginRight: 10,
    },
    [`@media ${BreakPoints.xl}`]: {
      marginRight: 10,
    },
    [`@media ${BreakPoints.xxl}`]: {
      marginRight: 10,
    },
  },
  rightColumnHeader: {
    ...ThemeStyle.flex1,
    height: 65,
    columnGap: 10,
    flexDirection: 'row',
    alignItems: 'center',
    [`@media ${BreakPoints.xs}`]: {
      justifyContent: 'flex-end',
    },
    [`@media ${BreakPoints.sm}`]: {
      justifyContent: 'flex-end',
    },
    [`@media ${BreakPoints.md}`]: {
      justifyContent: 'flex-end',
    },
    [`@media ${BreakPoints.lg}`]: {
      justifyContent: 'flex-start',
    },
    [`@media ${BreakPoints.xl}`]: {
      justifyContent: 'flex-start',
    },
    [`@media ${BreakPoints.xxl}`]: {
      justifyContent: 'flex-start',
    },
  },

  rightColumnHeaderNotPlain: {
    ...ThemeStyle.flex1,
    height: 65,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  searchContainer: {
    ...ThemeStyle.flex1,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  searchInput: {
    ...ThemeStyle.regular,
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    minWidth: 200,
  },
  logoContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  logoSize: {
    [`@media ${BreakPoints.xs}`]: {
      width: 50,
      height: 40,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 150,
      height: 40,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 150,
      height: 40,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 150,
      height: 40,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 150,
      height: 40,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 150,
      height: 40,
    },
  },
  searchIcon: { width: 15, height: 15 },
  iconSize: { width: 25, height: 25 },
  closeSearchIcon: { width: 15, height: 15 },
  searchIconButtonContainer: {
    width: 20,
    height: 20,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeIconButtonContainer: {
    width: 20,
    height: 20,
    borderRadius: 30,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ThemeColor.darkGray,
  },
  authenticationContainer: {
    flexDirection: 'row',
    columnGap: 10,
  },
  loginButton: {
    width: 100,
    height: 40,
    backgroundColor: '#E6E6E6',
    borderWidth: 1,
    borderColor: '#2B2B2B',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  signupButton: {
    width: 100,
    height: 40,
    backgroundColor: ThemeColor.black,
    borderWidth: 1,
    borderColor: '#2B2B2B',
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
  },
  cartContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  cartButton: {
    width: 75,
    height: 40,
    borderRadius: 30,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: ThemeColor.green,
  },
  cartTxtContainer: { width: 25, alignItems: 'center' },

  popover_content_wrapper: {
    ...ThemeStyle.flex1,
    backgroundColor: ThemeColor.white,

    padding: '2%',
    borderBottomColor: ThemeColor.lightGray,
    borderBottomWidth: 1,
    width: '100%',
    top: 50,
    position: 'absolute',
  },
  storeItemSearch: {
    ...ThemeStyle.flex1,
    height: 60,
    padding: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  dateContainer: {
    paddingLeft: 50,
    height: 30,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    columnGap: 10,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },
  popupButton: {
    backgroundColor: ThemeColor.white,
    borderWidth: 0,
    cursor: 'pointer',
  },
  popupAddressContainer: {
    width: 400,
    borderWidth: 0,
    backgroundColor: 'transparent',
  },

  saving_address_container: {
    backgroundColor: ThemeColor.white,
    top: 30,
    paddingTop: '2%',
    paddingBottom: '2%',
    borderWidth: 1,
    borderColor: ThemeColor.lightGray,
    borderRadius: 5,
    [`@media ${BreakPoints.xs}`]: {
      width: 250,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 300,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 350,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 500,
    },
  },

  result_search_separator: { height: 10 },
  image_search_result: {
    width: 40,
    height: 40,
    borderRadius: 40,
  },
  flatlist_search_container: { maxHeight: 600 },
  searched_item_result_container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  description_search_result: { marginLeft: 10, width: '80%' },
  name_search_result: {
    ...ThemeStyle.medium,
    fontSize: 14,
  },
  locationSearchResult: {
    ...ThemeStyle.regular,
    fontSize: 12,
  },

  plainLeftColumnHeaderLarge: {
    height: 65,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  plainCenterColumnHeaderLarge: {
    ...ThemeStyle.flex1,
    height: 65,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignSelf: 'center',
    alignItems: 'center',
  },
  plainBackButtonContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  itemCountTxt: { color: ThemeColor.white, fontSize: 18 },
  headerWrapperLeft: {
    paddingVertical: globalSpacing / 2,
  },
  headerWrapperRight: {
    paddingVertical: globalSpacing / 2,
  },
  icon: {
    width: iconSize,
    height: iconSize,
  },
  leftIcon: {
    marginRight: globalSpacing / 4,
  },
  rightIcon: {
    marginLeft: globalSpacing / 4,
  },
});
export default withDatePicker(React.memo(Header));
