import React from 'react';
import { connect } from 'react-redux';
import { Alert, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import creditcardutils from 'creditcardutils';
import lodashPick from 'lodash/pick';
import Tooltip from 'rn-tooltip';

import validation from '../../Config/validationRules';
import Form from '../../Components/FormFields/Form';
import FormField from '../../Components/FormFields/FormField';
import ScreenWrapper from '../../Components/ScreenWrapperWithTitleAnimation';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import { addPaymentMethod } from '../../RTK/utility';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

import styles from './styles';

const validationSchema = validation.createSchema({
  cardNumber: validation.cardNumberSchema,
  month: validation.monthSchema,
  year: validation.yearSchema,
  cvv: validation.cvvSchema,
  zip: validation.zipSchema,
});

const formInitialValues = {
  cardNumber: '',
  month: '',
  year: '',
  cvv: '',
  zip: '',
};

class AddPayment extends React.Component {
  _onSubmit = (data, { setSubmitting }) => {
    const { navigation, dispatchAddPaymentMethod } = this.props;
    const cardType = creditcardutils.parseCardType(data.cardNumber);
    const isCardNumberValid = creditcardutils.validateCardNumber(
      data.cardNumber
    );
    const isExpiryValid = creditcardutils.validateCardExpiry(
      data.month,
      data.year
    );
    const isCVVValid = creditcardutils.validateCardCVC(data.cvv, cardType);

    setSubmitting(false); // remove loading state of button
    if (!isCardNumberValid) {
      Alert.alert(
        'Card number is invalid',
        'Please check your card number and try again.'
      );
    } else if (!isExpiryValid) {
      Alert.alert(
        'Card expiry is invalid',
        'Please check your expiry month, year and try again.'
      );
    } else if (!isCVVValid) {
      const showLength = data.cvv.length < 3;
      Alert.alert(
        'Card CVV is invalid',
        showLength
          ? 'CVV should be 3 digit'
          : 'Please check your CVV and try again.'
      );
    } else {
      const toBeSaved = lodashPick(data, ['cardNumber', 'zip']);
      // were not going to save the expiry and cvv yet for now
      dispatchAddPaymentMethod(toBeSaved);
      navigation.goBack();
    }
  };

  _renderFooter = () => (
    <View style={ThemeStyle.pageHorizontalSpacing}>
      <SubmitButton text="Add Card" disableIfInvalid withShadow />
    </View>
  );

  _renderCVVlabel = (labelProps) => (
    <View style={ThemeStyle.flexDirectionRowSpaceBetween}>
      <Text {...labelProps}>CVV</Text>

      <Tooltip
        actionType="press"
        withOverlay={false}
        width="75%"
        height={50}
        backgroundColor={ThemeColor.gray}
        popover={
          <Text category="c2">
            You can find the CVC/CVV on the back of your Visa or Mastercard.
          </Text>
        }
      >
        <Icon
          name="question-mark-circle-outline"
          fill={ThemeColor.black}
          style={[ThemeStyle.spacingLeftMedium, { width: 20, height: 20 }]}
        />
      </Tooltip>
    </View>
  );

  render() {
    return (
      <Form
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={this._onSubmit}
      >
        <ScreenWrapper title="Add Payment" renderFooter={this._renderFooter}>
          <View style={styles.wrapper}>
            <View style={ThemeStyle.spacingBottom}>
              <FormField
                testID="cardNumber"
                direction="column"
                type="cardnumber"
                name="cardNumber"
                label="Card Number"
                placeholder="Credit or Debit Card"
                maxLength={19}
                withBackground
                withClear
              />
            </View>

            <View
              style={[
                ThemeStyle.flexDirectionRowCenter,
                ThemeStyle.spacingBottom,
              ]}
            >
              <View style={ThemeStyle.flex1}>
                <FormField
                  testID="month"
                  direction="column"
                  type="number"
                  name="month"
                  label="Month"
                  placeholder="MM"
                  maxLength={2}
                  withBackground
                  withClear
                />
              </View>

              <View style={ThemeStyle.rowSeparator} />

              <View style={ThemeStyle.flex1}>
                <FormField
                  testID="year"
                  direction="column"
                  type="number"
                  name="year"
                  label="Year"
                  placeholder="YY"
                  maxLength={2}
                  withBackground
                  withClear
                />
              </View>

              <View style={ThemeStyle.rowSeparator} />

              <View style={ThemeStyle.flex1}>
                <FormField
                  testID="cvv"
                  direction="column"
                  type="number"
                  name="cvv"
                  label={this._renderCVVlabel}
                  maxLength={3}
                  withBackground
                  withClear
                />
              </View>
            </View>

            <FormField
              testID="zip"
              direction="column"
              type="number"
              name="zip"
              label="Postal Code"
              maxLength={7}
              withBackground
              withClear
            />
          </View>
        </ScreenWrapper>
      </Form>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchAddPaymentMethod: (data) => dispatch(addPaymentMethod(data)),
});

export default connect(null, mapDispatchToProps)(AddPayment);
