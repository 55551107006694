import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import Tooltip from 'rn-tooltip';
import lodashFindIndex from 'lodash/findIndex';

import constants from '../Config/constants';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

const TYPE = {
  NON_EXCLUSIVE: 'non_exclusive',
  EXCLUSIVE: 'exclusive',
  COMPLETED: 'completed',
  PROCEED_TO_CHECKOUT: 'proceed_to_checkout',
};

const responsiveWidth = (width) => {
  const maxWidth = constants.DEVICE_WIDTH >= 400 ? 400 : constants.DEVICE_WIDTH;
  const deviceWidthWithSpacing = maxWidth - 20;
  const isPxMoreThanDeviceWidth = width >= deviceWidthWithSpacing;
  return isPxMoreThanDeviceWidth ? deviceWidthWithSpacing : width;
};

function Hint({ children, containerStyle, name, scrollReference, show }) {
  const tooltipRef = useRef({ current: {} }); // this will become current: { [name]: ref }
  const shopData = useSelector((state) => state.shop.data);
  const [config, setConfig] = useState({});
  const [isOpen, setOpen] = useState(false);
  const scrollRef = scrollReference?.current?.sectionList?.current;

  useEffect(() => {
    if (show) {
      if (name === TYPE.NON_EXCLUSIVE) {
        setConfig({
          text: 'Please select your meal plan item from our menu.',
          width: responsiveWidth(250),
          height: _getTextLineHeight(2),
          scroll: {
            viewOffset: constants.statusBarHeight + 607,
            sectionIndex: 0,
            itemIndex: 0,
          },
        });
      } else if (name === TYPE.EXCLUSIVE) {
        const exclusiveSectionIndex = lodashFindIndex(shopData.menu_list, {
          isExclusive: true,
        });
        setConfig({
          text: 'Please select your exclusive item under the section containing this tag.',
          width: responsiveWidth(300),
          height: _getTextLineHeight(2),
          scroll: {
            sectionIndex: exclusiveSectionIndex,
            itemIndex: 0,
          },
        });
      } else if (name === TYPE.COMPLETED) {
        setConfig({
          text: 'Your meal plan is complete. Click the button to review your order.',
          width: responsiveWidth(375),
          height: _getTextLineHeight(2),
        });
      } else if (name === TYPE.PROCEED_TO_CHECKOUT) {
        setConfig({
          text: 'Please verify your meal plan and click this to proceed to checkout.',
          width: responsiveWidth(375),
          height: _getTextLineHeight(2),
        });
      }
    }
  }, [show]);

  useEffect(() => {
    if (config.text) {
      // if has tooltip / hint on mealPlanStep
      // we will show tips only on the first occurence if hint is inside the loop
      const reference = tooltipRef?.current?.[name];
      if (reference) {
        // wait a little for transition
        setTimeout(() => {
          // if has scroll config
          if (config.scroll) {
            // then scroll to the hint
            scrollRef?.scrollToLocation?.(config.scroll);
          }
          // after scrolling (if has scroll config), wait a little for scroll animation to end
          setTimeout(() => {
            // then show tooltip
            reference.toggleTooltip();
          }, 500);
        }, 100);
      }
    }
  }, [config]);

  const _getTextLineHeight = (textLine) => {
    const spacing = 12 * 2; // up and down
    const singleLineText = 17.5; // text height
    const oneLineHeight = singleLineText + spacing; // one line text height
    return oneLineHeight + (textLine - 1) * singleLineText;
  };

  return (
    <Tooltip
      ref={(r) => (tooltipRef.current[name] = r)}
      actionType="none"
      backgroundColor={ThemeColor.green}
      overlayColor={`${ThemeColor.darkGray}96`}
      height={config.height}
      width={config.width}
      popover={
        <Text appearance="alternative" category="p2" style={ThemeStyle.flex1}>
          {config.text}
        </Text>
      }
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
    >
      <View style={[containerStyle, { position: 'relative' }]}>
        {/* Overlay to prevent clicking the element inside, this should be click first to close the highlight */}
        {isOpen && <View style={[StyleSheet.absoluteFill, { zIndex: 1 }]} />}

        {children}
      </View>
    </Tooltip>
  );
}

export const HINT_TYPE = TYPE;
export default Hint;
