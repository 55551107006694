import React from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, View, Linking } from 'react-native';
import { Icon, Text, Divider } from '@ui-kitten/components';
import Collapsible from 'react-native-collapsible';

import Button from '../../Components/Button';
import StoreHours from '../../Components/StoreHours';
import Service from '../../Service';
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

const leftIconSize = 20;
const rightIconSize = 15;
const LISTYPES = ['address', 'accordion', 'website', 'phone'];
const TYPES = {
  ADDRESS: LISTYPES[0],
  ACCRODION: LISTYPES[1],
  WEBSITE: LISTYPES[2],
  PHONE: LISTYPES[3],
};

class StoreInformationList extends React.PureComponent {
  state = {
    expand: false,
  };

  _onButtonPressed = (data) => () => {
    const { type } = data;
    if (type === TYPES.ADDRESS) {
      const url = Service.getStoreMapUrl(
        data.storeName,
        data.location.latitude,
        data.location.longitude
      );
      if (constants.isWeb) {
        Linking.openURL(`https://www.google.com/maps/place/${data.title}`);
        return;
      }
      Linking.openURL(url);
    } else if (type === TYPES.WEBSITE) {
      Linking.openURL(data.title);
    } else if (type === TYPES.PHONE) {
      Linking.openURL(`tel:${data.title}`);
    } else {
      this.setState({ expand: !this.state.expand });
    }
  };

  render() {
    const { data, store_theme = {} } = this.props;
    const { expand } = this.state;

    return data.map((d, i) => {
      const isNotAccordion = d.type !== TYPES.ACCRODION;
      const isLast = i !== data.length - 1;
      const accordionRightIcon = expand
        ? 'arrow-ios-upward-outline'
        : 'arrow-ios-downward-outline';

      return (
        <View key={i}>
          <Button
            testID={`siList_${d.type}`}
            onPress={this._onButtonPressed(d)}
            plain
          >
            <View style={styles.wrapper}>
              <Icon
                name={d.iconName}
                fill={store_theme['icon_color'] || ThemeColor.green}
                style={styles.leftIcon}
              />

              <View
                style={[ThemeStyle.flex1, ThemeStyle.pageHorizontalSpacing]}
              >
                <Text numberOfLines={d.titleLines || 1}>{d.title}</Text>
                {!!d.subTitle && (
                  <Text
                    category="c1"
                    style={ThemeStyle.dimColor}
                    numberOfLines={1}
                  >
                    {d.subTitle}
                  </Text>
                )}
              </View>

              <Icon
                name={
                  isNotAccordion ? 'external-link-outline' : accordionRightIcon
                }
                fill={ThemeColor.black}
                style={styles.rightIcon}
              />
            </View>

            <Divider
              style={[
                ThemeStyle.divider,
                isLast ? styles.dividerLeftSpacing : {},
                constants.isWeb ? { height: 0.5 } : {},
              ]}
            />
          </Button>

          {!isNotAccordion && (
            <Collapsible collapsed={!expand}>
              <View
                testID="storeScheduleList"
                style={[
                  ThemeStyle.pageVerticalSpacingMedium,
                  styles.dividerLeftSpacing,
                ]}
                isAccordionOpen={expand} // for testing purpose
              >
                <StoreHours hours={d.accordionData} />
              </View>
            </Collapsible>
          )}
        </View>
      );
    });
  }
}

const styles = StyleSheet.create({
  wrapper: {
    ...ThemeStyle.flexDirectionRowCenter,
    ...ThemeStyle.pageVerticalSpacingMedium,
  },
  leftIcon: {
    width: leftIconSize,
    height: leftIconSize,
  },
  rightIcon: {
    width: rightIconSize,
    height: rightIconSize,
  },
  dividerLeftSpacing: {
    marginLeft:
      leftIconSize + ThemeStyle.pageHorizontalSpacing.paddingHorizontal,
  },
});

StoreInformationList.propsTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string,
      type: PropTypes.oneOf(LISTYPES).isRequired,
      accordionData: PropTypes.any,
    }).isRequired
  ),
};

export { TYPES };

export default StoreInformationList;
