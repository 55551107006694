import { Layout, Text } from '@ui-kitten/components';
import React, { useEffect, useState } from 'react';
import { Linking, ScrollView, StyleProp, View } from 'react-native';
import Collapsible from 'react-native-collapsible';
import StyleSheet from 'react-native-media-query';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFindIndex from 'lodash/findIndex';

import validation from '../../../../Config/validationRules';
import externalLinks from '../../../../Config/externalLinks';

//THEMES
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';
import Button from '../../../Button';

import useModalPrompt from './hooks/useModalPrompt';
import useAuth from '../../../../Hooks/useAuth';
import useFormField from '../../../../Hooks/useFormField';

import OtpForm from '../../../../Screens/Auth/Otp';
import OTPTimer from '../../../../Screens/Auth/OTPTimer';
import SigninForm from '../../../../Screens/Auth/SigninForm';

import FormField from '../../../FormFields/FormField';
import ModalPrompt from './ModalPrompt';
import SubmitButton from '../../../FormFields/SubmitButton';
import Form from '../../../FormFields/Form';

import routeList from '../../../../Routes/list';
import SwitchTab from '../../../SwitchTab';

const validationSchema = validation.createSchema({
  first_name: validation.firstNameSchema,
  last_name: validation.lastNameSchema,
  email: validation.emailSchema,
  phone_number: validation.phoneSchema,
});

const formInitialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
};

const switchTabData = [
  { value: 'login', label: 'Log In' },
  { value: 'signup', label: 'Sign Up' },
];

const Authentication = ({ modalId }) => {
  const { hideModalPrompt } = useModalPrompt();

  const { getInputProps, setSubmitButtonRef } = useFormField();

  const [data, setData] = useState<{
    isLogin?: boolean;
    navigation: any;
  }>();

  const {
    isOTPResending,
    isOTPSubmitting,
    isSigninForm,
    payload,
    timer,
    onBack,
    onCountdownEnd,
    onSigninSubmit,
    onOTPSubmitSignUp,
    onOTPSubmit,
    onOTPResend,
  } = useAuth();

  const [activeTabindex, setActiveTabIndex] = useState<number>(0);

  useEffect(() => {
    if (!lodashIsEmpty(data)) setActiveTabIndex(data?.isLogin ? 0 : 1);
  }, [data]);

  const _goToTermsAndCondition = () => {
    const { navigation } = data || {};
    navigation.navigate(routeList.LEGAL_BINDING_CONTRACT, {
      key: 'termsAndConditions',
      title: 'Terms And Conditions',
    });
    hideModalPrompt(modalId, {});
  };

  const _goToPrivacyPolicy = () => {
    Linking.openURL(externalLinks.PRIVACY_POLICY);
  };
  const showTimer = timer > 0;

  const toggleOption = (val) => {
    const index = lodashFindIndex(switchTabData, val);
    setActiveTabIndex(index);
  };

  return (
    <ModalPrompt
      id={modalId}
      title={
        activeTabindex === 0 ? 'Login your credentials' : 'Create your account'
      }
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      hasHeader
      hasHeaderLine
      closeOnDocumentClick={false}
      onBeforeShow={setData}
    >
      <Layout style={styles.contentContainer}>
        {isSigninForm && (
          <SwitchTab
            data={switchTabData}
            value={switchTabData[activeTabindex]}
            onChange={toggleOption}
          />
        )}

        <Collapsible collapsed={activeTabindex === 1}>
          <Layout>
            {isSigninForm ? (
              <SigninForm
                timer={timer}
                onCountdownEnd={onCountdownEnd}
                onSubmit={onSigninSubmit}
              />
            ) : (
              <OtpForm
                email={payload?.email}
                isResending={isOTPResending}
                isSubmitting={isOTPSubmitting}
                timer={timer}
                onBack={onBack}
                onCountdownEnd={onCountdownEnd}
                onSubmit={onOTPSubmit}
                onResend={onOTPResend}
              />
            )}
          </Layout>
        </Collapsible>

        <Collapsible collapsed={activeTabindex === 0}>
          {isSigninForm ? (
            <ScrollView>
              <Form
                initialValues={formInitialValues}
                onSubmit={onSigninSubmit}
                validationSchema={validationSchema}
              >
                <View style={styles.rowSignUpContent}>
                  <View>
                    <FormField
                      type="email"
                      name="email"
                      label="Email"
                      placeholder="Required"
                      direction="column"
                      withBackground
                      withClear
                      {...getInputProps(1, true)}
                    />
                  </View>

                  <View style={styles.colSignupContent}>
                    <View style={ThemeStyle.flex1}>
                      <FormField
                        direction="column"
                        name="first_name"
                        label="First Name"
                        placeholder="Required"
                        withBackground
                        withClear
                        {...getInputProps(2, true)}
                      />
                    </View>

                    <View style={ThemeStyle.flex1}>
                      <FormField
                        direction="column"
                        name="last_name"
                        label="Last Name"
                        placeholder="Required"
                        withBackground
                        withClear
                        {...getInputProps(3, true)}
                      />
                    </View>
                  </View>

                  <View>
                    <FormField
                      type="phone"
                      name="phone_number"
                      label="Phone"
                      placeholder="Required"
                      direction="column"
                      withBackground
                      withClear
                      {...getInputProps(4, true)}
                    />
                  </View>

                  <OTPTimer
                    timer={timer}
                    text="You can sign up again after"
                    onCountdownEnd={onCountdownEnd}
                  />

                  <SubmitButton
                    onRef={setSubmitButtonRef}
                    disabled={showTimer}
                    style={{ marginTop: -20, borderRadius: 0 }}
                    text="Next"
                  />
                </View>
              </Form>

              <View style={styles.legalBindingRow}>
                <Text category="p2">
                  {/* By clicking Sign up, Continue with Facebook or Continue with Google, you agree to our */}
                  By tapping “Sign up”, you agree to our{' '}
                </Text>

                <Button
                  testID="tncButton"
                  onPress={_goToTermsAndCondition}
                  plain
                >
                  <Text category="p2" status="success" style={styles.underline}>
                    Terms and Conditions
                  </Text>
                </Button>

                <Text category="p2"> and </Text>

                <Button testID="ppButton" onPress={_goToPrivacyPolicy} plain>
                  <Text category="p2" status="success" style={styles.underline}>
                    Privacy Policy
                  </Text>
                </Button>

                <Text category="p2">.</Text>
              </View>
            </ScrollView>
          ) : (
            <OtpForm
              isResending={isOTPResending}
              isSubmitting={isOTPSubmitting}
              email={payload?.email || ''}
              timer={timer}
              onBack={onBack}
              onCountdownEnd={onCountdownEnd}
              onSubmit={onOTPSubmitSignUp}
              onResend={onOTPResend}
            />
          )}
        </Collapsible>
      </Layout>
    </ModalPrompt>
  );
};

const { ids, styles }: { ids: any; styles: any } = StyleSheet.create({
  mainWrapper: {
    borderRadius: 5,
    [`@media ${BreakPoints.xs}`]: {
      width: 370,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 420,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 440,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 460,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 480,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 500,
    },
  },

  contentContainer: {
    //@ts-ignore:row gap definition is not available react-native-media-query
    rowGap: 10,
    justifyContent: 'center',
  },
  containerSelector: {
    ...ThemeStyle.flex1,
    ...ThemeStyle.flexDirectionRowCenterSpaceBetween,
  },
  layoutBtnSelector: {
    width: '50%',
  },

  enableBtn: {
    backgroundColor: ThemeColor.green,
    flex: 1,
    marginTop: 10,
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
  disableBtn: {
    backgroundColor: ThemeColor.gray,
    flex: 1,
    marginTop: 10,
    opacity: 0.5,
    elevation: 0,
    borderWidth: 1,
    borderColor: ThemeColor.gray,
  },

  innerBtn: {
    height: 50,
    minWidth: 150,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },

  underline: {
    textDecorationLine: 'underline',
  },
  //@ts-ignore:row gap definition is not available react-native-media-query
  rowSignUpContent: { rowGap: 20 },
  //@ts-ignore:row gap definition is not available react-native-media-query
  colSignupContent: { ...ThemeStyle.flexDirectionRow, columnGap: 20 },
  legalBindingRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    ...ThemeStyle.spacingVerticalMedium,
  },
});

export default Authentication;
