import { createSelector } from '@reduxjs/toolkit';
import lodashFind from 'lodash/find';
import lodashFilter from 'lodash/filter';

import constants from '../../Config/constants';

const cart = (state) => state.cart;

const getStoreCartData = (cartData, storeId, shouldReturnArray) => {
  if (shouldReturnArray) {
    return lodashFilter(cartData, { store_id: storeId });
  } else {
    return lodashFind(cartData, { store_id: storeId }) || {};
  }
};

const allCartSelector = createSelector(cart, (state) =>
  state?.cartData.filter?.(
    (cd) => cd.status !== constants.ORDER_STATUS.CONVERTED
  )
);

const pendingCartSelector = createSelector(allCartSelector, (carts) =>
  lodashFilter(carts, (c) => c.status === constants.ORDER_STATUS.PENDING)
);

const storeCartSelector = createSelector(
  [
    allCartSelector, // base selector
    (_, storeId) => storeId, // for 2nd param
    (_, __, shouldReturnArray) => shouldReturnArray, // for 3rd param
  ],
  getStoreCartData
);

export { allCartSelector, pendingCartSelector, storeCartSelector };
