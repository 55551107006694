export default [
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/all_icon-active.png',
    id: '0',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/all_icon-inactive.png',
    name: 'All',
    all: true,
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-27.png',
    id: '1',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-26.png',
    name: 'Barbecue',
    value: 'barbecue',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-13.png',
    id: '2',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-12.png',
    name: 'Breakfast',
    value: 'breakfast',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-09.png',
    id: '3',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-08.png',
    name: 'Burgers',
    value: 'burger',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-17.png',
    id: '4',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-16.png',
    name: 'Chicken',
    value: 'chicken',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-31.png',
    id: '5',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-30.png',
    name: 'Coffee',
    value: 'coffee',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-49.png',
    id: '6',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-48.png',
    name: 'Dessert',
    value: 'dessert',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-23.png',
    id: '7',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-22.png',
    name: 'Fast Food',
    value: 'fast food',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-41.png',
    id: '8',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-40.png',
    name: 'Healthy',
    value: 'healthy',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-15.png',
    id: '9',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-14.png',
    name: 'Indian',
    value: 'indian',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-11.png',
    id: '10',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-10.png',
    name: 'Japanese',
    value: 'japanese',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-29.png',
    id: '11',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-28.png',
    name: 'Mexican',
    value: 'mexican',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-05.png',
    id: '12',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-04.png',
    name: 'Middle Eastern',
    value: 'middle eastern',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-35.png',
    id: '13',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-34.png',
    name: 'Pasta',
    value: 'pasta',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-19.png',
    id: '14',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-18.png',
    name: 'Pizza',
    value: 'pizza',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-07.png',
    id: '15',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-06.png',
    name: 'Salad',
    value: 'salad',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-25.png',
    id: '16',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-24.png',
    name: 'Sandwich',
    value: 'sandwich',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-43.png',
    id: '17',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-42.png',
    name: 'Smoothie',
    value: 'smoothie',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-39.png',
    id: '18',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-38.png',
    name: 'Stirfry',
    value: 'stirfry',
  },
  {
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-33.png',
    id: '19',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-32.png',
    name: 'Vegan',
    value: 'vegan',
  },
];
