import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { StyleSheet, ImageBackground, View, StatusBar } from 'react-native';
import { useIsFocused } from '@react-navigation/native';

import constants from '../Config/constants';
import {
  ids,
  styles as responsiveStyles,
} from '../Screens/Store/stylesResponsive';

import ImageHelper from '../Helper/Image';

import ThemeColor from '../Theme/colors';
import ThemeStyle, {
  globalSpacing,
  deviceWidth,
  deviceHeight,
} from '../Theme/styles';

import Image from './Image';

const avatarSize = 80;

function ScreenBanner({ avatar, banner }) {
  const isFocused = useIsFocused();
  const isDefaultPickupImage = ImageHelper.isPickupDefaultBanner(banner);
  return (
    <View
      dataSet={{ media: ids.wrapper }}
      style={[
        responsiveStyles.wrapper,
        constants.isWeb && ThemeStyle.paddingLeft,
      ]}
    >
      {Boolean(avatar) && (
        <StatusBar
          backgroundColor={isFocused ? 'transparent' : undefined}
          barStyle="dark-content"
          translucent={isFocused}
        />
      )}
      <ImageBackground
        source={{ uri: banner }}
        style={[
          Boolean(avatar) || constants.isWeb
            ? styles.backgroundImage
            : styles.backgroundImageProduct,
          isDefaultPickupImage && styles.noBannerStyle, // This is will be enabled if no banner image is available for the store
          constants.isWeb && { borderRadius: 20 },
        ]}
        resizeMode={isDefaultPickupImage ? 'contain' : 'cover'}
        imageStyle={[
          isDefaultPickupImage && styles.noBannerImageStyle, // This is will be enabled if no banner image is available for the store
        ]}
      >
        {Boolean(avatar) && (
          <Fragment>
            {!constants.isWeb && <View style={styles.slantCover} />}
            <View
              style={[
                styles.imageContainer,
                ThemeStyle.shadow,
                constants.isWeb && {
                  position: 'aboluste',
                  bottom: -(avatarSize / 2.25),
                },
              ]}
            >
              <Image
                source={{ uri: avatar }}
                style={styles.image}
                resizeMode="cover"
              />
            </View>
          </Fragment>
        )}
      </ImageBackground>
    </View>
  );
}

const styles = StyleSheet.create({
  backgroundImage: {
    width: '100%',
    height: 220,
    flexDirection: 'column-reverse',
  },
  backgroundImageProduct: {
    width: '100%',
    height: deviceHeight * 0.35,
    flexDirection: 'column-reverse',
  },
  slantCover: {
    position: 'absolute',
    bottom: 0,
    borderTopColor: 'transparent',
    borderRightColor: 'transparent',
    borderLeftColor: ThemeColor.white,
    borderBottomColor: ThemeColor.white,
    borderWidth: 30,
    borderLeftWidth: deviceWidth / 2,
    borderRightWidth: deviceWidth / 2,
  },
  imageContainer: {
    zIndex: 1,
    width: avatarSize,
    height: avatarSize,
    marginLeft: globalSpacing,
    borderRadius: avatarSize / 2,
    backgroundColor: ThemeColor.white,
  },
  image: {
    width: avatarSize,
    height: avatarSize,
    borderRadius: avatarSize / 2,
  },
  noBannerStyle: {
    backgroundColor: ThemeColor.green,
  },
  noBannerImageStyle: {
    height: constants.isWeb ? 150 : null,
    alignSelf: 'center',
  },
});

ScreenBanner.propsTypes = {
  banner: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

export default ScreenBanner;
