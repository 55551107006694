import React from 'react';
import { View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';

import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import constants from '../../Config/constants';

function ErrorPromptBelowInput({ text }) {
  if (!text) {
    return null;
  }

  return (
    <View
      style={[ThemeStyle.flexDirectionRowCenter, ThemeStyle.spacingTopMedium]}
    >
      <View style={ThemeStyle.spacingRightSmall}>
        <Icon
          name="alert-circle"
          fill={ThemeColor.red}
          style={{ width: 15, height: 15 }}
        />
      </View>

      <Text category="c1" style={[ThemeStyle.flex1, ThemeStyle.noticeText]}>
        {text}
      </Text>
    </View>
  );
}

export default ErrorPromptBelowInput;
