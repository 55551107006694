import React from 'react';
import PropTypes from 'prop-types';
import { Text, useTheme } from '@ui-kitten/components';
import lodashFilter from 'lodash/filter';
import lodashFindIndex from 'lodash/findIndex';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import constants from '../Config/constants';
import withController from '../Hooks/withController';
import ThemeColor from '../Theme/colors';
import ThemeStyle from '../Theme/styles';

import Button from './Button';
import SwitchTab from './SwitchTab';

const { ORDER_TYPES, ORDER_TYPE_DATA } = constants;

class SwitchTabOrderType extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { orderTypes: [] };
    this.buttonColor = props.theme['button_color'];
    this.buttonTextColor = props.theme['button_text_color'];
  }

  componentDidMount() {
    this._setOrderTypes();
  }

  componentDidUpdate(prevProps) {
    const prevTypes = prevProps.types;
    const currenttypes = this.props.types;
    if (!lodashIsEqual(prevTypes, currenttypes)) {
      this._setOrderTypes();
    }
  }

  _setOrderTypes = () => {
    const { showMealPlan, types, value, onChange } = this.props;
    // prettier-ignore
    let availableOrderTypes = ORDER_TYPE_DATA
    if (!lodashIsEmpty(types)) {
      // if has types filter the ORDER_TYPE_DATA
      if (showMealPlan) {
        availableOrderTypes = lodashFilter(
          ORDER_TYPE_DATA,
          (d) => types[d.apiDataKey]
        );
      } else {
        // remove meal plan on available order type
        availableOrderTypes = lodashFilter(
          ORDER_TYPE_DATA,
          (d) => types[d.apiDataKey] && d.value !== ORDER_TYPES.MEAL_PLAN
        );
      }
    }
    // update state
    this.setState({ orderTypes: availableOrderTypes }, () => {
      const updatedOrderTypes = this.state.orderTypes;
      if (value) {
        // if has value and value is not found on available order types
        // this.state.orderTypes do not remove the this.state, because it will get the previous value instead of the updated one
        const activeIndex = lodashFindIndex(updatedOrderTypes, {
          value: value.value,
        });
        // call on change and pass the first available order types
        if (activeIndex === -1) {
          onChange(updatedOrderTypes[0]);
        } else {
          // do nothing
        }
      } else {
        // if no value is pass yet, call onchange and pass the first of available order types
        onChange(updatedOrderTypes[0]);
      }
    });
  };

  _getAvailableOrderTypes = () => this.state.orderTypes;

  // web specific UI
  _renderItem = (data, index, { isButtonActive, isTextActive }) => {
    const applyButtonColor = isButtonActive && this.buttonTextColor;
    return (
      <Button
        key={index}
        style={({ hovered }) => [
          ThemeStyle.flex1,
          ThemeStyle.flexDirectionRowCenterCenter,
          { paddingVertical: 15 },
          hovered &&
            !isButtonActive && {
              borderRadius: 5,
              backgroundColor: this.buttonColor
                ? `${this.buttonColor}1A`
                : ThemeColor.green100,
            },
        ]}
        onPress={() => !isButtonActive && this.props.onChange(data)}
        isHoverable
      >
        <Text
          category="c1"
          status={isTextActive ? 'control' : 'primary'}
          style={applyButtonColor && { color: this.buttonTextColor }}
        >
          {data.label}
        </Text>
      </Button>
    );
  };

  render() {
    const { value, onChange, ...rest } = this.props;
    const { orderTypes } = this.state;
    const isValueInTypes = rest?.types[value?.apiDataKey];
    const conditionalStyle = constants.isWeb
      ? {
          activeColor: this.buttonColor,
          containerStyle: {
            ...ThemeStyle.flexDirectionRow,
            borderRadius: 5,
            borderWidth: 0,
            zIndex: 1,
          },
          overlayStyle: { borderRadius: 5 },
          renderItem: this._renderItem,
        }
      : {};
    return (
      <SwitchTab
        data={orderTypes}
        value={isValueInTypes ? value : undefined}
        onChange={onChange}
        {...conditionalStyle}
        {...rest}
      />
    );
  }
}

SwitchTabOrderType.propTypes = {
  showMealPlan: PropTypes.bool,
  types: PropTypes.object,
  value: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

SwitchTabOrderType = withController(SwitchTabOrderType, useTheme, 'theme');

export default SwitchTabOrderType;
