import constants from './constants';
const patchRequired = constants.patchRequired;

const APP_UPDATE = {
  title: 'Update available',
  message: 'Please update your app version to get the latest patch',
};
const APP_UPDATE_REQUIRED = {
  title: 'Update required',
  message: 'Please update your app version to proceed',
};
const REMOVE_ADDRESS = {
  title: 'Are you sure?',
  message:
    'You are trying to remove your saved address do you want to continue?',
};
const SESSION_EXPIRED = {
  title: 'Session Expired',
  message: 'Click ok to renew the session.',
};
const STORE_LOCATION_NOT_AVAILABLE = {
  title: 'Location is not available',
  message: 'Sorry, Location for this store is not set by its owner.',
};

const CART_LIMIT = {
  title: 'Cart Limit Exceeded',
  message: `You can only add up to ${constants.cartLimit} carts. Your oldest cart will be discarded. Do you want to continue?`,
};
const ITEM_LIMIT = 'Maximum Limit Reached';
const STORE_CLOSED = {
  title: 'Ooops! Looks like store is closed on the date you selected.',
  message: 'Please select another timeslot.',
};
const STORE_CLOSED_MOBILE = {
  title: 'Store is closed',
  message:
    'Sorry! This store is already closed and not accepting orders anymore.',
  messageExtension: 'Please select another time slot.',
};
const STORE_CLOSING = {
  title: 'Store is closing',
  message:
    'Sorry! This store is almost closing and not accepting orders anymore.',
  messageExtension: 'Please select another time slot.',
};

const UPDATE_ORDER_SCHEDULE = 'Update Order Schedule';

const APP_UPDATE_TITLE = patchRequired
  ? APP_UPDATE_REQUIRED.title
  : APP_UPDATE.title;
const APP_UPDATE_MESSAGE = patchRequired
  ? APP_UPDATE_REQUIRED.message
  : APP_UPDATE.message;
const COMMON_ERROR_MESSAGE = 'An error has occurred. Please try again';
const COMMON_ERROR_TITLE = 'Please try again';
const ERROR_ORDER_DETAILS = {
  title: 'No record found',
};
const ADDED_CART_ITEM = 'Item successfully added';
const PARTIAL_ADDED_CART_ITEM = 'Some item successfully added';
const REMOVE_CART_ITEM = 'Item successfully removed';
const UPDATE_CART_ITEM = 'Item successfully updated';
const ERROR_ADDING_CART_ITEM = 'Unable to Add the Item';

const MEAL_PLAN_EXCLUSIVE =
  'This category contains exclusive items that can be added once and is automatically added at the end of the week.';
const CHANGES_SAVED = 'Changes has been made.';
const CHANGES_NOT_SAVED = 'Changes has not been made.';

const CHANGE_SCHEDULE = 'The order schedule has been changed to';

const SCHEDULED_NOTIFICATION = {
  title: 'Rate Your Order',
  message:
    "Hi! ☺️ We'd love to hear about your recent order experience. Could you take a moment to share your thoughts? Your feedback matters! Thanks!",
};

const PAYMENT_EXPIRED = {
  title: 'Payment Expired',
  message:
    'We were unable to verify  your payment. Would you mind selecting an alternative payment method.',
};

const PAYMENT_PENDING = {
  title: 'Verifying Payment',
  message: `We're sorry, but updating ${constants.cartTerm} is not possible at the moment.`,
};

const PROMO_CODE_ADDED_SUCCESS = `Promo has been added to ${constants.cartTerm}`;

const ORDER_SUCCESS =
  'Thank you for your order! It has been successfully placed.';
const PAYMENT_METHOD = {
  required: 'Please choose a payment method.',
};

export default {
  APP_UPDATE_MESSAGE,
  APP_UPDATE_TITLE,
  COMMON_ERROR_MESSAGE,
  COMMON_ERROR_TITLE,
  ERROR_ORDER_DETAILS,
  REMOVE_ADDRESS,
  SESSION_EXPIRED,
  STORE_LOCATION_NOT_AVAILABLE,
  CART_LIMIT,
  ITEM_LIMIT,
  ADDED_CART_ITEM,
  PARTIAL_ADDED_CART_ITEM,
  REMOVE_CART_ITEM,
  UPDATE_CART_ITEM,
  ERROR_ADDING_CART_ITEM,
  MEAL_PLAN_EXCLUSIVE,
  CHANGES_SAVED,
  CHANGES_NOT_SAVED,
  CHANGE_SCHEDULE,
  STORE_CLOSED,
  STORE_CLOSED_MOBILE,
  STORE_CLOSING,
  UPDATE_ORDER_SCHEDULE,
  SCHEDULED_NOTIFICATION,
  PROMO_CODE_ADDED_SUCCESS,
  PAYMENT_EXPIRED,
  PAYMENT_PENDING,
  PAYMENT_METHOD,
  ORDER_SUCCESS,
};
