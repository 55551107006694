import React from 'react';
import PropTypes from 'prop-types';
import { ActivityIndicator } from 'react-native';
import { Text } from '@ui-kitten/components';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';
import { pluralize } from '../Helper';
import useCancellableRequest from '../Hooks/useCancellableRequest';
import withController from '../Hooks/withController';
import ThemeStyle from '../Theme/styles';
import Service from '../Service';
import storeAPI from '../Service/api/store';

class RatingText extends React.PureComponent {
  constructor(props) {
    super(props);
    this.shouldSetState = null;
    this.state = {
      loading: true,
      total: null,
      average: null,
    };
  }

  async componentDidMount() {
    const { api, storeId, onLoad } = this.props;
    this.shouldSetState = true;

    if (!storeId) {
      return;
    }

    const { ok, data } = await api.createRequest(
      storeAPI.getTotalRatings,
      storeId
    );

    let stateToUpdate = { loading: false };

    if (ok && !lodashIsEmpty(data)) {
      stateToUpdate.total = data.total;
      stateToUpdate.average = data.average.toFixed(2);
    }

    if (this.shouldSetState) {
      // update state only when component is mounted
      this.setState(stateToUpdate, () => onLoad?.(stateToUpdate));
    }
  }

  componentWillUnmount() {
    this.shouldSetState = false;
  }

  render() {
    const { trailing, storeId, type, onLoad, ...rest } = this.props;
    const { loading, total, average } = this.state;

    if (!storeId) {
      return null;
    }

    if (loading) {
      return (
        <ActivityIndicator
          size={constants.isIOS ? 16 : 18}
          style={[
            ThemeStyle.alignSelfStart,
            ThemeStyle.spacingRightMedium,
            rest.style,
          ]}
        />
      );
    }

    if (!total && !average) {
      return null;
    }

    if (type === 1) {
      return (
        <Text {...rest}>
          {average}{' '}
          <Text {...rest} status="success">
            ★
          </Text>{' '}
          ({Service.commafyNumber(total)}){trailing}
        </Text>
      );
    }

    return (
      <Text {...rest}>
        {average}{' '}
        <Text {...rest} status="success">
          ★
        </Text>{' '}
        {Service.commafyNumber(total)} {pluralize(total, 'review')}
        {trailing}
      </Text>
    );
  }
}

RatingText = withController(RatingText, useCancellableRequest, 'api');

RatingText.defaultProps = {
  type: 1,
};

RatingText.propTypes = {
  storeId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([1, 2]),
  onLoad: PropTypes.func,
};

export default RatingText;
