import { createSelector } from '@reduxjs/toolkit';
import lodashFind from 'lodash/find';

const filter = (state) => state.filter;

const distanceFilterSelector = createSelector(filter, (state) =>
  lodashFind(state.home.tags, { paramName: 'distance' })
);
const parentCategorySelector = createSelector(
  filter,
  (state) => state.parentCategory
);
const tagsFilterSelector = createSelector(filter, (state) => state.home.tags);
const whenFilterSelector = createSelector(filter, (state) => state.home.when);

export {
  distanceFilterSelector,
  parentCategorySelector,
  tagsFilterSelector,
  whenFilterSelector,
};
