import React, { Fragment, useRef } from 'react';
import ActionSheet, { useScrollHandlers } from 'react-native-actions-sheet';
import { Alert, View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { ScrollView } from 'react-native-gesture-handler';
import { Divider, Icon, Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { SwipeRow } from 'react-native-swipe-list-view';
import dayjs from 'dayjs';

import MealPlanHint, { HINT_TYPE } from '../../Components/MealPlanHint';
import MealPlanItem from '../../Components/StoreCart/MealPlanItem';
import MinimumOrderNote from '../../Components/StoreCart/MinimumOrderNote';
import StoreCartSummary from '../../Components/StoreCart/CartSummary';
import constants from '../../Config/constants';
import { pluralize } from '../../Helper';
import CartHelper from '../../Helper/Cart';
import MealPlanHelper from '../../Helper/MealPlan';
import useMealPlan from '../../Hooks/useMealPlan';
import usePromoCart from '../../Hooks/usePromoCart';
import useSetCheckoutStoreInfo from '../../Hooks/useSetCheckoutStoreInfo';
import { removeItem } from '../../RTK/mealPlan';
import { shopSelector } from '../../RTK/shop/selectors';
import { setFirstMealPlanCheckout } from '../../RTK/utility';
import routeList from '../../Routes/list';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import Button from '../Button';

import commonProps, { scrollProps } from './commonProps';
import modals from './modals';
import styles from './styles';

const hiddenItemWidth = 75;
const { cartTerm, isIOS, minuteInterval, TIME_ONLY_DISPLAY_CAPITAL_FORMAT } =
  constants;

function MealPlanListSheet({ sheetId }) {
  const dispatch = useDispatch();
  const sheetRef = useRef();
  const swipeRowRef = useRef({ current: {} });
  const navigation = useNavigation();
  const scrollHandlers = useScrollHandlers('mealPlanList', sheetRef);
  const { itemRequired, item, progress } = useMealPlan();
  const { promotion, promotionMinimum, storePromo, onPromoRemove } =
    usePromoCart({
      fetchPromo: item.list.length >= itemRequired,
    });
  const setCheckoutStoreInfo = useSetCheckoutStoreInfo();
  const shopData = useSelector(shopSelector);
  const showMealPlanHint = useSelector(
    (state) => state.utility.showMealPlanHint
  );

  const orderTimeInitial = dayjs(`1/1/1 ${shopData?.meal_plan_order_time}`);
  const mealPlanDispatchTime = `${orderTimeInitial.format(
    TIME_ONLY_DISPLAY_CAPITAL_FORMAT
  )} to ${orderTimeInitial
    .add(minuteInterval, 'minute')
    .format(TIME_ONLY_DISPLAY_CAPITAL_FORMAT)}`;

  const dispatchTime = mealPlanDispatchTime || '';
  const schedule = MealPlanHelper.generateSchedule(`${itemRequired}_day`);
  const { subTotal } = CartHelper.getBreakdown({
    isDelivery: true,
    promotion,
    cart: item.list,
  });
  const isLessThanMinimum = subTotal < promotionMinimum;

  const _onCheckout = async () => {
    await modals.hide(modals.MEAL_PLAN_LIST);
    if (showMealPlanHint) {
      // if still true, meaning first checkout
      await dispatch(setFirstMealPlanCheckout());
    }
    setCheckoutStoreInfo(shopData);
    navigation.navigate(routeList.CHECKOUT_MEAL_PLAN);
  };

  const _onRemove = (index) => () => {
    if (typeof index === 'number') {
      dispatch(removeItem(index));
    } else {
      Alert.alert(
        'Are you sure?',
        'Are you sure you want to remove all your items?',
        [
          {
            text: 'Confirm',
            onPress: async () => {
              await modals.hide(modals.MEAL_PLAN_LIST);
              dispatch(removeItem(index));
            },
          },
          { text: 'Cancel', style: 'cancel' },
        ]
      );
    }
  };

  const _renderVisibleItem = (data, index) => {
    // exclusive item use end of mealPlanSchedule date (friday)
    const dateIndex = data.is_exclusive ? schedule.length - 1 : index;
    const date = schedule[dateIndex];
    const isLast = index === item.list.length - 1;
    return (
      <View
        key={`visible.${index}`}
        style={[
          ThemeStyle.flex1,
          ThemeStyle.pageBackground,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <MealPlanItem {...data} date={date} />
        {!isLast && <Divider style={ThemeStyle.divider} />}
      </View>
    );
  };

  const _renderHiddenItem = (index) => (
    <View
      style={[ThemeStyle.flex1, ThemeStyle.alignItemsEnd]}
      key={`hidden.${index}`}
    >
      <Button
        style={[
          ThemeStyle.flex1,
          ThemeStyle.alignItemsCenter,
          ThemeStyle.justifyContentCenter,
          { width: hiddenItemWidth, backgroundColor: 'red' },
        ]}
        onPress={() => {
          swipeRowRef.current[index].closeRow();
          _onRemove(index)(); // _onRemove return a function
        }}
        plain
      >
        <Icon
          name="trash-o"
          pack="fa"
          fill={ThemeColor.white}
          style={{ width: 25, height: 25 }}
        />
      </Button>
    </View>
  );

  return (
    <ActionSheet
      id={sheetId}
      ref={sheetRef}
      useBottomSafeAreaPadding={!isIOS}
      {...commonProps}
    >
      <View style={styles.contentContainer}>
        {/* Title */}
        <Text style={ThemeStyle.spacingBottomMedium} {...SECTION_TITLE}>
          Your Meal Plan Order
        </Text>

        {/* Divider between title and content */}
        <View style={ThemeStyle.spacingBottomMedium}>
          <Divider style={ThemeStyle.divider} />
        </View>

        {/* Dispatch time */}
        <View
          style={[
            ThemeStyle.flexDirectionRowSpaceBetween,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          {/* dispatch information */}
          <View>
            <Text>{itemRequired} DAY MEAL PLAN</Text>
            <Text category="p2">Dispatch Time: {dispatchTime}</Text>
          </View>

          {/* remove all item button */}
          <View style={ThemeStyle.alignSelfEnd}>
            <Button hitSlop={10} onPress={_onRemove()} plain>
              <Text category="p2" status="danger">
                Remove
              </Text>
            </Button>
          </View>
        </View>

        {/* List */}
        <View
          style={[
            ThemeStyle.spacingBottomMedium,
            { maxHeight: 320, marginHorizontal: -globalSpacing },
          ]}
        >
          <ScrollView {...scrollProps} {...scrollHandlers}>
            {!item.list.length ? (
              <Text
                category="p2"
                style={[
                  ThemeStyle.textCenter,
                  ThemeStyle.dimColor,
                  ThemeStyle.pageHorizontalSpacing,
                ]}
              >
                No item added to your {cartTerm}
              </Text>
            ) : (
              item.list.map((data, index) => (
                <SwipeRow
                  ref={(r) => (swipeRowRef.current[index] = r)}
                  key={`row.${index}`}
                  swipeKey={`row.${index}`}
                  rightOpenValue={-hiddenItemWidth}
                  stopRightSwipe={-hiddenItemWidth}
                  previewOpenValue={-hiddenItemWidth}
                  swipeGestureBegan={() => {
                    // to close other open hidden items
                    const refKeys = Object.keys(swipeRowRef.current).filter(
                      (e) => e !== 'current' && e !== index.toString() // exclude current key and this index key
                    );
                    refKeys.forEach((e) => {
                      const ref = swipeRowRef.current[e];
                      ref?.closeRow?.();
                    });
                  }}
                  preview={index === 0}
                  disableHiddenLayoutCalculation
                  disableRightSwipe
                >
                  {_renderHiddenItem(index)}
                  {_renderVisibleItem(data, index)}
                </SwipeRow>
              ))
            )}
          </ScrollView>
        </View>

        {/* Item remaining, Summary */}
        <View style={ThemeStyle.spacingBottom}>
          {item.list.length < itemRequired ? (
            (() => {
              const itemRemaining = itemRequired - item.list.length;
              return (
                <Text
                  category="p2"
                  style={[ThemeStyle.textCenter, ThemeStyle.noticeText]}
                >
                  You need to add {itemRemaining} more{' '}
                  {pluralize(itemRemaining, 'item')} for this {itemRequired} day
                  meal plan.
                </Text>
              );
            })()
          ) : (
            <Fragment>
              <StoreCartSummary
                cart={item.list}
                isDelivery={false} // always false on cart page, since we only need to display the promo details here
                promotion={promotion}
                subTotalPromoPercent={shopData.meal_plan_discount}
                noHspacing
              />

              <MinimumOrderNote
                current={subTotal}
                minimum={promotionMinimum}
                show={isLessThanMinimum}
                voucher={storePromo}
                onRemove={onPromoRemove}
              />
            </Fragment>
          )}
        </View>

        {/* Checkout button */}
        <MealPlanHint
          name={HINT_TYPE.PROCEED_TO_CHECKOUT}
          show={progress.isCompleteStep}
        >
          <Button
            status="success"
            onPress={_onCheckout}
            disabled={item.list.length < itemRequired}
          >
            Create meal plan
          </Button>
        </MealPlanHint>
      </View>
    </ActionSheet>
  );
}

export default MealPlanListSheet;
