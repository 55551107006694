import React from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import Form from '../../Components/FormFields/Form';
import FormField from '../../Components/FormFields/FormField';
import FormFieldCheckbox from '../../Components/FormFields/FormFieldCheckbox';
import SubmitButton from '../../Components/FormFields/SubmitButton';
import constants from '../../Config/constants';
import validation from '../../Config/validationRules';
import useFormField from '../../Hooks/useFormField';
import ThemeStyle from '../../Theme/styles';

import OTPTimer from './OTPTimer';

const validationSchema = validation.createSchema({
  email: validation.emailSchema,
});

function SigninForm({ timer, onSubmit, onCountdownEnd }) {
  const { getInputProps, setSubmitButtonRef } = useFormField();
  const { rememberEmail } = useSelector((state) => state.noSession);

  const showTimer = timer > 0;
  const formInitialValues = {
    email: rememberEmail || '',
    remember: !!rememberEmail,
  };

  return (
    <Form
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {!constants.isWeb && (
        <Text
          category="h6"
          style={[ThemeStyle.spacingBottom, ThemeStyle.textCenter]}
        >
          Continue with Email
        </Text>
      )}

      <View style={ThemeStyle.spacingBottom}>
        <FormField
          testID="number"
          type="email"
          name="email"
          label="Email"
          placeholder="Enter your email"
          direction="column"
          withBackground
          {...getInputProps(1, true)}
        />
      </View>

      <View style={showTimer && ThemeStyle.spacingBottom}>
        <FormFieldCheckbox name="remember" label="Remember me" />
      </View>

      <OTPTimer
        timer={timer}
        text="You can sign in again after"
        onCountdownEnd={onCountdownEnd}
      />

      <SubmitButton
        onRef={setSubmitButtonRef}
        disabled={showTimer}
        style={
          constants.isWeb
            ? { ...ThemeStyle.spacingTop, borderRadius: 0 }
            : ThemeStyle.spacingTop
        }
        text="Next"
      />
    </Form>
  );
}

SigninForm.propTypes = {
  onSuccess: PropTypes.func,
};

export default SigninForm;
