const convertNumberToDay = (dayInNumber) => {
  return (
    ['Sun', 'Mon', 'Tues', 'Wednes', 'Thurs', 'Fri', 'Satur'][dayInNumber] +
    'day'
  );
};

export default {
  convertNumberToDay,
};
