import React, { Fragment } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { useSelector } from 'react-redux';
import { createDrawerNavigator } from '@react-navigation/drawer';

//HELPER
import ScreenSize from '../../Helper/ScreenSize';

//ROUTES
import routeList from '../list';

//HOOKS
import withDatePicker from '../../Hooks/withDatePicker';
import useDetailsController from '../../Screens/Store/controller/details';
import useMealPlan from '../../Hooks/useMealPlan';

//COMPONENTS
import SwitchTabOrderType from '../../Components/SwitchTabOrderType';
import CartList from '../../Components/Web/Cart/CartList';
import RightDrawerContent from '../../Components/RightDrawerContent';
//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

//STYLES
//@ts-ignore: JS code error
import { styles as homeStyles } from '../../Screens/Home/styles';

//SCREENS
import MealPlanList from '../../Screens/Store/MealPlan/List.web';
import { shopSelector } from '../../RTK/shop/selectors';
import { userCartFetchingSelector } from '../../RTK/utility/selectors';
import { allCartSelector, storeCartSelector } from '../../RTK/cart/selectors';
import LeftDrawerScreen from './LeftDrawer';

const RightDrawer = createDrawerNavigator();

const RightDrawerScreen = (rightDrawerProps) => {
  const isUserCartLoading = useSelector(userCartFetchingSelector);
  const shopData = useSelector(shopSelector);
  const cartAll = useSelector(allCartSelector);
  const cartStore = useSelector((state) =>
    //@ts-ignore: JS code error
    storeCartSelector(state, shopData?.id, true)
  );

  const { xl } = ScreenSize();
  const { isMealPlan } = useMealPlan();

  const { availableStoreOrderTypes, selectedOrderType, updateShopOrderType } =
    useDetailsController(!xl);

  const isStorePage = routeList.STORE === window.location.pathname.slice(1);

  const cartData = isStorePage && !xl ? cartStore : cartAll;

  const screenOptions = {
    drawerPosition: 'right',
    drawerStyle: {
      width: 400,
    },
    headerShown: false,
  };

  const drawerContent = (props) => (
    <RightDrawerContent contentContainerStyle={ThemeStyle.flex1} {...props}>
      <Fragment>
        <View style={[ThemeStyle.flex1, styles.spacingRight]}>
          {isStorePage && !xl && (
            <SwitchTabOrderType
              size="medium"
              types={availableStoreOrderTypes}
              value={selectedOrderType}
              onChange={updateShopOrderType}
              showMealPlan
            />
          )}
          {isStorePage && isMealPlan && !xl ? (
            <MealPlanList />
          ) : (
            <CartList
              shopData={isStorePage && shopData}
              cartData={cartData}
              isCartLoading={isUserCartLoading?.loading}
              isClickableHeader={true}
              contentContainerStyle={{ flexGrow: 1 }}
            />
          )}
        </View>
      </Fragment>
    </RightDrawerContent>
  );
  return (
    <RightDrawer.Navigator
      id="RightDrawer"
      defaultStatus="closed"
      useLegacyImplementation
      drawerContent={drawerContent}
      screenOptions={screenOptions}
    >
      <RightDrawer.Screen
        name={'MainMenu'}
        component={LeftDrawerScreen}
        initialParams={rightDrawerProps}
        options={{
          unmountOnBlur: true,
        }}
      />
    </RightDrawer.Navigator>
  );
};
const DrawerNavigator = (props) => {
  return <RightDrawerScreen {...props} />;
};

export const { ids, styles } = StyleSheet.create({
  topLayout: {
    [`@media ${BreakPoints.xs}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.md}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.lg}`]: {
      alignItems: 'center',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  topLayoutWithOutCredentials: {
    [`@media ${BreakPoints.xs}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.sm}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.md}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.lg}`]: {
      paddingTop: 10,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },
  mobileTopLayout: {
    paddingTop: 10,
    paddingHorizontal: 20,
  },
  addressandDateFilterContainer: {
    flex: 1,
    paddingTop: 10,
    paddingBottom: 10,
    flexDirection: 'row',
    justifyContent: 'center',
    columnGap: 20,
  },
  webTopLayout: {
    alignItems: 'center',
  },
  searchContainer: {
    [`@media ${BreakPoints.xs}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'none',
    },
  },

  searchInput: {
    ...ThemeStyle.regular,
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    marginRight: 10,
    minWidth: 200,
  },
  modalContentContainer: {
    ...ThemeStyle.spacingBottomMedium,
    ...ThemeStyle.alignItemsCenter,
  },
  modalContentRegular: {
    textAlign: 'center',
    ...ThemeStyle.spacingBottomMedium,
  },
  mainContentWrapperStyle: { flex: null },
  confirmationModalHeader: {
    flex: null,
    paddingRight: 10,
    height: 40,
    alignItems: 'center',
  },
  confirmModalWrapper: {
    flex: null,
    top: 100,
    width: 400,
    height: 'auto',
    padding: null,
    paddingBottom: 10,
  },

  controlContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingHorizontal: 20,
  },

  yesButtonContainer: {
    backgroundColor: ThemeColor.green,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  controlButtonSeparator: {
    width: 30,
  },
  noButtonContainer: {
    backgroundColor: ThemeColor.white,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  yesTxt: { color: ThemeColor.white },
  noTxt: { color: ThemeColor.black },
  spacingRight: { paddingRight: 15 },
  pop_up_wrapper: {
    alignSelf: 'center',
    backgroundColor: ThemeColor.white,
    borderWidth: 0,
    cursor: 'pointer',
  },
  pop_up_address_container: {
    backgroundColor: 'transparent',
  },
  saving_address_container: {
    backgroundColor: ThemeColor.white,
    top: 30,
    maxWidth: 600,
    paddingTop: '2%',
    paddingBottom: '2%',
    [`@media ${BreakPoints.xs}`]: {
      width: 350,
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.md}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 500,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 550,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 600,
    },
  },
  ...homeStyles,
});

export default withDatePicker(DrawerNavigator);
