import React, { Fragment, useState } from 'react';
import { ActivityIndicator, StyleSheet, View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { SafeAreaView } from 'react-native-safe-area-context';
import Modal from 'react-native-modal';
import OTPInputView from '@twotalltotems/react-native-otp-input';

import PickupWordLogo from '../../Assets/PickupWordLogo.svg';
import Button from '../../Components/Button';
import ScreenWrapper from '../../Components/ScreenWrapper';
import constants from '../../Config/constants';
import { anonymize } from '../../Helper';
import ThemeColor from '../../Theme/colors';
import ThemeStyle, { deviceHeight, globalSpacing } from '../../Theme/styles';

import OTPTimer from './OTPTimer';

const otplength = 6;

function Otp({
  email,
  isResending,
  isSubmitting,
  onBack,
  timer,
  onCountdownEnd,
  onResend,
  onSubmit,
}) {
  const [otp, setOtp] = useState('');

  const _onResend = () => {
    setOtp('');
    onResend();
  };

  return (
    <Fragment>
      {/* Info */}
      <View style={styles.contentContainer}>
        <View>
          {/* Icon, number and Code input */}
          <View
            style={[
              ThemeStyle.flex1,
              ThemeStyle.alignItemsCenter,
              ThemeStyle.spacingBottom,
            ]}
          >
            {/* app logo */}
            <PickupWordLogo width={200} height={100} />
            {/* sent to */}
            <Text category="p1">OTP has been sent to</Text>
            {/* number */}
            <View style={styles.numberContainer}>
              <Text appearance="alternative" category="p2">
                {anonymize(email, 3, email.length - email.indexOf('@'))}
              </Text>
            </View>
            {/* input label */}
            <Text category="p1">Enter your OTP</Text>
            {/* Inputs */}
            <View style={[ThemeStyle.spacingBottom]}>
              <OTPInputView
                code={otp}
                codeInputFieldStyle={{
                  borderRadius: 10,
                  borderWidth: 2,
                  color: ThemeColor.black,
                  marginHorizontal: globalSpacing / 4,
                }}
                codeInputHighlightStyle={{
                  borderRadius: 10,
                  borderWidth: 2,
                  borderColor: ThemeColor.darkGreen,
                }}
                pinCount={otplength}
                selectionColor={ThemeColor.black}
                onCodeChanged={setOtp}
                style={{ height: 50 }}
                autoFocusOnLoad={false}
                onCodeFilled={onSubmit}
                editable={!isResending}
              />
            </View>
            {/* Resend / Countdown */}
            {timer <= 0 ? (
              <View style={ThemeStyle.flexDirectionRowCenter}>
                <Text appearance={!constants.isWeb && 'alternative'}>
                  Did not receive a code?
                </Text>
                <Button
                  onPress={_onResend}
                  style={ThemeStyle.spacingLeftSmall}
                  disabled={isResending}
                  plain
                >
                  <Text status="success">
                    {isResending ? 'Resending' : 'Resend'}
                  </Text>
                </Button>
              </View>
            ) : (
              <OTPTimer
                timer={timer}
                text="Resend code in"
                onCountdownEnd={onCountdownEnd}
              />
            )}
            <Text
              category="label"
              appearance={!constants.isWeb && 'alternative'}
              style={[ThemeStyle.dimColor, ThemeStyle.spacingTop]}
            >
              Never share your OTP with anyone.
            </Text>
          </View>
        </View>
      </View>

      {/* Loader */}
      <Modal
        isVisible={isSubmitting}
        animationIn="fadeIn"
        animationOut="fadeOut"
        deviceHeight={deviceHeight}
        useNativeDriver
        statusBarTranslucent
      >
        <SafeAreaView>
          <View style={ThemeStyle.flex1}>
            <ActivityIndicator color={ThemeColor.white} size="large" />
          </View>
        </SafeAreaView>
      </Modal>
    </Fragment>
  );
}

const styles = StyleSheet.create({
  contentContainer: [
    ThemeStyle.flex1,
    ThemeStyle.alignItemsCenter,
    ThemeStyle.justifyContentCenter,
  ],
  logo: {
    width: 200,
    height: 50,
    marginTop: globalSpacing,
    marginBottom: globalSpacing * 2,
  },
  numberContainer: {
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingMedium,
    ...ThemeStyle.spacingBottom,
    ...ThemeStyle.spacingTopSmall,
    backgroundColor: ThemeColor.green,
    borderRadius: 20,
  },
});

export default Otp;
