import React, { Fragment, useRef, useState } from 'react';
import { View } from 'react-native';

import constants from '../../Config/constants';
import { isScrollEndReached } from '../../Helper/Routines';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

import ArrowControl from '../ArrowControl';
import List from '../List';

// this component can be use on 'any' listing as long as it's horizontal
// this component also include ArrowControl for web
// getMarginStyle is commonly used margin for horizontal listing both mobile and web
const getMarginStyle = (isFirst, isLast, noMargin = constants.isWeb) => {
  const firstAndLastMargin = noMargin ? 0 : globalSpacing;
  return {
    marginLeft: isFirst ? firstAndLastMargin : globalSpacing / 4,
    marginRight: isLast ? firstAndLastMargin : globalSpacing / 4,
  };
};
function HorizontalListing({ leftContent, rightContent, ...props }) {
  const list = useRef();
  const [isEndReached, setEndReached] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const hasRightContent = !!rightContent;

  const _onScroll = ({ nativeEvent }) => {
    const isEnd = isScrollEndReached(nativeEvent);
    if (!isEnd && isEndReached) {
      setEndReached(false);
    }
    setScrollOffset(nativeEvent?.contentOffset?.x);
  };

  const _onEndReached = () => {
    // always get called when end reached (e.g on end then scroll back, then scroll to end again)
    setEndReached(true);
  };

  // prettier-ignore
  const conditionalProps = constants.isWeb ? { onScroll: _onScroll, onEndReached: _onEndReached } : {}

  return (
    <Fragment>
      {/* Heading */}
      <View
        style={[
          ThemeStyle.flexDirectionColEnd,
          ThemeStyle.flexDirectionRowSpaceBetween,
          !!leftContent && !constants.isWeb && ThemeStyle.spacingBottomSmall,
        ]}
      >
        {/* Left Content */}
        <View
          style={[
            ThemeStyle.flex1,
            hasRightContent && ThemeStyle.spacingRight,
            constants.isWeb && ThemeStyle.spacingBottomSmall,
          ]}
        >
          {leftContent}
        </View>

        {/* Arrow Control & Right Content */}
        <View
          style={[
            ThemeStyle.flexDirectionRowCenter,
            constants.isWeb && ThemeStyle.spacingBottomSmall,
          ]}
        >
          {!!hasRightContent && (
            <View style={ThemeStyle.spacingRight}>{rightContent}</View>
          )}
          {constants.isWeb && (
            <ArrowControl
              listRef={list}
              scrollOffset={scrollOffset}
              isEndReached={isEndReached}
            />
          )}
        </View>
      </View>

      {/* Horizontal list */}
      <List {...props} {...conditionalProps} ref={list} horizontal />
    </Fragment>
  );
}

export { getMarginStyle };
export default HorizontalListing;
