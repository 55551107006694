import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';

import FilterMainCategory from '../../Components/Filter/MainCategory';
import FilterCategory from '../../Components/Filter/Category';
import FilterTag from '../../Components/Filter/Tag';
import ShopAd from '../../Components/ShopAd';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';
import { SECTION_TITLE } from '../../Theme/typographyProps';

import HorizontalListingService from './HorizontalListingService';

class HomepageListHeader extends React.PureComponent {
  render() {
    const {
      // for FilterTag
      isLoading,
      resultCount,
      // for pull to refresh
      refreshing,
      // for ShopAd & Horizontal Listing
      showAdAndHorizontalList,
      segments,
    } = this.props;

    return (
      <Fragment>
        {!constants.isWeb && (
          <Fragment>
            {constants.USE_PARENT_CATEGORY && <FilterMainCategory />}
            <FilterCategory />
            <FilterTag isLoading={isLoading} resultCount={resultCount} />
          </Fragment>
        )}

        {showAdAndHorizontalList && (
          <Fragment>
            {/* <ShopAd /> */}

            {segments.map((list, index) => (
              <HorizontalListingService
                key={index}
                refreshing={refreshing}
                {...list}
              />
            ))}

            <View
              style={[
                ThemeStyle.spacingTopMedium,
                !constants.isWeb && ThemeStyle.pageHorizontalSpacing,
              ]}
            >
              <Text
                style={ThemeStyle.flex1}
                {...(constants.isWeb ? { category: 's1' } : SECTION_TITLE)}
              >
                All Stores
              </Text>
            </View>
          </Fragment>
        )}
      </Fragment>
    );
  }
}

HomepageListHeader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  resultCount: PropTypes.number.isRequired,
  refreshing: PropTypes.bool,
  showAdAndHorizontalList: PropTypes.bool.isRequired,
  segments: PropTypes.array.isRequired,
  onSearchPress: PropTypes.func,
};

export default HomepageListHeader;
