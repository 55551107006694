import { Fragment, useState } from 'react';
import StyleSheet from 'react-native-media-query';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

import { Card, Layout, Select, SelectItem, Text } from '@ui-kitten/components';

import storeApi from '../../../../Service/api/store';

//THEMES
import ThemeColor from '../../../../Theme/colors';
import ThemeStyle from '../../../../Theme/styles';
import BreakPoints from '../../../../Theme/styles/breakpoints';

import Button from '../../../Button';
import constants from '../../../../Config/constants';
import ModalPrompt from './ModalPrompt';
import { MODALPROMPT } from './config';
import useModalPrompt from './hooks/useModalPrompt';
import { CartDetails, TimeOptions } from '../../../../Types';
import { shopSelector } from '../../../../RTK/shop/selectors';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import useMealPlan from '../../../../Hooks/useMealPlan';
import { whenFilterSelector } from '../../../../RTK/filter/selectors';

const RescheduleTextIfLimit = 'Do you want to change your order schedule?';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(tz);

type ChangeTimeSchedulePayload = {
  title: string;
  value: { label: string; value: string };
  orderTimeSlot: Array<{ label: string; value: string; canAsap: string }>;
  error: {
    errorType: string;
    title: string;
    message: string;
    unavailableItems: Array<CartDetails>;
  };
};
const ChangeTimeSchedule = () => {
  const shopData = useSelector(shopSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const { isMealPlan } = useMealPlan();
  const { hideModalPrompt } = useModalPrompt();

  const [data, setData] = useState<ChangeTimeSchedulePayload>();
  const [selectedAdvanceTime, setSelectedAdvanceTime] = useState<TimeOptions>();
  const [changeSchedule, setChangeSchedule] = useState(false);

  const { error, title, value, orderTimeSlot } = data || {};

  const hasError = !lodashIsEmpty(error?.errorType);

  const { data: storeHoursResult, isFetching: storeHoursLoading } = useQuery({
    queryKey: [shopData?.id, 'store-hours'],
    queryFn: () => storeApi.getStoreOrderDates(shopData?.id),
    enabled:
      Boolean(shopData?.id) && !isMealPlan && lodashIsUndefined(orderTimeSlot), // since web is using it on meal plan and non meal plan order, prevent fetch if has mealPlanType
    refetchOnWindowFocus: false,
  });

  const orderAdvanceTime = !lodashIsUndefined(orderTimeSlot)
    ? orderTimeSlot[0]
    : storeHoursResult?.data?.length > 0
    ? storeHoursResult?.data[0]
    : [{ time_options: [] }];

  const selectedTimeValue =
    selectedAdvanceTime?.value === 'asap_order_time'
      ? 'ASAP'
      : selectedAdvanceTime?.value
      ? `Today at ${dayjs(selectedAdvanceTime.start).format(
          constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
        )}`
      : !lodashIsEmpty(value) || !lodashIsEmpty(whenFilter?.label)
      ? value?.label || whenFilter?.label
      : '';

  const _onSelectAdvanceTime = async (index) => {
    //get selected date
    const selectData = orderAdvanceTime?.time_options[index - 1];
    setSelectedAdvanceTime(selectData);
  };

  const onPressProceed = async () => {
    if (selectedAdvanceTime?.value !== '') {
      const updatedWhenFilter = {
        label:
          selectedAdvanceTime?.value === 'asap_order_time'
            ? 'ASAP'
            : `Today at ${dayjs(selectedAdvanceTime.start).format(
                constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
              )}`,
        value:
          selectedAdvanceTime?.value === 'asap_order_time'
            ? 'asap'
            : selectedAdvanceTime.start,
        date:
          selectedAdvanceTime?.value === 'asap_order_time'
            ? ''
            : dayjs(selectedAdvanceTime.start).format(constants.DBDATE_FORMAT),
        time:
          selectedAdvanceTime?.value === 'asap_order_time'
            ? ''
            : selectedAdvanceTime?.value,
        canAsap: selectedAdvanceTime?.value === 'asap_order_time',
      };
      setChangeSchedule(false);
      hideModalPrompt(MODALPROMPT.changeTimeSchedule, updatedWhenFilter);
    }
  };
  const onPressClosed = () => {
    setChangeSchedule(false);
  };
  return (
    <ModalPrompt
      id={MODALPROMPT.changeTimeSchedule}
      title={changeSchedule ? 'Change Schedule' : error?.title}
      dataSet={{ media: ids.mainWrapper }}
      mainContentWrapperStyle={styles.mainWrapper}
      hasHeader={true}
      hasHeaderLine={true}
      closeOnDocumentClick={false}
      onBeforeClose={onPressClosed}
      scrollEnabled={false}
      onBeforeShow={setData}
    >
      <Layout style={[ThemeStyle.spacingBottomSmall]}>
        <Layout style={[ThemeStyle.spacingBottomMedium]}>
          <Text category="h6">{title ? title : 'Select Dispatch Time'}</Text>
        </Layout>
        {hasError && !changeSchedule && (
          <Fragment>
            <Card
              style={[
                { backgroundColor: '#ffe2e6' },
                ThemeStyle.spacingBottomMedium,
              ]}
            >
              <Text category="p2" status="danger">
                {error?.message}
              </Text>
            </Card>
            <Button
              style={ThemeStyle.spacingBottomMedium}
              status="success"
              onPress={() => {
                setChangeSchedule(true);
              }}
            >
              {() => (
                <Text category="c1" status="control">
                  {RescheduleTextIfLimit}
                </Text>
              )}
            </Button>
          </Fragment>
        )}
        {(!hasError || changeSchedule) && (
          <Fragment>
            <Layout style={[ThemeStyle.spacingBottomMedium]}>
              <Layout style={styles.container}>
                <Layout style={styles.layout} level="1">
                  <Select
                    onSelect={(index) => _onSelectAdvanceTime(index)}
                    value={selectedTimeValue}
                    size="large"
                  >
                    {orderAdvanceTime?.time_options?.map(
                      ({ value, start }, index) => (
                        <SelectItem
                          key={index}
                          title={
                            value === 'asap_order_time'
                              ? 'ASAP'
                              : `Today at ${dayjs(start).format(
                                  constants.TIME_ONLY_DISPLAY_CAPITAL_FORMAT
                                )}`
                          }
                        />
                      )
                    )}
                  </Select>
                </Layout>
              </Layout>
            </Layout>
            <Button
              disabled={!selectedAdvanceTime?.value && storeHoursLoading}
              plain
              style={styles.proceedBtn}
              onPress={onPressProceed}
            >
              <Text category="label" status="basic">
                Proceed
              </Text>
            </Button>
          </Fragment>
        )}
      </Layout>
    </ModalPrompt>
  );
};
const { ids, styles } = StyleSheet.create({
  mainWrapper: {
    height: 'auto',
    [`@media ${BreakPoints.xs}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.sm}`]: {
      width: 'auto',
    },
    [`@media ${BreakPoints.md}`]: {
      width: 400,
    },
    [`@media ${BreakPoints.lg}`]: {
      width: 450,
    },
    [`@media ${BreakPoints.xl}`]: {
      width: 500,
    },
    [`@media ${BreakPoints.xxl}`]: {
      width: 550,
    },
  },
  container: {
    ...ThemeStyle.flex1,
  },
  layout: {},
  proceedBtn: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderWidth: 1,
    borderColor: ThemeColor.green,
  },
});
export default ChangeTimeSchedule;
