import React, { Fragment } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { createDrawerNavigator } from '@react-navigation/drawer';

import lodashIsEmpty from 'lodash/isEmpty';

//HELPER
import ScreenSize from '../../Helper/ScreenSize';

//HOOKS
import withDatePicker from '../../Hooks/withDatePicker';
import useDetailsController from '../../Screens/Store/controller/details';
import useMealPlan from '../../Hooks/useMealPlan';

//COMPONENTS
import CartList from '../../Components/Web/Cart/CartList';
import Header from '../../Components/Header/index.branded';
import RightDrawerContent from '../../Components/RightDrawerContent';
import SwitchTabOrderType from '../../Components/SwitchTabOrderType';

//STYLES
import { styles } from './';

//SCREENS
import MealPlanList from '../../Screens/Store/MealPlan/List';

import { shopSelector } from '../../RTK/shop/selectors';
import { storeCartSelector } from '../../RTK/cart/selectors';
import Store from '../../Screens/Store';

const RightDrawer = createDrawerNavigator();

const RightDrawerScreen = () => {
  const shopData = useSelector(shopSelector);
  const cartStore = useSelector((state) =>
    storeCartSelector(state, shopData?.id, true)
  );

  const { xl } = ScreenSize();
  const { isMealPlan } = useMealPlan();

  const { availableStoreOrderTypes, selectedOrderType, updateShopOrderType } =
    useDetailsController(xl);

  const screenOptions = {
    drawerPosition: 'right',
    drawerStyle: {
      width: 400,
    },
    header: (props) => (
      <View>
        <Header {...props} />
      </View>
    ),
    sceneContainerStyle: {
      backgroundColor: '#fff',
    },
  };

  const drawerContent = (props) => {
    return (
      <RightDrawerContent {...props}>
        <Fragment>
          <View style={styles.spacingRight}>
            {!xl && (
              <SwitchTabOrderType
                size="medium"
                types={availableStoreOrderTypes}
                value={selectedOrderType}
                onChange={updateShopOrderType}
                showMealPlan
              />
            )}
            {isMealPlan && !xl ? (
              <MealPlanList />
            ) : (
              <View
                style={lodashIsEmpty(cartStore) && styles.cartListContainer}
              >
                <CartList
                  shopData={shopData}
                  cartData={cartStore}
                  isCartLoading={false}
                  isClickableHeader={true}
                  {...props}
                />
              </View>
            )}
            <View style={styles.bottom} />
          </View>
        </Fragment>
      </RightDrawerContent>
    );
  };

  return (
    <RightDrawer.Navigator
      id="RightDrawer"
      defaultStatus="closed"
      useLegacyImplementation
      drawerContent={drawerContent}
      screenOptions={screenOptions}
      initialRouteName={'order'}
    >
      <RightDrawer.Screen
        name="order"
        component={Store}
        options={{
          unmountOnBlur: true,
        }}
      />
    </RightDrawer.Navigator>
  );
};

export default withDatePicker(RightDrawerScreen);
