import React, { Fragment, useRef, useState } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';
import { Divider, Icon, Input, Layout, Text } from '@ui-kitten/components';
import { useSelector } from 'react-redux';
import lodashIsEmpty from 'lodash/isEmpty';
import Modal from 'react-native-modal';

//HELPER
import ScreenSize from '../../Helper/ScreenSize';

//COMPONENTS
import Button from '../../Components/Button';
import Footer from '../../Components/Footer';
import ScreenBanner from '../../Components/ScreenBanner';
import SwitchTabOrderType from '../../Components/SwitchTabOrderType';

//CONTENT
import StoreDescription from './StoreDescription';
import PromotionService from './PromotionService';
//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';
import BreakPoints from '../../Theme/styles/breakpoints';

//CONSTANTS
import constants from '../../Config/constants';

//CONTROLLER
import useController from './controller';

import Badge from '../../Components/Badge';
import CartList from '../../Components/Web/Cart/CartList';
import { Section } from '../../Components/Web/Items';
import TabSectionList from './component/TabSectionList/TabSectionList';

import MealPlanList from './MealPlan/List';

import useError from '../../Hooks/useError';

import useMealPlan from '../../Hooks/useMealPlan';
import useDetailsController from './controller/details';
import useListSectionItemController from './ListSectionItem/controller/ListSection.controller';
import { storeCartSelector } from '../../RTK/cart/selectors';
import { shopLoadingSelector, shopSelector } from '../../RTK/shop/selectors';

import FeaturedItems from './FeaturedItems';
import Loader from './Loader';
import LoaderMenu from './Loader/Menu';

import { RAZZLE_BUILD_MODE } from '@env';
import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import ImageHelper from '../../Helper/Image';
const Store = (props) => {
  const { id, distance } = props.route?.params || {};
  const scrollRef = useRef(null);

  //REDUX
  const shopData = useSelector(shopSelector);
  const shopLoading = useSelector(shopLoadingSelector);

  const cartStore = useSelector((state) =>
    storeCartSelector(state, shopData?.id, true)
  );

  ///STATES
  const [search, setSearch] = useState('');
  const [showSearchModal, setSearchModal] = useState(false);
  const { searchItems, searchItemByName, onItemPress } =
    useListSectionItemController();
  const { ErrorCartOrCheckoutHandler } = useError(shopData?.id);
  const { xs2 } = ScreenSize();
  const {
    state: { isGettingMenu, menuError },
  } = useController(props);

  const { isMealPlan } = useMealPlan();
  const { showModalPrompt } = useModalPrompt();

  const { availableStoreOrderTypes, selectedOrderType, updateShopOrderType } =
    useDetailsController();

  const onChangeText = (nextValue) => {
    setSearch(nextValue);
    searchItemByName(nextValue);
  };

  const onPressSearch = () => setSearchModal(!showSearchModal);

  const renderSectionTitle = ({
    section: { title, subTitle, isExclusive, categoryStock, index },
  }) => (
    <View key={index} style={{ backgroundColor: ThemeColor.white }}>
      <View
        dataSet={{ media: ids.sectionTitleContainer }}
        style={[styles.sectionTitleContainer, ThemeStyle.spacingTop]}
      >
        <Text category="s1">{title}</Text>
        <View
          style={[
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.spacingLeftMedium,
          ]}
        >
          {!!categoryStock && (
            <Badge
              type={Badge.TYPES.SOLID}
              category={Badge.CATEGORIES.DANGER}
              size="c1"
            >
              {`${categoryStock} items left!`}
            </Badge>
          )}
          {isExclusive && (
            <View style={ThemeStyle.spacingLeftMedium}>
              <Badge
                type={Badge.TYPES.OUTLINE}
                category={Badge.CATEGORIES.INFO}
                size="c1"
              >
                EXCLUSIVE
              </Badge>
            </View>
          )}
        </View>
      </View>
      {!!subTitle && (
        <View style={styles.sectionSubtitleContainer}>
          <View
            style={
              isExclusive
                ? styles.exclusiveContainer
                : ThemeStyle.spacingTopSmall
            }
          >
            <Text
              category="p2"
              style={[ThemeStyle.dimColor, isExclusive && styles.exclusiveText]}
            >
              {subTitle}
            </Text>
          </View>
        </View>
      )}
    </View>
  );

  const renderSectionItem = (item) => (
    <Section
      {...item}
      isMealPlan={isMealPlan}
      onError={(err) =>
        ErrorCartOrCheckoutHandler({
          error: err,
          isAcceptingAdvanceOrder: shopData?.is_accepting_in_advanced_orders,
        })
      }
      onItemPress={onItemPress}
    />
  );

  const renderEmpty = () => {
    if (isGettingMenu) {
      return null;
    }
    return (
      <View
        style={[
          ThemeStyle.pageVerticalSpacing,
          ThemeStyle.pageHorizontalSpacing,
        ]}
      >
        <Text style={[ThemeStyle.textCenter, ThemeStyle.bold]}>
          {menuError || 'No item to display.'}
        </Text>
      </View>
    );
  };

  const seeReviews = () => showModalPrompt(MODALPROMPT.reviewsModal);

  return (
    <View style={[ThemeStyle.flex1, ThemeStyle.flexDirectionRow]}>
      <View style={styles.wrapperFirstColumn}>
        <TabSectionList
          ref={scrollRef}
          data={
            Object.keys(searchItems)?.length > 0
              ? searchItems.menu_list
              : !lodashIsEmpty(shopData)
              ? shopData?.menu_list
              : []
          }
          search={search}
          onSearch={onChangeText}
          onPressSearch={onPressSearch}
          ListHeaderComponent={() =>
            shopLoading ? (
              <Loader />
            ) : (
              <Fragment>
                <View style={ThemeStyle.spacingTop}>
                  <ScreenBanner
                    banner={ImageHelper.getStoreBannerImage(shopData)}
                    avatar={ImageHelper.getStoreLogoImage(shopData)}
                  />
                </View>
                <StoreDescription
                  store_id={id}
                  distance={distance}
                  seeReviews={seeReviews}
                />
                <PromotionService />
                {isGettingMenu && <LoaderMenu />}
                {RAZZLE_BUILD_MODE !== 'branded' &&
                  !lodashIsEmpty(shopData?.featured_items) && (
                    <Fragment>
                      <Divider style={ThemeStyle.divider} />
                      <FeaturedItems
                        isMealPlan={isMealPlan}
                        onPress={onItemPress}
                      />
                    </Fragment>
                  )}
              </Fragment>
            )
          }
          renderSectionItem={renderSectionItem}
          renderSectionTitle={renderSectionTitle}
          ListEmptyComponent={renderEmpty}
          ListFooterComponent={() => (
            <View
              dataSet={{ media: ids.footerContainer }}
              style={[styles.footerContainer, ThemeStyle.spacingTop]}
            >
              <Footer />
            </View>
          )}
        />
      </View>

      {/* second column */}

      <View
        dataSet={{ media: ids.wrapperSecondColumn }}
        style={styles.wrapperSecondColumn}
      >
        <SwitchTabOrderType
          size="medium"
          types={availableStoreOrderTypes}
          value={selectedOrderType}
          onChange={updateShopOrderType}
          showMealPlan
        />
        {isMealPlan ? (
          <MealPlanList />
        ) : (
          <CartList
            shopData={shopData}
            cartData={cartStore}
            isCartLoading={shopLoading}
            emptyCartTitle={`Seems like your ${constants.cartTerm} is empty...`}
            isClickableHeader={false}
            contentContainerStyle={
              lodashIsEmpty(cartStore) && styles.contentCenter
            }
            onItemPress={(item) => onItemPress({ ...item, isUpdate: true })}
            route={props.route}
          />
        )}
      </View>

      <Modal
        animationIn={'slideInDown'}
        animationOut={'slideOutUp'}
        isVisible={showSearchModal && xs2}
        style={styles.searchModalContainer}
        useNativeDriver
        onBackdropPress={onPressSearch}
        onModalWillHide={!xs2 && onPressSearch}
      >
        <Layout style={styles.searchContainer}>
          <Input
            placeholder="Search store menu"
            value={search}
            accessoryLeft={() => (
              <Button plain>
                <Icon
                  name={'search'}
                  fill={ThemeColor.green}
                  pack="ion"
                  style={styles.searchIcon}
                />
              </Button>
            )}
            textStyle={styles.textInputStyle}
            style={styles.searchInput}
            onChangeText={onChangeText}
          />
        </Layout>
      </Modal>
    </View>
  );
};

const { ids, styles } = StyleSheet.create({
  wrapperFirstColumn: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    backgroundColor: ThemeColor.white,
  },
  wrapperSecondColumn: {
    width: 400,
    height: '100vh',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
    borderLeftColor: ThemeColor.gray100,
    borderLeftWidth: 1,
    [`@media ${BreakPoints.xs}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.sm}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.md}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.lg}`]: {
      display: 'none',
    },
    [`@media ${BreakPoints.xl}`]: {
      display: 'flex',
    },
    [`@media ${BreakPoints.xxl}`]: {
      display: 'flex',
    },
  },

  control_icon: { width: 20, height: 20 },
  paging_indicator: {
    width: 40,
    height: 10,
    marginRight: '3%',
    borderColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderRadius: 60,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  confirmModalWrapper: {
    width: 400,
    height: 'auto',
    flexDirection: 'column',
    padding: '0%',
  },
  removeButtonContainer: {
    backgroundColor: ThemeColor.green,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  cancelButtonContainer: {
    backgroundColor: ThemeColor.white,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  checkoutTxt: { ...ThemeStyle.medium, color: ThemeColor.white },
  cancelTxt: { ...ThemeStyle.medium, color: ThemeColor.black },
  singleContentContainer: {
    alignItems: 'center',
  },
  singleContentBold: { fontSize: 20, textAlign: 'center', fontWeight: 'bold' },
  singleContentRegular: {
    fontSize: 16,
    textAlign: 'center',
    marginTop: 10,
  },
  controlContainer: {
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    flexDirection: 'row',
    paddingHorizontal: 20,
    marginTop: 20,
  },
  removeButtonContainer: {
    backgroundColor: ThemeColor.green,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },
  controlButtonSeparator: {
    width: 30,
  },
  cancelButtonContainer: {
    backgroundColor: ThemeColor.white,
    width: 100,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
    ...ThemeStyle.fullRoundCorder,
  },

  inputSearchWrapper: {
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    alignSelf: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  searchModalContainer: {
    justifyContent: 'flex-start',
    margin: 0,
    borderBottomLeftRadius: 30,
    borderBottomRightRadius: 30,
    position: 'relative',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 100,
    paddingHorizontal: 10,
  },

  control_icon: { width: 15, height: 15 },

  paging_indicator: {
    width: 40,
    height: 10,
    marginRight: '3%',
    borderColor: ThemeColor.lightGray,
    borderWidth: 1,
    borderRadius: 60,
    alignSelf: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section_selected: {
    backgroundColor: ThemeColor.white,
    justifyContent: 'center',
    alignItems: 'center',
    height: '12%',
    borderBottomWidth: 3,
    borderBottomColor: ThemeColor.green,
  },
  section_unselected: {
    backgroundColor: ThemeColor.white,
    justifyContent: 'center',
    alignItems: 'center',
    height: '12%',
    borderBottomWidth: 0,
    borderBottomColor: null,
  },
  textInputStyle: {
    ...ThemeStyle.regular,
    fontSize: 14,
  },
  item: {
    backgroundColor: '#f9c2ff',
    padding: 20,
    width: '49%',
    height: '80%',
    marginVertical: 10,
  },

  title: {
    fontSize: 24,
  },
  searchInput: {
    borderColor: ThemeColor.gray,
    borderRadius: 30,
    borderWidth: 1,
    width: '100%',
  },
  searchIcon: { width: 20, height: 20 },
  icon_size: { width: 15, height: 15 },
  skeleton_container: { display: 'flex' },
  full_menu_txt: {
    ...ThemeStyle.bold,
    fontSize: 20,
  },
  sectionTitleContainer: {
    ...ThemeStyle.flexDirectionRowCenter,
    justifyContent: 'space-between',
    backgroundColor: ThemeColor.white,
    [`@media ${BreakPoints.xs}`]: ThemeStyle.pageHorizontalSpacing,
  },
  sectionSubtitleContainer: {
    padding: 10,
  },
  contentCenter: {
    flexGrow: 1,
    justifyContent: 'center',
    marginBottom: 50,
  },
  exclusiveContainer: {
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
    ...ThemeStyle.pageVerticalSpacingSmall,
    borderWidth: StyleSheet.hairlineWidth,
    borderRadius: 5,
    borderColor: ThemeColor.blue,
  },
  exclusiveText: {
    color: ThemeColor.blue,
  },
  footerContainer: {
    [`@media ${BreakPoints.xs}`]: {
      marginHorizontal: 0,
      paddingHorizontal: 0,
    },
    [`@media ${BreakPoints.sm}`]: {
      marginHorizontal: 50,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.md}`]: {
      marginHorizontal: 50,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.lg}`]: {
      marginHorizontal: 50,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.xl}`]: {
      marginHorizontal: 50,
      paddingHorizontal: 20,
    },
    [`@media ${BreakPoints.xxl}`]: {
      marginHorizontal: 50,
      paddingHorizontal: 20,
    },
  },
});

export default Store;
