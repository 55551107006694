import React, { Fragment, useEffect, useState } from 'react';
import { View } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useNavigation, useRoute } from '@react-navigation/native';
import { Text, Icon, Input } from '@ui-kitten/components';
import Popup from 'reactjs-popup';
import dayjs from 'dayjs';

import lodashIsUndefined from 'lodash/isUndefined';
import lodashIsEmpty from 'lodash/isEmpty';

//REDUX
import { populatesResultSearched } from '../../RTK/search';
import { logout } from '../../RTK/user';

//CONFIG
import constants from '../../Config/constants';

//HELPER
import ScreenSize from '../../Helper/ScreenSize';

//SERVICE
import searchApi from '../../Service/api/search';

//ROUTES
import routeList from '../list';

//HOOKS
import useCancellableRequest from '../../Hooks/useCancellableRequest';
import useAddress from '../../Hooks/useAddress';
import useSetUserData from '../../Hooks/useSetUserData';

//COMPONENTS
import { AddressSelector } from '../../Components/Web/Address';
import Button from '../../Components/Button';
import DatePicker from '../../Components/DatePicker';
import Header from '../../Components/Header';
import LeftDrawerContent from '../../Components/LeftDrawerContent';

//THEMES
import ThemeColor from '../../Theme/colors';
import ThemeStyle from '../../Theme/styles';

//STYLES
import { ids, styles } from './';

//SCREENS
import { Profile } from '../../Screens/Account';
import Basket from '../../Screens/Basket';
import FeedDescription from '../../Screens/Feed/view/Description';
import HomeNavigator from '../HomeNavigator';
import SeeAll from '../../Screens/SeeAll';
import Store from '../../Screens/Store';

import { isLoggedInSelector, userSelector } from '../../RTK/user/selectors';

import useModalPrompt from '../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../Components/Web/Modal/ModalPrompt/config';
import { setAddressSelection as setAddressNormalSelection } from '../../RTK/checkout';
import { setAddressSelection as setAddressMealPlanSelection } from '../../RTK/mealPlan';
import { mealPlanCheckoutFlagSelector } from '../../RTK/mealPlan/selectors';
import { checkoutFlagSelector } from '../../RTK/checkout/selectors';
import { whenFilterSelector } from '../../RTK/filter/selectors';

const LeftDrawer = createDrawerNavigator();
const iconSize = 20;

const LeftDrawerScreen = () => {
  const dispatch = useDispatch();
  const navigation = useNavigation<any>();
  const route = useRoute<any>();

  const loadHomeData = useSetUserData();
  const { createRequest } = useCancellableRequest();
  const { setActiveAddress } = useAddress();
  const { showModalPrompt } = useModalPrompt();
  const { xs } = ScreenSize();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const userDetails = useSelector(userSelector);
  const normalAddressSelection = useSelector(checkoutFlagSelector);
  const mealPlanAddressSelection = useSelector(mealPlanCheckoutFlagSelector);
  const whenFilter = useSelector(whenFilterSelector);
  const emailReviewDeepLinking = useSelector(
    (state: any) => state.noSession.emailReviewDeepLinking
  );

  const [dateVisible, setDateVisible] = useState(false);

  const activeAddress = userDetails?.addresses.filter((item) => {
    return item.active == true;
  });

  useEffect(() => {
    if (!isLoggedIn && lodashIsEmpty(userDetails?.addresses)) {
      if (!lodashIsEmpty(emailReviewDeepLinking)) {
        setTimeout(
          () =>
            showModalPrompt(MODALPROMPT.authentication, {
              isLogin: true,
              navigation: navigation,
            }),
          500
        );
        return;
      }
      setTimeout(() => showModalPrompt(MODALPROMPT.deliveryAddress, {}), 500);
    }
    if (normalAddressSelection || mealPlanAddressSelection) {
      dispatch(setAddressNormalSelection(false));
      dispatch(setAddressMealPlanSelection(false));
    }
  }, []);
  const onPressActiveAddress = (address) => setActiveAddress(address);

  const searchStoreName = async (text) => {
    const { ok, data } = await createRequest(
      searchApi.getSearchResults,
      text,
      '',
      false
    );
    if (ok) {
      dispatch(populatesResultSearched(data.result));
    }
  };
  const toggleSearchContainer = () => navigation.navigate(routeList.SEARCH);

  const onSelectedSavedAddressAsEdit = (index, item) => {
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'edit',
      selectedAddress: {
        ...item,
        index: item.id,
        latitude: item?.geo_json_point.coordinates[1],
        longitude: item?.geo_json_point.coordinates[0],
      },
    });
  };

  const onAddNewAddress = () =>
    showModalPrompt(MODALPROMPT.addressDetails, {
      status: 'new',
      selectedAddress: {},
    });

  const screenOptions = {
    drawerPosition: 'left',
    header: (props) => (
      <View>
        <Header {...props} onTextChanged={searchStoreName} />

        <View
          dataSet={{ media: ids.topLayoutWithOutCredentials }}
          style={styles.topLayoutWithOutCredentials}
        >
          <View
            dataSet={{ media: ids.searchContainer }}
            style={styles.searchContainer}
          >
            <Input
              placeholder="Search store, dishes, products"
              value={''}
              textStyle={{ ...ThemeStyle.regular }}
              style={styles.searchInput}
              onFocus={toggleSearchContainer}
              onChangeText={() => {}}
            />
          </View>

          <View style={styles.addressandDateFilterContainer}>
            <Button
              testID="dropDownButton"
              onPress={() => setDateVisible(true)}
              plain
            >
              <View style={[ThemeStyle.flexDirectionRowCenterCenter]}>
                <Text category="p1" numberOfLines={1}>
                  {lodashIsUndefined(whenFilter)
                    ? 'ASAP'
                    : dayjs(whenFilter?.value).format(
                        constants.DATE_TIME_FILTER_DISPLAY
                      )}
                </Text>
                <Icon
                  name="chevron-down-outline"
                  fill={ThemeColor.black}
                  pack="eva"
                  style={[
                    styles.rightIcon,
                    { width: iconSize, height: iconSize },
                  ]}
                />
              </View>
            </Button>

            <Popup
              offsetX={xs ? -60 : 0}
              trigger={
                <button style={styles.pop_up_wrapper}>
                  <View
                    style={[
                      ThemeStyle.flexDirectionRowCenterCenter,
                      { minWidth: 100, maxWidth: 200 },
                    ]}
                  >
                    <Text category="p1" numberOfLines={1}>
                      {!lodashIsEmpty(activeAddress)
                        ? activeAddress[0].formatted_address
                        : 'Address'}
                    </Text>
                    <Icon
                      name="chevron-down-outline"
                      fill={ThemeColor.black}
                      pack="eva"
                      style={[
                        styles.rightIcon,
                        { width: iconSize, height: iconSize },
                      ]}
                    />
                  </View>
                </button>
              }
              arrow={false}
              contentStyle={styles.pop_up_address_container}
              position="bottom center"
              nested
            >
              <View
                dataSet={{ media: ids.saving_address_container }}
                style={styles.saving_address_container}
              >
                <AddressSelector
                  addresses={userDetails?.addresses}
                  onSelectedSavedAddress={onPressActiveAddress}
                  onSelectedSavedAddressAsEdit={onSelectedSavedAddressAsEdit}
                  addNewAddress={onAddNewAddress}
                />
              </View>
            </Popup>
          </View>
        </View>

        <DatePicker
          isOpen={dateVisible}
          onClosed={() => setDateVisible(false)}
          onProceed={(date) => {
            route.params?.onConfirm(date);
            setDateVisible(false);
          }}
          value={''}
        />
      </View>
    ),
    sceneContainerStyle: {
      backgroundColor: '#fff',
    },
  };

  const toggle = () =>
    showModalPrompt(MODALPROMPT.prompt, {
      message: `Are you sure you want to logout?`,
      buttons: [
        {
          size: 'small',
          status: 'danger',
          text: 'Yes',
          onPress: confirmLogout,
        },
        { size: 'small', text: 'No' },
      ],
    });

  const confirmLogout = async () => {
    toggle();
    dispatch(logout());
    window.location.href = routeList.HOME_TAB;
  };

  const leftDrawerContent = [
    {
      text: 'Home',
      iconName: 'home-outline',
      value: routeList.HOME_TAB,
    },
    {
      text: 'Orders',
      iconName: 'basket-outline',
      value: routeList.BASKET_TAB,
    },
    {
      text: 'Account',
      iconName: 'people-outline',
      value: routeList.PROFILE,
    },
    {
      text: 'Saved Stores',
      iconName: 'heart-outline',
      value: routeList.SEE_ALL,
      params: {
        title: 'Saved Stores',
        tag: {
          key: 'funnel',
          value: 'favorite',
        },
      },
    },
    {
      text: 'Sign Out',
      iconName: 'exit-outline',
      value: routeList.ACCOUNT_TAB,
      onPressItem: toggle,
    },
  ];

  return (
    <Fragment>
      <LeftDrawer.Navigator
        id="LeftDrawer"
        initialRouteName={routeList.HOME_TAB}
        useLegacyImplementation
        screenOptions={screenOptions}
        drawerContent={(props) => (
          <LeftDrawerContent
            data={
              isLoggedIn
                ? leftDrawerContent
                : [leftDrawerContent.find((item) => item.text === 'Home')]
            }
            {...props}
          />
        )}
      >
        <LeftDrawer.Screen
          name={routeList.HOME_TAB}
          component={HomeNavigator}
          initialParams={{
            loadHomeData: loadHomeData,
          }}
          options={{
            title: 'Home',
            iconName: 'home',
            unmountOnBlur: true,
          }}
        />
        <LeftDrawer.Screen
          name={routeList.BASKET_TAB}
          component={Basket}
          options={{
            title: 'Basket',
            iconName: 'shopping-bag',
            unmountOnBlur: true,
          }}
        />

        <LeftDrawer.Screen
          name={routeList.PROFILE}
          component={Profile}
          options={{
            title: 'Account',
            iconName: 'home',
            unmountOnBlur: true,
          }}
        />

        <LeftDrawer.Screen
          name={routeList.FEED_DESCRIPTION}
          component={FeedDescription}
          options={{
            unmountOnBlur: true,
          }}
        />
        <LeftDrawer.Screen
          name={routeList.SEE_ALL}
          initialParams={{
            title: 'My Favorites',
            key: 'funnel',
            tag: 'favorite',
          }}
          options={{
            title: 'Saved Stores',
            unmountOnBlur: true,
          }}
          component={SeeAll}
        />

        <LeftDrawer.Screen
          name={routeList.STORE}
          options={{
            unmountOnBlur: true,
          }}
          component={Store}
        />
      </LeftDrawer.Navigator>
    </Fragment>
  );
};

export default LeftDrawerScreen;
