import React, { forwardRef, Fragment, useImperativeHandle } from 'react';
import { View } from 'react-native';
import StyleSheet from 'react-native-media-query';

import useFormField from '../Hooks/useFormField';
import ThemeStyle from '../Theme/styles';
import breakpoints from '../Theme/styles/breakpoints';

import FormField from './FormFields/FormField';
import TextInput from './TextInputDebounce';

type Props = {
  disabled?: boolean;
  useFormikContext?: boolean;
  values?: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
  onChange?: (name: string, value: string) => void;
};

const ProfileForm: React.FC<Props> = forwardRef(
  ({ disabled, useFormikContext, values, onChange }: Props, ref) => {
    const { getInputProps, setSubmitButtonRef } = useFormField();
    const InputElement = useFormikContext ? FormField : TextInput;
    const getConditionalProps = (name: string) =>
      useFormikContext
        ? {}
        : {
            value: values?.[name],
            onChange: (value: string) => onChange(name, value),
          };

    useImperativeHandle(ref, () => ({
      setSubmitButtonRef,
    }));

    return (
      <Fragment>
        <View
          style={[
            ThemeStyle.flexDirectionRowCenter,
            ThemeStyle.spacingBottomMedium,
          ]}
        >
          {/* First Name Input */}
          <View style={ThemeStyle.flex1}>
            <InputElement
              direction="column"
              disabled={disabled}
              label="First Name *"
              name="firstName"
              testID="firstName"
              withBorder
              {...getConditionalProps('firstName')}
              {...getInputProps(1)}
            />
          </View>
          {/* Separator */}
          <View style={ThemeStyle.rowSeparator} />
          {/* Last Name Input */}
          <View style={ThemeStyle.flex1}>
            <InputElement
              direction="column"
              disabled={disabled}
              label="Last Name *"
              name="lastName"
              testID="lastName"
              withBorder
              {...getConditionalProps('lastName')}
              {...getInputProps(2)}
            />
          </View>
        </View>

        <View
          dataSet={{ media: ids.inputContainer }}
          style={[styles.inputContainer, ThemeStyle.spacingBottomMedium]}
        >
          {/* Phone Number Input */}
          <View
            dataSet={{ media: ids.inputMobileBottomSeparator }}
            style={[ThemeStyle.flex1, styles.inputMobileBottomSeparator]}
          >
            <InputElement
              direction="column"
              disabled={disabled}
              label="Phone Number *"
              name="phone"
              testID="phone"
              type="phone"
              withBorder
              {...getConditionalProps('phone')}
              {...getInputProps(3, true)}
            />
          </View>
          {/* Separator */}
          <View dataSet={{ media: ids.separator }} style={styles.separator} />
          {/* Email Input */}
          <View style={ThemeStyle.flex1}>
            <InputElement
              direction="column"
              label="Email *"
              name="email"
              testID="email"
              type="email"
              disabled
              withBackground
              {...getConditionalProps('email')}
            />
          </View>
        </View>
      </Fragment>
    );
  }
);

const { ids, styles } = StyleSheet.create({
  inputContainer: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.flexDirectionRowCenter,
  },
  inputMobileBottomSeparator: {
    [`@media ${breakpoints.xs}`]: ThemeStyle.spacingBottomMedium,
  },
  separator: {
    [`@media ${breakpoints.sm}`]: ThemeStyle.rowSeparator,
  },
});

export default ProfileForm;
