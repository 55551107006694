import { createSelector } from '@reduxjs/toolkit';

import { CACHE_KEYS } from './constants';

const dataCache = (state) => state.dataCache;

const categoryCacheSelector = createSelector(
  dataCache,
  (state) => state[CACHE_KEYS.CATEGORIES]
);

export { categoryCacheSelector };
