import { NativeModules, Dimensions, Platform } from 'react-native';

const { StatusBarManager } = NativeModules;
const DEVICE_WIDTH = Dimensions.get('window').width;
const DEVICE_HEIGHT = Dimensions.get('window').height;
const patchRequired = false; // change this if codepush is required the user to update or not
const isIOS = Platform.OS === 'ios';
const isAndroid = Platform.OS === 'android';
const isWeb = Platform.OS === 'web';
const statusBarHeight = StatusBarManager?.HEIGHT || 0; // or just StatusBar.currentHeight for android
const scrollThrottle = 16;
const minuteInterval = 30; // this use for order_time of store and also for schedule time list
const currencySign = '₱';
const lastUsedAddressLength = 3; // this is the count how many last used address to show in checkout page
const DATE_DISPLAY_FORMAT = 'dddd, MMM DD, hh:mm A';
const DATE_DISPLAY_FORMAT2 = 'ddd, MMM. DD, YYYY';
const DATE_ONLY_DISPLAY_FORMAT = 'dddd, MMM DD';
const DATE_TIME_FILTER_DISPLAY = 'MMM D, hh:mm A';
const DBDATE_FORMAT = 'YYYY-MM-DD';
const DBTIME_FORMAT = 'HH:mm';
const TIME_ONLY_DISPLAY_FORMAT = 'hh:mm a';
const TIME_ONLY_DISPLAY_CAPITAL_FORMAT = 'hh:mm A';
const DEFAULT_DISTANCE = 10;
const DISTANCE_MAX = 50;
const DISTANCE_UNIT = 'km';
const LONG_DISTANCE = 25; // 25km
const listTypes = {
  STORE: 'store',
  ITEM: 'item',
};
const DELIVERY_PAYMENT_OPTION = {
  CASH: 'cash',
  NON_CASH: 'non_cash',
};
const PRODUCT_TYPES = [
  {
    label: 'Food',
    value: 'food',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-25.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-24.png',
  },
  {
    label: 'Non Food',
    value: 'non-food',
    active:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-39.png',
    inactive:
      'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/cuisines/pickup_icons2-38.png',
  },
];
const serviceFee = null; // if want to use the service fee change the value to { MIN: number, MAX: number }
const pinUrl = {
  HOME: 'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/pin-home.png',
  RIDER: 'https://cdn-icons-png.flaticon.com/512/2060/2060329.png',
  STORE:
    'https://pickup-marketplace.s3.ap-southeast-1.amazonaws.com/pin-store.png',
  CURRENT_LOCATION:
    'https://cdn4.iconfinder.com/data/icons/location-and-map-gray-set-1/100/Untitled-1-16-512.png',
};
const PICKUP_DEFAULT_TRANSPARENT_STORE =
  'https://pickupph.s3.ap-southeast-1.amazonaws.com/assets/pickup-default.png?cb=2';

const PICKUP_DEFAULT_GREEN_STORE =
  'https://pickupph.s3.ap-southeast-1.amazonaws.com/assets/pickup-default-green.jpg?cb=3';

const ORDER_STATUS = {
  PENDING: 'payment_pending',
  CONVERTED: 'converted',
};
const ORDER_TYPES = {
  DELIVERY: 'delivery',
  PICKUP: 'pickup',
  THIRD_PARTY_PICKUP: 'third_party_pickup',
  CURBSIDE: 'curbside_pickup',
  MEAL_PLAN: 'meal_plan',
};
const ORDER_TYPE_DATA = [
  {
    testID: 'deliveryType',
    value: ORDER_TYPES.DELIVERY,
    label: 'Delivery',
    apiDataKey: 'is_delivery_active',
  },
  {
    testID: 'pickupType',
    value: ORDER_TYPES.PICKUP,
    label: 'Pickup',
    apiDataKey: 'is_pickup_active',
  },
  {
    testID: 'curbsideType',
    value: ORDER_TYPES.CURBSIDE,
    label: 'Curbside',
    apiDataKey: 'is_curbside_active',
  },
  {
    testID: 'mealPlanType',
    value: ORDER_TYPES.MEAL_PLAN,
    label: 'Meal Plan',
    apiDataKey: 'is_meal_plan_active',
  },
];
const ORDER_TYPE_API_KEYS = ORDER_TYPE_DATA.map((e) => e.apiDataKey);

const LABEL_TYPE = [
  { label: 'Home', icon: 'home', pack: 'entypo' },
  { label: 'Work', icon: 'suitcase', pack: 'entypo' },
  { label: 'Partner', icon: 'heart', pack: 'entypo' },
  { label: 'Other', icon: 'plus', pack: 'entypo', showInput: true },
];

// If false, will show coming soon. If true, will show the content
const COMING_SOON_CONTENT = {
  feed: false,
  reservation: false,
};

const CHANNEL_IDS = {
  GENERAL: 'pickup_superapp_general_channel',
  ORDERS: 'pickup_superapp_orders_channel',
  REVIEWS: 'pickup_superapp_reviews_channel',
  PAYMENT: 'pickup_superapp_payments_channel',
};

// Feature flags
const USE_PARENT_CATEGORY = false; // to be removed when parent category is completely implemented

const STORE_CLOSING_OR_CLOSED_ERROR = [
  'Order_date - store is closed',
  'Order_time - store is closed',
];
const ERROR_CONFIG = {
  type: 'danger',
  duration: 2000,
};
const SUCCESS_CONFIG = {
  type: 'success',
  duration: 2000,
};
const ERROR_TYPE = {
  GENERIC: 'GENERIC',
  UNAVAILABLE: 'UNAVAILABLE',
};

const ImageSize = {
  LARGE: 'large',
  MEDIUM: 'medium',
  SMALL: 'small',
};

// limits and duration
const cartTerm = 'basket';
const cartLimit = 3;
const PROMO_DISPLAY_LIMIT = 4;
const ACTIVE_ORDER_LIMIT_WEB = 3;
const CODEPUSH_CHECK_FREQUENCY = 5; // in minutes
const PREP_TIME_ALLOWANCE = 5; // minutes
const STORE_INFO_CACHE_DURATION = 8; // in hours
const STORE_MENU_CACHE_DURATION = 5; // in minutes

export default {
  patchRequired,
  isIOS,
  isAndroid,
  isWeb,
  statusBarHeight,
  scrollThrottle,
  minuteInterval,
  currencySign,
  cartTerm,
  cartLimit,
  ImageSize,
  lastUsedAddressLength,
  DATE_DISPLAY_FORMAT,
  DATE_DISPLAY_FORMAT2,
  DATE_ONLY_DISPLAY_FORMAT,
  DATE_TIME_FILTER_DISPLAY,
  DBDATE_FORMAT,
  DBTIME_FORMAT,
  TIME_ONLY_DISPLAY_FORMAT,
  TIME_ONLY_DISPLAY_CAPITAL_FORMAT,
  PREP_TIME_ALLOWANCE,
  DELIVERY_PAYMENT_OPTION,
  PRODUCT_TYPES,
  listTypes,
  serviceFee,
  pinUrl,
  DEVICE_WIDTH,
  DEVICE_HEIGHT,
  PICKUP_DEFAULT_TRANSPARENT_STORE,
  PICKUP_DEFAULT_GREEN_STORE,
  ORDER_STATUS,
  ORDER_TYPES,
  ORDER_TYPE_DATA,
  ORDER_TYPE_API_KEYS,
  DEFAULT_DISTANCE,
  DISTANCE_MAX,
  DISTANCE_UNIT,
  LONG_DISTANCE,
  LABEL_TYPE,
  COMING_SOON_CONTENT,
  USE_PARENT_CATEGORY,
  CHANNEL_IDS,
  ERROR_TYPE,
  ERROR_CONFIG,
  SUCCESS_CONFIG,
  CODEPUSH_CHECK_FREQUENCY,
  STORE_INFO_CACHE_DURATION,
  STORE_MENU_CACHE_DURATION,
  STORE_CLOSING_OR_CLOSED_ERROR,
  PROMO_DISPLAY_LIMIT,
  ACTIVE_ORDER_LIMIT_WEB,
};
