import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { View } from 'react-native';
import { Text } from '@ui-kitten/components';
import { useNavigation } from '@react-navigation/native';
import { useQuery } from '@tanstack/react-query';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';
import lodashFind from 'lodash/find';

import StoreCard from '../../Components/Card/Store';
import StoreCardLoader from '../../Components/Card/StoreLoader';
import HorizontalListing from '../../Components/HorizontalListing';
import HorizontalListingHeading from '../../Components/HorizontalListing/Heading';
import Button from '../../Components/Button';
import constants from '../../Config/constants';
import AnalyticsHelper, { CUSTOM_EVENTS } from '../../Helper/Analytics';
import useFavorite from '../../Hooks/useFavorite';
import {
  distanceFilterSelector,
  parentCategorySelector,
  whenFilterSelector,
} from '../../RTK/filter/selectors';
import { addressSelector } from '../../RTK/user/selectors';
import routeList from '../../Routes/list';
import storeApi from '../../Service/api/store';
import ThemeStyle, { globalSpacing } from '../../Theme/styles';

function HorizontalListingService(props) {
  const isMounted = useRef();
  const prevWhenFilter = useRef();
  const navigation = useNavigation();
  const { disableFavourite, isSavedToFavourite, toggleFavourite } =
    useFavorite();
  const distanceFilter = useSelector(distanceFilterSelector);
  const parentCategory = useSelector(parentCategorySelector);
  const whenFilter = useSelector(whenFilterSelector);
  const userAddress = useSelector(addressSelector);

  const activeAddress = lodashFind(userAddress, { active: true });

  const { refreshing, tag, title } = props;
  const distanceParam = distanceFilter?.paramName;
  const distance = distanceFilter?.values?.[0]?.value;

  const {
    data: result,
    isFetching,
    isError,
    refetch,
  } = useQuery({
    queryKey: [
      distance,
      tag.value,
      whenFilter?.date,
      whenFilter?.time,
      activeAddress?.id,
    ],
    queryFn: () => {
      const params = [
        { key: 'open_only', value: true },
        { key: 'product_type', value: parentCategory?.value },
        ...(!lodashIsEmpty(distanceFilter) // when set/change the distance of filter on homepage
          ? [{ key: distanceParam, value: distance }]
          : []),
        ...(!lodashIsEmpty(whenFilter) // when selected date filter on homepage
          ? [
              { key: 'order_date', value: whenFilter.date },
              { key: 'order_time', value: whenFilter.time },
            ]
          : []),
        tag, // from section.js, must be an object
      ];
      return storeApi.getTenStores(params);
    },
    refetchOnWindowFocus: false,
  });

  const list = result?.ok ? result.data.result : [];

  const listLoader = [
    { loading: true },
    { loading: true },
    { loading: true },
    ...(constants.isWeb ? [{ loading: true }, { loading: true }] : []), // on web, 5 loaders
  ];
  const data = isFetching ? listLoader : list;
  const hasSeeMore = list.length >= 10;

  useEffect(() => {
    if (
      isMounted.current &&
      (refreshing || !lodashIsEqual(prevWhenFilter.current, whenFilter))
    ) {
      prevWhenFilter.current = whenFilter;
      refetch();
    }
  }, [refreshing, whenFilter]);

  useEffect(() => {
    isMounted.current = true;
    prevWhenFilter.current = whenFilter;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _onSeeMore = () => {
    AnalyticsHelper.customEvent(CUSTOM_EVENTS.sectionSeeAll, { title });
    navigation.navigate(routeList.SEE_ALL, {
      title,
      key: tag.key,
      tag: tag.value,
    });
  };

  const _onPress = (store) => () => {
    AnalyticsHelper.storeSelection({
      ...store,
      fromSection: title,
    });
    navigation.navigate(routeList.STORE, {
      id: store.id,
      distance: store.distance,
      duration: store.duration,
    });
  };

  const _renderItem = ({ isFirstOfList, isLastOfList, item }) => {
    const firstAndLastMargin = constants.isWeb ? 0 : globalSpacing;
    return (
      <View
        style={{
          marginLeft: isFirstOfList ? firstAndLastMargin : globalSpacing / 4,
          marginRight: isLastOfList ? firstAndLastMargin : globalSpacing / 4,
        }}
      >
        {item.loading ? (
          <StoreCardLoader />
        ) : (
          <StoreCard
            {...item}
            isSaved={isSavedToFavourite(item.id)}
            disableSave={disableFavourite}
            onSave={toggleFavourite(item)}
            onPress={_onPress(item)}
          />
        )}
      </View>
    );
  };

  // if not loading and has error or data length is 0 (in english, if the section does not have data to display just hide it)
  if (!isFetching && (isError || list.length === 0)) {
    return null;
  }

  return (
    <View>
      <View style={ThemeStyle.pageVerticalSpacingMedium}>
        <HorizontalListing
          leftContent={
            <HorizontalListingHeading
              title={title}
              noHPadding={constants.isWeb}
              onPress={!constants.isWeb && hasSeeMore ? _onSeeMore : undefined}
            />
          }
          rightContent={
            constants.isWeb &&
            hasSeeMore && (
              <Button onPress={_onSeeMore} plain>
                <Text category="s2" status="basic">
                  See All
                </Text>
              </Button>
            )
          }
          data={data}
          renderItem={_renderItem}
        />
      </View>
      <View style={ThemeStyle.sectionSeparator} />
    </View>
  );
}

HorizontalListingService.propTypes = {
  refreshing: PropTypes.bool,
  size: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  title: PropTypes.string.isRequired,
};

export default HorizontalListingService;
