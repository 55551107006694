import { useEffect, useRef, useState } from 'react';
import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import {
  StackActions,
  useNavigation,
  useRoute,
} from '@react-navigation/native';
import lodashIsEmpty from 'lodash/isEmpty';

import constants from '../Config/constants';
import messages from '../Config/messages';
import { isUserDetailsIncomplete } from '../Helper';
import { setGettingUserData } from '../RTK/utility';
import { logout, toggleRequiringCompletingInformation } from '../RTK/user';
import routeList from '../Routes/list';
import Service from '../Service';

import useShouldCallApi from './useShouldCallApi';

import useSetSpecificUserData from './useSetSpecificUserData';

import useModalPrompt from '../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../Components/Web/Modal/ModalPrompt/config';
import { setEmailReviewDeepLinking } from '../RTK/noSession';

export default function () {
  const alreadyShowned = useRef();
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const route = useRoute();
  const shouldCallApi = useShouldCallApi();
  const { showModalPrompt } = useModalPrompt();
  const { setCartData, setUserAddresses, setUserDetails } =
    useSetSpecificUserData();
  const isMounted = useRef();
  const user = useSelector((state) => state.user?.user);
  const emailReviewDeepLinking = useSelector(
    (state) => state.noSession.emailReviewDeepLinking
  );
  const rememberEmail = useSelector((state) => state.noSession?.rememberEmail);
  const [done, setDone] = useState(false);
  const [isSetUserDataDone, setDoneSettingUserData] = useState(false);

  useEffect(() => {
    if (!done) {
      _setUserData();
    }
  }, []);

  useEffect(() => {
    // if user doesn't have address yet, it will redirect the screen to address adding
    // then when they update their address this will be called and done will become true
    if (isSetUserDataDone && user?.addresses?.length !== 0) {
      setDone(true);
    }
  }, [user?.addresses]);

  // hooks for showing complete profile prompt
  useEffect(() => {
    if (
      done && // if process is done
      !alreadyShowned.current && // if not yet showned
      !lodashIsEmpty(user) && // has user object
      isUserDetailsIncomplete(user) // if profile is incomplete
    ) {
      _showCompleteProfilePrompt();
    }
  }, [done, user]);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const _showCompleteProfilePrompt = async () => {
    alreadyShowned.current = true; // to prevent multiple open when updating user address
    await new Promise((resolve) => setTimeout(resolve, 1500));

    if (constants.isWeb) {
      showModalPrompt(MODALPROMPT.completeInformation, {
        navigation: navigation,
      });
      return;
    }
    Alert.alert(
      'Complete your profile',
      'Would you like to complete your profile now?',
      [
        {
          text: 'Yes',
          onPress: () => {
            navigation.navigate(routeList.ACCOUNT_TAB);
            navigation.navigate(routeList.PROFILE);
          },
        },
        { text: 'Not now', style: 'cancel' },
      ]
    );
  };

  const _setUserData = async () => {
    /* If user click the email review it will open the app if it's already installed
     ** If the user is not yet login
     ** This will be call after succesfully login
     */
    if (!lodashIsEmpty(emailReviewDeepLinking) && !constants.isWeb) {
      dispatch(setEmailReviewDeepLinking());
      navigation.navigate(routeList.BASKET_TAB, {
        screen: routeList.ORDER_RATING,
        params: emailReviewDeepLinking,
      });
    }
    if (!shouldCallApi && user?.addresses.length === 0) {
      // user is guest/social and no saved address yet, redirect to address selection
      console.log(
        'Login as guest/social',
        'No address: redirect on address adding screen'
      );
      navigation.navigate(routeList.ADDRESS_SELECTOR);
      navigation.navigate(routeList.ADDRESS_ADD_EDIT);
    } else if (shouldCallApi) {
      // adding the loader flag for getting user details, addresses and carts
      await dispatch(setGettingUserData({ value: { loading: true } }));

      const detailsResponse = await setUserDetails();
      const addressesResponse = await setUserAddresses();
      const cartResponse = await setCartData();

      const responseStatuses = [
        detailsResponse?.status,
        addressesResponse?.status,
        cartResponse?.status,
      ];
      if (responseStatuses.includes(401) || responseStatuses.includes(403)) {
        const alert = messages.SESSION_EXPIRED;
        Alert.alert(alert.title, alert.message, [
          {
            text: 'ok',
            onPress: async () => {
              const phoneNumber = user?.phone || ''; // store phone number, because when logout it will be removed on redux state
              await dispatch(logout()); // then logout
              if (!constants.isWeb) {
                navigation.navigate(routeList.AUTH, {
                  phone_number: phoneNumber.replace('+63', ''), // with existing phone number (replace +63 since we dont need it on the otp screen)
                  remember: rememberEmail, // and existing remember flag
                  session_expired: true, // also add session_expired flag
                });
              }
            },
          },
        ]);
      } else if (
        detailsResponse?.error ||
        addressesResponse?.error ||
        cartResponse?.error
      ) {
        // if any of the api call above encounter any error
        const errors = [
          detailsResponse?.error,
          addressesResponse?.error,
          cartResponse?.error,
        ];
        Alert.alert(
          'Error trying to get user data.\nPlease try again',
          Service.handleErrorMessage(errors)
        );
      }
      // if noAddress is returned on setUserAddresses it means use doesn't have they first address yet
      if (addressesResponse?.noAddress) {
        if (!constants.isWeb) {
          // so lets redirect it to address adding screen
          navigation.navigate(routeList.ADDRESS_SELECTOR);
          navigation.navigate(routeList.ADDRESS_ADD_EDIT);
        } else {
          showModalPrompt(MODALPROMPT.addressDetails, {
            status: 'new',
            selectedAddress: {},
            navigation: navigation,
            route: route,
          });
        }
      } else if (isMounted.current) {
        // if no error occur and noAddress is not returned from setUserAddresses setDone as true
        setDone(true);
      }
      await dispatch(setGettingUserData({ value: { loading: false } })); // removing the loader flag for getting user details
    }

    // flag for done processing the setting of user data
    if (isMounted.current) {
      setDoneSettingUserData(true);
    }
  };

  return done;
}
