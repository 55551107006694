import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import lodashGet from 'lodash/get';

import { removeCart } from '../RTK/cart';
import { storeCartSelector } from '../RTK/cart/selectors';
import { removeCheckoutDetails } from '../RTK/checkout';
import { storeCheckoutSelector } from '../RTK/checkout/selectors';
import { reset } from '../RTK/mealPlan';
import { addSuccessfulOrder } from '../RTK/utility';

export default function (Component) {
  return (props) => {
    const store_id = lodashGet(props, 'route.params.storeId');

    const dispatch = useDispatch();
    const checkoutData = useSelector((state) =>
      storeCheckoutSelector(state, store_id)
    );
    const storeCartData = useSelector((state) =>
      storeCartSelector(state, store_id)
    );

    const clearCartAndCheckoutData = async () => {
      dispatch(addSuccessfulOrder(store_id));
      dispatch(removeCart(store_id)); // clear store cart data
      dispatch(removeCheckoutDetails(store_id)); // clear store checkout details
    };

    const clearMealPlanAndCheckoutData = () => {
      dispatch(reset());
      dispatch(removeCheckoutDetails(store_id));
    };

    return (
      <Component
        {...props}
        storeCheckoutData={checkoutData}
        storeCartData={storeCartData}
        clearCartAndCheckoutData={clearCartAndCheckoutData}
        clearMealPlanAndCheckoutData={clearMealPlanAndCheckoutData}
      />
    );
  };
}
