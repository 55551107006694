import { useEffect, useRef, useState, memo } from 'react';
import { Alert } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { CANCEL_ERROR, CancelToken } from 'apisauce';
import lodashGet from 'lodash/get';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';

import validation from '../../../Config/validationRules';
import Sentry from '../../../Helper/Sentry';
import useCancellableRequest from '../../../Hooks/useCancellableRequest';
import useShouldCallApi from '../../../Hooks/useShouldCallApi';
import Service from '../../../Service';
import {
  saveUserAddress,
  saveUserAsGuest,
  updateUserAddress,
} from '../../../RTK/user';
import {
  addressSelector,
  isLoggedInSelector,
  userSelector,
} from '../../../RTK/user/selectors';
import { updateSelectedAddress } from '../../../RTK/reservation';
import userApi from '../../../Service/api/user';
import otherApi from '../../../Service/api/other';

import savedAddressesController from './savedAddresses';

import useCommonHooks from './useCommonHooks';

import constants from '../../../Config/constants';
import useModalPrompt from '../../../Components/Web/Modal/ModalPrompt/hooks/useModalPrompt';
import { MODALPROMPT } from '../../../Components/Web/Modal/ModalPrompt/config';

function useController(props) {
  const dispatch = useDispatch();

  const formRef = useRef();
  const autoCompleteRef = useRef();
  const locationInfoRef = useRef();
  const mapRef = useRef();
  const firstReverseGeocodeData = useRef();

  const { hideModalPrompt, isOpenModalPrompt } = useModalPrompt();
  const { getPrefixLabel, getUserLocation } = useCommonHooks();
  const { createRequest } = useCancellableRequest();
  const { onSavedAddressPressed } = savedAddressesController(props);

  const shouldCallApi = useShouldCallApi();
  const isLoggedIn = useSelector(isLoggedInSelector);
  const user = useSelector(userSelector);
  const userAddresses = useSelector(addressSelector);

  const [existingError, setExisistingError] = useState('');
  const { navigation, route } = props;

  const userLocation = lodashGet(route, 'params.userLocation');
  const useUserLocation = !lodashIsEmpty(userLocation);
  const updateData = constants.isWeb
    ? lodashGet(props, 'selectedAddress')
    : lodashGet(route, 'params.update');
  const isFromReservationGuest =
    !isLoggedIn && lodashGet(route, 'params.fromReservation');
  const isUpdate = !lodashIsEmpty(updateData);
  const defaultSelectedAddress = {};
  const labelValidation = validation.createSchema({
    label: validation.fieldRequired('Address label'),
  });
  const formInitialValues = {
    label: updateData?.label,
    labelSelection: updateData?.label ? getPrefixLabel(updateData.label) : null,
  };
  if (isUpdate) {
    // if update, set default selected address to it
    // mapping below should not throw error, because the saved address data is design this way
    defaultSelectedAddress.place_id = updateData.place_id;
    defaultSelectedAddress.address = updateData.formatted_address;
    defaultSelectedAddress.latitude = updateData.latitude;
    defaultSelectedAddress.longitude = updateData.longitude;
  } else if (useUserLocation) {
    // if user click the use my current location on addresses page
    defaultSelectedAddress.place_id = userLocation.place_id;
    defaultSelectedAddress.address = userLocation.address;
    defaultSelectedAddress.latitude = userLocation.latitude;
    defaultSelectedAddress.longitude = userLocation.longitude;
  }

  // expected value { place_id: string, address: string, latitude: number, longitude: number }
  const [selectedAddress, setSelectedAddress] = useState(
    defaultSelectedAddress
  );
  const [selectedLabel, setSelectedLabel] = useState(null); // for selected label
  const [submitting, setSubmitting] = useState(false); // for loader

  useEffect(() => {
    if (isUpdate || useUserLocation) {
      if (constants.isWeb) {
        setSelectedAddress(defaultSelectedAddress);
      }
      _onRegionChangeComplete(defaultSelectedAddress, { isGesture: true });
    }
  }, [updateData]);

  useEffect(() => {
    if (isUpdate && updateData.label) {
      // if update
      const prefixLabel = getPrefixLabel(updateData.label);
      if (!lodashIsEmpty(prefixLabel)) {
        // if has prefix label, update state
        setSelectedLabel(prefixLabel);
      }
    }
  }, []);

  const _setCurrentLocation = () => {
    getUserLocation((res) => {
      const result = {
        place_id: res.userLocation.place_id,
        address: res.userLocation.address,
        latitude: res.userLocation.latitude,
        longitude: res.userLocation.longitude,
      };
      setSelectedAddress(result);
      _onRegionChangeComplete(result, { isGesture: true });
    });
  };

  const _askForUserLocation = () => {
    if (lodashIsEmpty(user?.addresses)) {
      Alert.alert(
        'User Location',
        'Would you like to use your current location to display on the map?',
        [{ text: 'Yes', onPress: _setCurrentLocation }, { text: 'No' }]
      );
    }
  };

  const _onLabelSelect = (data) => {
    const labelName = 'label';
    formRef.current.setFieldTouched(labelName);
    formRef.current.setFieldValue(labelName, data.showInput ? '' : data.label);
    setSelectedLabel(data);
  };

  const _getReverseGeocodeData = (data, { latitude, longitude }) => {
    let result = lodashFind(data, (e) => {
      return (
        latitude.toString().includes(e.geometry.location.lat) &&
        longitude.toString().includes(e.geometry.location.lng)
      );
    });
    if (lodashIsEmpty(result)) {
      // not found the region latitude and longitude, use the first data
      result = data[0];
    }
    // return with modified latitude and longitude to user selected on map
    return {
      ...result,
      geometry: {
        ...result.geometry,
        location: {
          lat: latitude,
          lng: longitude,
        },
      },
    };
  };

  const _onRegionChangeComplete = async (
    { place_id, latitude, longitude, address },
    { isGesture }
  ) => {
    if (isGesture) {
      if (!lodashIsEmpty(address)) {
        firstReverseGeocodeData.current = {
          place_id,
          geometry: {
            location: {
              lat: latitude,
              lng: longitude,
            },
          },
          formatted_address: address,
        };
      } else {
        locationInfoRef.current?.cancel?.(); // cancel previous request
        const source = CancelToken.source();
        locationInfoRef.current = source; // store new request token
        const { ok, data, problem } = await otherApi.googleMapReverseGeocode(
          { latitude, longitude },
          source.token
        );
        if (problem !== CANCEL_ERROR && ok) {
          const result = _getReverseGeocodeData(data.results, {
            latitude,
            longitude,
          });
          firstReverseGeocodeData.current = result;

          autoCompleteRef?.current?._setDefaultSearchValue?.(
            result.formatted_address
          );
        }
      }
    }
  };

  // for handling the press of search item result
  const _onSearchItemPressed = (param1, param2) => {
    const location_string = param2.formatted_address.includes(param2.name)
      ? param2.formatted_address
      : `${param2.name} ${param2.formatted_address}`;

    let result = {
      place_id: param1.place_id,
      address: location_string,
      latitude: lodashGet(param2, 'geometry.location.lat'),
      longitude: lodashGet(param2, 'geometry.location.lng'),
    };

    setSelectedAddress(result);
    _onRegionChangeComplete(result, { isGesture: true });
    mapRef?.current?._animateToRegion?.();
  };

  // when save address pressed
  const _onSubmit = async (updatedLatLng, formData) => {
    if (!isLoggedIn) {
      // for guest user
      const { ok, problem } = await createRequest(userApi.signInAsGuest, {
        place_id: selectedAddress.place_id,
        address: selectedAddress.address,
      });
      let addressObject = {};
      const firstRgData = firstReverseGeocodeData.current;

      if (ok) {
        if (isUpdate) {
          addressObject = {
            index: updateData.index,
            label: formData.label,
            active: updateData?.active,
            place_id: firstRgData.place_id,
            formatted_address: firstRgData.formatted_address,
            geo_json_point: {
              type: 'Point',
              coordinates: [
                firstRgData.geometry.location.lng,
                firstRgData.geometry.location.lat,
              ],
            },
          };
          // update user address on redux
          await dispatch(updateUserAddress(addressObject));
          setSubmitting(false);
        } else {
          addressObject = {
            label: formData.label,
            place_id: firstRgData.place_id,
            formatted_address: firstRgData.formatted_address,
            geo_json_point: {
              type: 'Point',
              coordinates: [
                firstRgData.geometry.location.lng,
                firstRgData.geometry.location.lat,
              ],
            },
          };
          dispatch(saveUserAsGuest());
          dispatch(saveUserAddress(addressObject));
          setSubmitting(false);
        }
      } else {
        alert(problem);
        setSubmitting(false);
      }

      hideModalPrompt(MODALPROMPT.addressDetails);

      return;
    }
    setExisistingError('');
    const firstRgData = firstReverseGeocodeData.current;
    const isExisting = !lodashIsEmpty(
      lodashFilter(userAddresses, {
        place_id: firstRgData.place_id,
      })
    );
    setSubmitting(true); // add loader
    setSelectedAddress({
      place_id: firstRgData.place_id,
      address: firstRgData.formatted_address,
      latitude: firstRgData.geometry.location.lat,
      longitude: firstRgData.geometry.location.lng,
    });
    // if from reservation guest
    if (isFromReservationGuest) {
      setSubmitting(false); // remove loader flag
      const addressToSave = {
        geo_json_point: {
          type: 'Point',
          coordinates: [
            firstRgData.geometry.location.lat,
            firstRgData.geometry.location.lng,
          ],
        },
        active: false,
        place_id: firstRgData.place_id,
      };
      await dispatch(updateSelectedAddress(addressToSave));
      if (constants.isWeb) {
        return true;
      }

      navigation.goBack();
      return;
    }
    if (!isUpdate && isExisting) {
      setSubmitting(false);
      setExisistingError(
        'Address already saved, Please select another location.'
      );
      return;
    }
    // proceed with the flow
    let addressObject = {};
    if (shouldCallApi) {
      const payload = {
        place_id: firstRgData.place_id,
        label: formData.label,
        address: firstRgData.formatted_address,
      };
      const apiMethod = isUpdate ? userApi.updateAddress : userApi.saveAddress;
      const { ok, data, status, problem } = isUpdate
        ? await apiMethod(updateData.id, payload) // will not gonna add cancellable api here it's too risky, read the comment on userApi.updateAddress
        : await createRequest(apiMethod, payload);
      // stop the code from going if request is cancelled
      if (problem === CANCEL_ERROR) {
        return;
      }
      if (ok) {
        addressObject = {
          ...data,
          // if update and is active, automatically set as active false to set is as active on onSavedAddressPressed function
          active: updateData?.active ? false : data.active,
          formatted_address: firstRgData.formatted_address,
        };
      } else {
        Sentry.reportError(
          `Error ${isUpdate ? 'updating' : 'adding'} user address`,
          data
        );
        if (status === 400) {
          const formError = Service.handleFormError(data.message);
          Alert.alert(
            'Validation error',
            Service.handleErrorMessage(formError)
          );
        } else {
          Alert.alert(
            `Cannot ${isUpdate ? 'update' : 'save'} address`,
            Service.handleErrorMessage(data.message)
          );
        }

        return;
      }
    } else {
      addressObject = {
        id: firstRgData.place_id, // used as unique identifier for local data purpose
        ...firstRgData,
      };
    }
    if (isUpdate) {
      // update user address on redux
      await dispatch(
        updateUserAddress({
          index: updateData.index,
          ...addressObject,
        })
      );
    } else {
      // add user address on redux
      await dispatch(saveUserAddress(addressObject));
    }
    if (updateData?.active) {
      // if the address editting is an active address, set it as default again
      // go back is inside onSavedAddressPressed function
      // no need to go back if editting address is active, because onSavedAddressPressed has goBack function
      await onSavedAddressPressed(addressObject, () => {
        setSubmitting(false); // remove loader flag
      });
    } else {
      setSubmitting(false); // remove loader flag
      !constants.isWeb && navigation.goBack();
    }

    if (constants.isWeb && isOpenModalPrompt(MODALPROMPT.addressDetails)) {
      hideModalPrompt(MODALPROMPT.addressDetails, false);
    }
  };

  return {
    state: {
      existingError,
      selectedAddress,
      selectedLabel,
      submitting,
    },
    autoCompleteRef,
    formInitialValues,
    formRef,
    isFromReservationGuest, // if from reservation guest, hide address label
    isUpdate,
    mapRef,
    pageTitle: `${
      isFromReservationGuest ? 'Search' : isUpdate ? 'Edit' : 'Add'
    } address`,
    validationSchema: isFromReservationGuest ? undefined : labelValidation, // no label validation if from reservation guest
    askForUserLocation: _askForUserLocation,
    onLabelSelect: _onLabelSelect,
    onRegionChangeComplete: _onRegionChangeComplete,
    onSearchItemPressed: _onSearchItemPressed,
    onSubmit: _onSubmit,
    setSelectedAddress,
  };
}

export default useController;
