import { StyleSheet } from 'react-native';
import constants from '../../Config/constants';
import ThemeStyle from '../../Theme/styles';

export default StyleSheet.create({
  wrapper: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacingMedium,
    ThemeStyle.pageHorizontalSpacing,
    constants.isWeb && ThemeStyle.spacingTop,
  ],
  wrapperBig: [
    ThemeStyle.flex1,
    ThemeStyle.pageVerticalSpacing,
    ThemeStyle.pageHorizontalSpacing,
  ],
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
