import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { CANCEL_ERROR } from 'apisauce';
import lodashFilter from 'lodash/filter';
import lodashFind from 'lodash/find';
import lodashIsEmpty from 'lodash/isEmpty';
import lodashIsEqual from 'lodash/isEqual';

import constants from '../../../../Config/constants';
import AnalyticsHelper from '../../../../Helper/Analytics';
import CheckoutHelper from '../../../../Helper/Checkout';
import Sentry from '../../../../Helper/Sentry';
import StoreHelper from '../../../../Helper/Store';
import UrlParameter from '../../../../Helper/UrlParameter';
import useCancellableRequest from '../../../../Hooks/useCancellableRequest';
import routeList from '../../../../Routes/list';
import storeApi from '../../../../Service/api/store';

import getHomeSections from '../../sections';
import { isLoggedInSelector } from '../../../../RTK/user/selectors';

let lastScrollY = 0;
let isScrollDragging = false;
let onEndReachedCalledDuringMomentum = false;

const useHomeController = () => {
  const navigation = useNavigation();
  const { createRequest } = useCancellableRequest();
  const prevHomeFilter = useRef();
  const prevMainCategoryFilter = useRef();
  const prevUserAddress = useRef();

  const isLoggedIn = useSelector(isLoggedInSelector);
  const userFavorites = useSelector((state) => state.favourite?.favouriteData);
  const { home, parentCategory } = useSelector((state) => state.filter);
  const userAddresses = useSelector(
    (state) => state.user?.user?.addresses || []
  );
  const { address, cart, details } = useSelector(
    (state) => state.utility.gettingUserData
  );
  const loadHomeData =
    constants.isWeb && !isLoggedIn
      ? true
      : !address.loading && !cart.loading && !details.loading;

  const [filterLoading, setFilterLoading] = useState(false); // loader for filter
  const [pageLoading, setPageLoading] = useState(true); // loader for page loading
  const [isGettingAllStore, setGettingAllStore] = useState(true); // loader for all store fetching
  const [homeSegments, setHomeSegments] = useState([]); // homepage sections
  const [allStorePage, setAllStorePage] = useState(1); // all store pagination
  const [allStoreEnd, setAllStoreEnd] = useState(false); // all store flag for page paginated page
  const [homeAllStores, setHomeAllStores] = useState([]); // all store data
  const [totalResults, setTotalResults] = useState(0); // filter results count
  const [refreshing, setRefreshing] = useState(); // pull to refresh
  const [isScrollingUp, setIsScrollingUp] = useState(true); // show search bar if scrolling up

  useEffect(() => {
    prevMainCategoryFilter.current = parentCategory;
    prevHomeFilter.current = home;
    prevUserAddress.current = userAddresses;
  }, [parentCategory, home, userAddresses]);

  // called when page update (e,g when user data has been initialized, when date filter changed and when scroll to top)
  const _onPageUpdate = (scrollUp, listReference) => {
    const prevParentCategory = prevMainCategoryFilter.current;
    const prevHome = prevHomeFilter.current;
    const prevAddress = prevUserAddress.current;
    //console.log(prevHome?.category, home.category)
    // load homepage data if page is loading and user data has been initialize (loadHomeData=true)
    const shouldLoadHomepage = pageLoading && loadHomeData;
    const isWhenFilterChanged = !lodashIsEqual(prevHome?.when, home.when); // if homepage date filter is changed
    // when user changed default selected address
    const isAddressChanged = !lodashIsEqual(
      lodashFind(prevAddress, { active: true }), // get active on previous address
      lodashFind(userAddresses, { active: true }) // get active on current address
    );
    if (shouldLoadHomepage || isWhenFilterChanged || isAddressChanged) {
      _getHomepageData(isAddressChanged);
    } else if (
      !lodashIsEqual(prevParentCategory, parentCategory) || // if parent category is changed
      !lodashIsEqual(prevHome?.category, home.category) || // or category filter is changed (the asian, breakfast etc.)
      !lodashIsEqual(prevHome?.tags, home.tags) // or tag filter is changed (the order type, ratings etc.)
    ) {
      _handleFilterChange();
    } else if (
      (constants.isWeb && home.category.length > 0) ||
      (constants.isWeb && home.tags.length > 0)
    ) {
      _handleFilterChange();
    } else if (scrollUp) {
      setTimeout(() => {
        listReference?._scrollToOffset?.({
          animated: true,
          offset: 0,
        });
      }, 100);
      navigation.setParams({ scrollToTop: false });
    }
  };

  // called on _onPageUpdate when filter changed
  const _handleFilterChange = () => {
    setFilterLoading(true);
    setGettingAllStore(true);
    setHomeAllStores([
      { loader: true },
      { loader: true },
      { loader: true },
      { loader: true },
      { loader: true },
      { loader: true },
    ]);
    _fetchAllStoreNextData();
  };

  const _getHomepageData = (setAsRefreshing) => {
    const sections = getHomeSections(lodashIsEmpty(userFavorites));
    if (setAsRefreshing) {
      setRefreshing(true);
    }
    setFilterLoading(false);
    setPageLoading(false);
    setHomeSegments(sections);
    setAllStoreEnd(false);
    setHomeAllStores([]);
    setTotalResults(0);
    setGettingAllStore(true);
    _fetchAllStoreNextData();
  };

  const _fetchAllStoreNextData = async (page = 1) => {
    const { ok, data, problem } = await _getAllStoresData(page);
    // stop the code from going if request is cancelled
    if (problem === CANCEL_ERROR) {
      return;
    }
    if (ok) {
      const { result, has_next_page, total_pages } = data;
      let totalResults = UrlParameter.page_limit * total_pages;
      if (result.length <= 10 && total_pages === 1) {
        totalResults = result.length;
      }
      setFilterLoading(false);
      setAllStorePage(page);
      setAllStoreEnd(!has_next_page);
      setHomeAllStores(page === 1 ? result : [...homeAllStores, ...result]);
      setTotalResults(totalResults);
      setRefreshing(false); // for _onRefresh method (no effect if not pull to refresh)
      setGettingAllStore(false);
    } else {
      setFilterLoading(false);
      setAllStorePage(1);
      setAllStoreEnd(true);
      setHomeAllStores([]);
      setTotalResults(0);
      setRefreshing(false); // for _onRefresh method (no effect if not pull to refresh)
      setGettingAllStore(false);
      Sentry.reportError('Error getting all store data', data);
    }
  };

  const _getAllStoresData = async (page) => {
    const urlParams = UrlParameter.getUrlParameterFromFilter(
      parentCategory,
      home.tags,
      home.category,
      home.when,
      true
    );
    const urlParamsWithPageNumber = `?${UrlParameter.getLimitAndOffset(
      page
    )}${urlParams}`;
    return await createRequest(storeApi.getAllStores, urlParamsWithPageNumber);
  };

  const _onSearchPressed = () => {
    navigation.navigate(routeList.SEARCH);
  };

  // prettier-ignore
  const _onCardPressed = (item, fromSection = 'All Store') => () => {
    AnalyticsHelper.storeSelection({...item, fromSection})
    navigation.navigate(routeList.STORE, { id: item.id, distance: item.distance, orderForLater: item.orderForLater })
  }

  const _onRefresh = () => {
    // you should not pull to refresh if page still loading & already refreshing
    if (!pageLoading && !refreshing) {
      // we don't need to set the pageLoading to true
      setHomeSegments(getHomeSections(lodashIsEmpty(userFavorites)));
      // reset states
      setFilterLoading(false);
      setAllStoreEnd(false);
      setHomeAllStores([]);
      setTotalResults(0);
      setRefreshing(true);
      setGettingAllStore(true);
      // call function
      _fetchAllStoreNextData();
    }
  };

  const _onReachedEnd = async () => {
    if (!onEndReachedCalledDuringMomentum && !allStoreEnd) {
      console.log('end reached');
      _fetchAllStoreNextData(allStorePage + 1);
      onEndReachedCalledDuringMomentum = true;
    }
  };

  const _onMomentumScrollBegin = () => {
    onEndReachedCalledDuringMomentum = false;
  };

  // specific for web functions
  const _isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingBottom = 20;
    const shouldLoadHomepage = pageLoading && loadHomeData;
    if (
      layoutMeasurement.width + contentOffset.x >=
        contentSize.width - paddingBottom &&
      !allStoreEnd
    ) {
      if (shouldLoadHomepage) {
        _fetchAllStoreNextData(allStorePage + 1);
      }
    }
  };

  // for filter of the store to show in 'All Store' and 'Order for later' section
  const _isStoreOpen = (returnOpen) => (store) => {
    // if loader
    if (returnOpen && store.loader) {
      return true;
    } else if (store.loader) {
      return false;
    }
    // loading done, process the store if its open or not
    const { isOpen, nextSchedule } = StoreHelper.getStoreAvailability(
      store?.store_hours,
      store?.off_dates,
      'object',
      home.when?.value // to adjust on the date filter not just current date and time
    );
    const { isClosing } = CheckoutHelper.isCanCheckout(
      store?.store_hours,
      store?.off_dates,
      store?.pre_order_to_order_queue_timer,
      home.when?.value
    );
    if (returnOpen) {
      // return if store is open AND if closing, store should be accepting order in advance else, just true
      return (
        isOpen && (isClosing ? store.is_accepting_in_advanced_orders : true)
      );
    } else {
      // return if not open and store is accepting advance order and nextSchedule is empty
      return (
        !isOpen &&
        store.is_accepting_in_advanced_orders &&
        lodashIsEmpty(nextSchedule)
      );
    }
  };

  const _onStartDragging = () => {
    isScrollDragging = true;
  };

  const _onStopDragging = () => {
    isScrollDragging = false;
  };

  const _toggleScrollingUp = ({
    nativeEvent: { contentOffset, contentSize, layoutMeasurement },
  }) => {
    const scrollY = Math.round(contentOffset.y);
    const isCurrentScrollingUp = scrollY < lastScrollY;
    const maxY = Math.round(contentSize.height - layoutMeasurement.height);
    if (scrollY > 180 && scrollY < maxY) {
      if (isCurrentScrollingUp !== isScrollingUp && isScrollDragging) {
        // this should only get trigger once on user begin scrolling up or scrolling down
        setIsScrollingUp(isCurrentScrollingUp);
      }
    }
    lastScrollY = scrollY;
  };

  return {
    home,
    // states
    allStoreEnd,
    allStorePage,
    filterLoading,
    homeAllStores: lodashFilter(homeAllStores, _isStoreOpen(true)),
    homeClosedStores: lodashFilter(homeAllStores, _isStoreOpen(false)),
    homeSegments,
    isGettingAllStore,
    isScrollingUp,
    loadHomeData,
    pageLoading,
    refreshing,
    totalResults,
    // functions
    onPageUpdate: _onPageUpdate,
    getHomepageData: _getHomepageData,
    fetchAllStoreNextData: _fetchAllStoreNextData,
    onSearchPressed: _onSearchPressed,
    onCardPressed: _onCardPressed,
    onRefresh: _onRefresh,
    onMomentumScrollBegin: _onMomentumScrollBegin,
    onReachedEnd: _onReachedEnd,
    isCloseToBottom: _isCloseToBottom,
    onStartDragging: _onStartDragging,
    onStopDragging: _onStopDragging,
    toggleScrollingUp: _toggleScrollingUp,
  };
};

export default useHomeController;
