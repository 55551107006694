import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { Icon, Text } from '@ui-kitten/components';
import { DrawerContentScrollView } from '@react-navigation/drawer';
import PropTypes from 'prop-types';

import Button from './Button';
import CloseButton from './Web/CloseButton';
import VersionText from './VersionText';

import ThemeStyle from '../Theme/styles';
import ThemeColor from '../Theme/colors';

const LeftDrawerContent = ({ title, data, navigation, children }) => {
  const [active, setActive] = useState(
    window.location.pathname.replace(/^\/|\/$/g, '')
  );

  const toggleDrawer = () => navigation.getParent('LeftDrawer').closeDrawer();
  const onPress = (item) => {
    setActive(item.value);
    navigation.navigate(item.value);
  };
  return (
    <View style={styles.container}>
      <View style={styles.closeButtonContainer}>
        <CloseButton onPress={toggleDrawer} />
      </View>
      <DrawerContentScrollView style={styles.drawerContentScrollview}>
        {data?.map((item, index) => (
          <Button
            key={index}
            testID="menu_btn"
            status="success"
            onPress={() =>
              !item.onPressItem ? onPress(item) : item.onPressItem()
            }
            style={styles.drawerItem}
            plain
          >
            <View style={{ width: '15%' }}>
              <Icon
                name={item.iconName}
                fill={
                  active === item.value ? ThemeColor.green : ThemeColor.black
                }
                pack="ion"
                style={styles.drawerIcon}
              />
            </View>
            <View>
              <Text
                style={{
                  color:
                    active === item.value ? ThemeColor.green : ThemeColor.black,
                }}
              >
                {item.text}
              </Text>
            </View>
          </Button>
        ))}

        {children}
      </DrawerContentScrollView>
      <View
        style={[ThemeStyle.appVersionContainer, ThemeStyle.spacingBottomMedium]}
      >
        <VersionText />
      </View>
    </View>
  );
};
LeftDrawerContent.propTypes = {
  //string to be displayed at the top of drawer.
  title: PropTypes.string,

  //data to be rendered in drawer component.
  data: PropTypes.array,

  //props for navigation provided by react-navigation.
  navigation: PropTypes.any,

  //it allow to render element body inside the drawer component.
  children: PropTypes.element,
};

const styles = StyleSheet.create({
  container: { flex: 1, height: '100%' },
  closeButtonContainer: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    ...ThemeStyle.spacingTopMedium,
    ...ThemeStyle.pageHorizontalSpacingSmall,
  },
  drawerContentScrollview: { paddingLeft: '5%' },
  drawerItem: {
    borderBottomWidth: 1,
    borderBottomColor: ThemeColor.lightGray,
    width: '90%',
    height: 50,
    marginTop: 10,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  drawerIcon: { width: 20, height: 20, marginRight: 20 },
});

export default LeftDrawerContent;
