import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import creditcardutils from 'creditcardutils';

import withFormik from '../../Hooks/withFormik';
import TextInput from '../TextInput';

import ErrorPromptBelowInput from './ErrorPromptBelowInput';

class FormField extends React.Component {
  render() {
    const { formikProps, name, onRef, ...rest } = this.props;
    const { setFieldTouched, setFieldValue, values, errors } = formikProps;
    const isCardNumber = rest.type === 'cardnumber';

    return (
      <Fragment>
        <TextInput
          onRef={onRef}
          value={values[name]}
          onBlur={() => setFieldTouched(name)}
          onChangeText={(text) => {
            if (isCardNumber) {
              text = creditcardutils.formatCardNumber(text);
            }
            setFieldValue(name, text);
          }}
          error={errors[name]}
          {...rest} // can overwrite the error props
        />

        <ErrorPromptBelowInput text={errors[name] || rest.error} />
      </Fragment>
    );
  }
}

FormField.propTypes = {
  formikProps: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onRef: PropTypes.func,
};

export default withFormik(FormField);
