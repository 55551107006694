import React, { useEffect, useState } from 'react';
import { StyleSheet, Animated } from 'react-native';

import Button from '../../Button';

const ScaleButtonAnimation = ({
  dataSet,
  onPress,
  buttonStyle,
  contentStyle,
  onScaling,
  scale = 1.01,
  disabled = false,
  children,
  onLayout,
}) => {
  const [scaleValue] = useState(new Animated.Value(1));

  useEffect(() => {
    // Reset the scale value when the component mounts
    scaleValue.setValue(1);
  }, [scaleValue]);

  const handleScaleAnimation = () => {
    Animated.timing(
      scaleValue, // The animated value to drive
      {
        toValue: scale, // The final scale value
        duration: 100, // Duration of the animation in milliseconds
        useNativeDriver: false, // Make sure to set this to false for scaling
      }
    ).start();
    onScaling?.(true);
  };
  const handleReset = () => {
    scaleValue.setValue(1);
    onScaling?.(false);
  };
  return (
    <Button
      dataSet={dataSet}
      style={buttonStyle}
      onPress={onPress}
      onHoverIn={handleScaleAnimation}
      onHoverOut={handleReset}
      isHoverable
      disabled={disabled}
      onLayout={onLayout}
    >
      <Animated.View
        style={[contentStyle, { transform: [{ scale: scaleValue }] }]}
      >
        {children}
      </Animated.View>
    </Button>
  );
};

export default ScaleButtonAnimation;
