import { createSlice } from '@reduxjs/toolkit';

import { CACHE_KEYS } from './constants';

export const dataCacheSlice = createSlice({
  name: 'dataCache',
  initialState: {
    // for storing cache of category data (homepage filter e.g: Barbecue, Breakfast etc.)
    [CACHE_KEYS.CATEGORIES]: [],
  },
  reducers: {
    setDataCache: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

export const { setDataCache } = dataCacheSlice.actions;
export default dataCacheSlice.reducer;
